import axios from "axios";
import uuidv4 from "uuid/v4";
import history from "../../history";
import { initialState } from "../../state/reducers/main";

const getDeviceToken = ({ localStorage, token }) => {
	const savedToken = localStorage.getItem("deviceToken");

	if (savedToken) return savedToken;

	const newToken = token();
	localStorage.setItem("deviceToken", newToken);

	return newToken;
};

export const withAuthHeader = (module) => {
	const localStorageItem = { value: "user" };
	const getUser = (item) => JSON.parse(localStorage.getItem(item));
	const user = getUser(localStorageItem.value);
	const accessToken =
		(user && user.accessToken) ||
		(user && user.token && user.token.accessToken);

	if (module === "main" && accessToken) {
		const options = {
			headers: {
				Authorization: `bearer ${accessToken}`,
			},
		};

		return options;
	}

	return null;
};

export const apiService = ({ localStorage, platformId, appId, apiUrl }) =>
	axios.create({
		responseType: "json",
		baseURL: apiUrl,
		headers: {
			"Content-Type": "application/json",
			Idioma: "pt-br",
			Plataforma: platformId,
			Versao: "3.12",
			DeviceToken: getDeviceToken({ localStorage, token: uuidv4 }),
			AplicativoId: appId,
			OneSignalPlayerId: "",
			Agent: "Ecommerce",
		},
	});

export const externalApiService = ({ responseType, headers } = {}) =>
	axios.create({
		responseType,
		headers,
	});

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const status = error.response.status;

		switch (status) {
			case 401:
				localStorage.setItem("main", JSON.stringify(initialState));
				history.push(`/`);
			case 400:
				throw new Error("Bab Request");
			case 500:
				throw new Error("Internal Server Error");
			default:
				return Promise.reject(error);
		}
	}
);
