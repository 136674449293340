import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSixthColor) || '#e2e2e2'};
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  border-radius: .625rem;
  font-size: .688rem;
  padding: .125rem .125rem;
  line-height: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;
  min-width: calc(100% - 2.5rem);
  max-width: calc(100% - 2.5rem);
  text-align: center;
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    padding: .375rem .313rem 0 0
  }

  & .custom-icon {
    padding: 0 .313rem
  }
`
