import styled from 'styled-components'

export const Wrapper = styled.header`
	background-color: #fff;
	max-width: 100%;
	min-width: 100%;
	display: unset;
	max-height: 5.625rem;
	min-height: 5.625rem;
	height: 5.625rem;
	z-index: 2;

	@media (max-width: 40rem) {
		max-height: 4.225rem;
		min-height: 4.225rem;
	}
`
