import styled from 'styled-components'

export const Item = styled.div`
  background: #fff;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: .15rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#EEE'};

  @media (min-width: 50rem) {
    border: .15rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#EEE'};
    border-radius: 15px;
    padding: .85rem;
    height: 5rem;
    min-height: 5rem;
    max-height: 5rem;
  }
`
