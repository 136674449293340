import { css } from 'styled-components'

export const fadeIn = css`
  animation: fadeIn .5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  transition: all .5s ease-in;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`
