import { actionTypes } from '.'
import { loading } from '..'
import { setNotification } from '../../redux/actions/main'

export const setAddress = (address) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_ADDRESS,
		payload: address
	})
}

export const setAddresses = (address) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_ADDRESSES,
		payload: address
	})
}

export const setAddressPostalCodeRequest = (addressPostalCodeRequest) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_ADDRESS_POSTAL_CODE_REQUEST,
		payload: addressPostalCodeRequest
	})
}

export const setAddressRequest = (addressRequest) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_ADDRESS_REQUEST,
		payload: addressRequest
	})
}

export const setNoAddressesList = (noAddressesList) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_NO_ADDRESSES_LIST,
		payload: noAddressesList
	})
}

export const setAddressByCEP = (addressByCEP) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_ADDRESS_BY_CEP,
		payload: addressByCEP
	})
}

export const setCEP = (cep) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_CEP,
		payload: cep
	})
}

export const setAddressByGeolocation = (userAddress) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_USER_ADDRESS,
		payload: userAddress
	})
}

export const removeUserAddressesItem = (userAddressesItem) => (dispatch) => {
	dispatch({
		type: actionTypes.REMOVE_USER_ADDRESSES_ITEM,
		payload: userAddressesItem
	})
}

export const setGeolocation = (geolocation) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_GEOLOCATION,
		payload: geolocation
	})
}

export const fetchAddressByGeolocation = (callback) => (dispatch, getState, container) => {
	try {
		const data = {}
		const actions = {
			setLoading: loading.setLoading
		}

		const stateAddress = getState().address && getState().address.address
		const latitude = getState().address && getState().address.latitude
		const longitude = getState().address && getState().address.longitude

		return container.fetchAddressByGeolocation({
			onSuccess: ({ address }) => {
				if (address) {
					const fullAddress = {
						latitude,
						longitude,
						...stateAddress,
						...address
					}

					dispatch(setAddressByGeolocation(fullAddress))
					dispatch(setAddress(fullAddress))
					dispatch(setCEP(fullAddress.cep))

					return
				}

				dispatch(setAddressByGeolocation({}))
			},
			onError: () => {
				dispatch(setAddressByGeolocation({}))
			},
			onEmpty: () => {
				dispatch(setAddressByGeolocation({}))
			},
			dispatch,
			data,
			actions,
			getState
		})
	} catch (e) {
		console.log(e)

		dispatch(setAddressByGeolocation({}))
	} finally {
		callback && callback()
	}
}

export const fetchGeolocationByAddress = (callback) => (dispatch, getState, container) => {
	const callbackErrorFilter = callback && callback.filter(filteredItem => filteredItem.name === 'geolocation-by-address' && filteredItem.type === 'error')
	const callbackError = callbackErrorFilter && callbackErrorFilter[0] && callbackErrorFilter[0].callback
	const callbackSuccessFilter = callback && callback.filter(filteredItem => filteredItem.name === 'geolocation-by-address' && filteredItem.type === 'success')
	const callbackSuccess = callbackSuccessFilter && callbackSuccessFilter[0] && callbackSuccessFilter[0].callback

	const stateAddress = (getState().address && getState().address.address) || {}

	const done = () => {
		dispatch(loading.setLoading({ item: 'geolocation-by-address', delete: true }))
	}

	try {
		const fullAddress = stateAddress &&
			stateAddress.main &&
			stateAddress.number &&
			stateAddress.neighborhood &&
			`${stateAddress.main}, ${stateAddress.number} - 
			${stateAddress.neighborhood}, ${stateAddress.city} - 
			${stateAddress.state}, ${stateAddress.cep}, Brasil`
		const almostFullAddress = stateAddress &&
			stateAddress.main && !stateAddress.number &&
			stateAddress.neighborhood &&
			`${stateAddress.main}, 1 - ${stateAddress.neighborhood}, ${stateAddress.city} - 
			${stateAddress.state}, ${stateAddress.cep}, Brasil`
		const formattedAddress = almostFullAddress || fullAddress

		const data = {
			address: formattedAddress
		}

		const actions = {}

		if (stateAddress && !stateAddress.main) {
			return
		}

		loading.setLoading('geolocation-by-address')

		return container.fetchGeolocationByAddress({
			onSuccess: ({ geolocation }) => {
				if (geolocation) {
					const {
						lat,
						lng
					} = geolocation.geometry.location

					dispatch(setGeolocation({
						latitude: lat,
						longitude: lng
					}))

					const address = {
						...stateAddress,
						latitude: lat,
						longitude: lng
					}

					dispatch(setAddress({ ...address }))

					done()

					callbackSuccess && callbackSuccess(address)

					return
				}

				dispatch(setGeolocation({}))
			},
			onError: () => {
				dispatch(setGeolocation({}))

				done()

				callbackError && callbackError()
			},
			onEmpty: () => {
				dispatch(setGeolocation({}))

				done()

				callbackError && callbackError()
			},
			dispatch,
			data,
			actions,
			getState
		})
	} catch (e) {
		console.log(e)

		dispatch(setGeolocation({}))

		callbackError && callbackError()

		done()
	}
}

export const fetchAddressByCEP = (callback) => (dispatch, getState, container) => {
	const callbackErrorFilter = callback && callback.filter(filteredItem => filteredItem.type === 'error')
	const callbackError = callbackErrorFilter && callbackErrorFilter[0] && callbackErrorFilter[0].callback
	const callbackSuccessFilter = callback && callback.filter(filteredItem => filteredItem.type === 'success')
	const callbackSuccess = callbackSuccessFilter && callbackSuccessFilter[0] && callbackSuccessFilter[0].callback

	const cep = getState().address && getState().address.cep
	const stateLoading = (getState().main && getState().loading.loading) || []
	const stateAddress = getState().address && getState().address.addressByCEP
	const latitude = getState().address && getState().address.latitude
	const longitude = getState().address && getState().address.longitude

	const done = () => {
		dispatch(loading.setLoading({ item: 'address-by-cep', delete: true }))
	}

	try {
		if (stateLoading && stateLoading.includes('address-by-cep')) {
			throw new Error({ loading: true })
		}

		dispatch(loading.setLoading('address-by-cep'))

		const extraData = {
			cep
		}

		return container.fetchAddressByCEP({
			onSuccess: async ({ address }) => {
				if (address && !address.error) {
					const fullAddress = {
						latitude,
						longitude,
						...stateAddress,
						...address,
						pristine: true
					}

					dispatch(setAddressByCEP(fullAddress))
					dispatch(setAddress(fullAddress))
					dispatch(setNotification(null))

					if (fullAddress.main &&
						fullAddress.number &&
						fullAddress.neighborhood &&
						fullAddress.city &&
						fullAddress.state) {
						await dispatch(fetchGeolocationByAddress([{
							name: 'geolocation-by-address',
							type: 'error',
							callback: () => { }
						}, {
							name: 'geolocation-by-address',
							type: 'success',
							callback: () => {
								callbackSuccess && callbackSuccess()

								done()
							}
						}]))
					} else {
						callbackSuccess && callbackSuccess()

						done()
					}
				} else if (address && address.error) {
					dispatch(setNotification({
						type: 'error',
						message: 'Endereço fora da área de serviço! :('
					}))
					dispatch(setAddressRequest(true))
					dispatch(setAddressPostalCodeRequest(false))

					done()
				} else {
					dispatch(setAddressByCEP({}))

					done()
				}
			},
			onError: () => {
				dispatch(setAddressByCEP({}))

				callbackError && callbackError()

				done()
			},
			onEmpty: () => {
				dispatch(setAddressByCEP({}))

				callbackError && callbackError()

				done()
			},
			dispatch,
			extraData,
			getState
		})
	} catch (e) {
		if (e.loading === true) {
			console.warn(`addressActions.fetchAddressByCEP is already loading`)

			return
		} else {
			console.log(e)
			dispatch(setAddressByCEP({}))

			callbackError && callbackError()

			done()
		}
	}
}
