export default ({
  apiService
}) => {
  const strategy = {
    fetchSearch: async ({
      data,
      maps
    }) => {
      const {
        searchTerm,
				storeId,
				modalityId
      } = data

      const {
        mapMenuData
      } = maps

			const delivery = modalityId === 4 ? 'delivery=true' : ''
			const productSearch = searchTerm ? `produto=${searchTerm}` : ''

			const urlExtension = `?${delivery}&${productSearch}`
  
      const url = `Estabelecimentos/ObterCardapio/${storeId}${urlExtension}`
      const result = await apiService.get(url)
      const mappedResult = mapMenuData(result.data)
  
      return mappedResult
    }
  }

  return {
    fetchSearch: async (payload) => {
      return await strategy['fetchSearch'](payload)
    }
  }
}
