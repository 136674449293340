import React from 'react'

import { ReactComponent as CouponIcon } from '../../../assets/icons/icon_coupon.svg'
import { ReactComponent as IconCheck } from '../../../assets/icons/icon_check.svg'
import { ReactComponent as IconClose } from '../../../assets/icons/icon_close.svg'

import {
	Button,
	InputRounded,
} from '../../../components'

import {
	Wrapper,
	CouponForm,
	CouponTitle,
	CouponInputContainer,
	ShowCouponsLink,
	Message,
	Div
} from './styles'

export function Coupon(props) {
	const {
		handleAddCoupon,
		handleRemoveCoupon,
		handleViewCoupons,
		couponField,
		setCoupon,
		cartCoupon,
		error,
		success
	} = props

	return <Wrapper>
		{!cartCoupon && !cartCoupon.value && <CouponTitle>Adicionar cupom de desconto?</CouponTitle>}

		{cartCoupon && cartCoupon.value ? <>
			<CouponTitle>Cupom de desconto aplicado</CouponTitle>
		</> : <></>}

		<Div>
			{!error && success &&
				<Message className="success">
					<span>Cupom cadastrado com sucesso!</span>
					<IconCheck />
				</Message>
			}

			{error &&
				<Message className="error">
					<span>{error}</span>
					<IconClose />
				</Message>
			}
		</Div>

		<CouponForm onSubmit={(event) => { event.preventDefault() }}>
			<CouponInputContainer>
				{cartCoupon.value ? <InputRounded className="disabled" placeholder='Digite o código do cupom' value={cartCoupon.code ? cartCoupon.code : couponField} disabled /> :
					<InputRounded placeholder='Digite o código do cupom' value={couponField} onChange={(event) => {
						setCoupon(event.target.value)
					}} />}

				<CouponIcon />
			</CouponInputContainer>
			{cartCoupon.value ? <Button onClick={() => {
				handleRemoveCoupon()
			}}>Remover</Button> :
				<Button onClick={() => {
					handleAddCoupon()
				}}>Aplicar</Button>}
		</CouponForm>
		<ShowCouponsLink onClick={() => {
			handleViewCoupons()
		}}>Ver meus cupons</ShowCouponsLink>
	</Wrapper>
}
