import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
	Wrapper,
	Title,
	CustomButton
} from './styles'
import { Icon } from '../../components/common'
import { CustomLoading } from '../../components'
import { MULTI_STORE } from '../../../config'

export function Geolocation(props) {
	const {
		loadingGeolocation,
		directAccess,
		modalityId,
		latitude,
		longitude,
		setLoadingGeolocation,
		setGeolocation,
		handleCloseDialogGeolocation,
		setStoresListRequest,
		setVisibleGeolocation
	} = props

	useEffect(() => {
		if (modalityId === 2 && !latitude && !longitude && MULTI_STORE === 'true' && !directAccess) {
			setVisibleGeolocation(true)
		}
	}, [
		modalityId,
		latitude,
		longitude,
		directAccess,
		setVisibleGeolocation
	])

	function handleLocationClick() {
		setLoadingGeolocation(true)
		navigator.geolocation.getCurrentPosition(async function (position) {
			const { latitude, longitude } = position.coords

			setGeolocation({ latitude: latitude, longitude: longitude })

			setTimeout(() => {
				handleCloseDialogGeolocation()
				setStoresListRequest(true)
				setLoadingGeolocation(false)
			}, 2000)
		})
	}

	return (
		<Wrapper>
			<Title>Onde você está?</Title>
			<p>Assim você terá acesso aos produtos ofertas da sua região.</p>
			<CustomButton onClick={() => handleLocationClick()}>
				{loadingGeolocation ?
					<CustomLoading
						color={'#fff'}
						type={'spin'}
						id='default-loading'
						height={30}
						width={30} /> :
					<>
						<Icon width={"25px"} height={"25px"} name={"geolocalization"} />
						<span>Ativar geolocalização</span>
					</>}

			</CustomButton>
		</Wrapper>
	)
}

Geolocation.propTypes = {
	handleLocationClick: PropTypes.func,
}
