import styled from 'styled-components'

export const Header = styled.div`
    grid-area: header;
    background: #fff;
    border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    @media (min-width: 60rem) {
        padding: 0 2.5rem;
        justify-content: space-between;
    }

    button {
        display: none;
        max-width: 11rem;
        height: 2.5rem;
        border-radius: 8px;
        font-size: .875rem;
        cursor: pointer;
        
        @media (min-width: 60rem) {
            display: block;
        }
    }

    img {
        display: none;

        @media (min-width: 60rem) {
            display: block;
        }
    }

    #icon-arrow-left {
        display: block;
        cursor: pointer;
        position: absolute;
        left: 1.5rem;
        top: 50%;
        transform: translate(0, -50%);

        @media (min-width: 60rem) {
            display: none;
        }
    }
`

export const StoreName = styled.span`
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.688rem;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };

    @media (min-width: 60rem) {
        display: none;
    }
`
