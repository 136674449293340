import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles'

export function PageContainerHeader({ children, itemId, isMobile = false }) {
	return (
		<>
			{!isMobile ? <Wrapper itemId={itemId}>{children}</Wrapper> : <></>}
		</>
	)
}

PageContainerHeader.propTypes = {
	children: PropTypes.node,
	itemId: PropTypes.number
}
