import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

import { ReactComponent as InfoIcon } from '../../assets/icons/icon_info.svg'
import { CustomIcon } from '../../components'

import {
	Wrapper,
	H2,
	H3,
	Paragraph,
	Button,
	Title,
	CloseButton
} from './styles'

const useStyles = makeStyles({
	paper: {
		margin: 0,
		position: 'absolute',
		bottom: '-10px'
	},
	paperWidthMd: {
		width: '100%',
	},
	paperWidthMdMobile: {
		width: '400px'
	}
});

export function CouponOverlay(props) {
	const {
		code,
		overlay,
		setOverlay,
		quickDescription,
		addedAt,
		expirationAt,
		description,
		expiration,
		isMobile = false
	} = props
	const classes = useStyles();
	const maxWidth = 'md';

	const [expired, setExpired] = useState(false)

	useEffect(() => {
		if (expiration) {
			setExpired(true)
			return
		}
		
		setExpired(false)
	}, [expiration])

	return <Dialog
		open={overlay}
		onClose={() => setOverlay(false)}
		aria-labelledby={`coupon-dialog-title`}
		aria-describedby={`coupon-dialog-description`}
		maxWidth={maxWidth}
		classes={isMobile ? { paper: classes.paper, paperWidthMd: classes.paperWidthMd } : { paperWidthMd: classes.paperWidthMdMobile }}
		className='coupon-detail'
		PaperProps={{ style: { borderRadius: !isMobile ? '16px' : '10px' } }}
	>
		<DialogContent>
			<Wrapper id='coupon-information'>
				<CloseButton className='close-button' onClick={() => {
					setOverlay(false)
				}}>
					<CustomIcon name='Times' />
				</CloseButton>

				<Title>
					<InfoIcon />
					<H2>Informações de uso </H2>
				</Title>

				<H3>{code}</H3>

				<Paragraph className={`quick-description`}>{quickDescription}</Paragraph>

				<Paragraph className={`created-at`}>Cupom adicionado em <strong>{moment(addedAt).format('DD/MM/YYYY')}</strong></Paragraph>

				{expired ? <Paragraph className={`expired`}>Este cupom expirou em <strong>{moment(expirationAt).format('DD/MM/YYYY')}</strong></Paragraph> :
				<Paragraph className={`expires-at`}>Pode ser usado até <strong>{moment(expirationAt).format('DD/MM/YYYY')}</strong></Paragraph>}

				<Paragraph className={`description`}>{description}</Paragraph>

				<Button onClick={() => {
					setOverlay(false)
				}}>Entendi!</Button>
			</Wrapper>
		</DialogContent>
	</Dialog>
}
