import { useDispatch, useStore } from 'react-redux'

import { scroll } from '../../state/scroll'

export function useScroll() {
	const dispatch = useDispatch()
	const store = useStore()

	const stateScroll = (store.getState().main && store.getState().main.scroll) || {}
	const scrollX = stateScroll && stateScroll.x
	const scrollY = stateScroll && stateScroll.y

	function setScroll({x, y}) {
		dispatch(scroll.set({x, y}))
	}

	return {
		setScroll,
		scrollX,
		scrollY
	}
}
