import React, {
	useEffect,
	useState
} from 'react'
import PropTypes from 'prop-types'

import {
	Wrapper,
  Label,
	Input,
	Paragraph
} from './styles'

export const CustomInput = (props) => {
  const {
    name,
    type,
    notification,
		placeholder
  } = props

	const [focus, setFocus] = useState(false)

	useEffect(() => {
		const inputElement = document.getElementsByName(name)
		const inputElementItem = inputElement && inputElement.item(0)
		const inputElementValue = inputElementItem && inputElementItem.value

		if (inputElementValue) {
			setFocus(true)
		}
	}, [
		setFocus,
		name
	])

	return <Wrapper className={`custom-input ${focus ? 'focused' : ''}`}>
		<Label>{placeholder}</Label>
		<Input {...props} onFocus={() => {
			const {
				onFocus
			} = props

			if (onFocus && typeof onFocus === 'function') {
				onFocus()
			}

			setFocus(true)
		}} onBlur={(event) => {
			const {
				onBlur
			} = props

			if (onBlur && typeof onBlur === 'function') {
				onBlur()
			}

			if (!event.target.value) {
				setFocus(false)
			}
		}} onKeyDown={(event) => {
			if (type === 'number' && event.key === 'e') {
				event.preventDefault()
			}
		}} />
		{notification && notification.type === `new-address-${name}-warning` ? <Paragraph className='notification'>{notification.message}</Paragraph> : null}
	</Wrapper>
}

CustomInput.propTypes = {
	name: PropTypes.string,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	notification: PropTypes.shape({})
}
