import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { pipe } from '../../../domain/helpers'

import {
  modality,
  store,
  loading,
	addressActions,
	user,
	userAddress,
	signUp
} from '../../../state'

import {
  setUserCard,
  setCart,
  setUserCards,
  setURLParameters
} from '../../../redux/actions/main'

import { useCustomLayout } from '../../hooks'

import {
  Stepper
} from '../../components'

import {
  Home,
  Splash
} from '../'

function WelcomeComponent(props) {
  const {
    setModality,
    setStoresPagination,
    setLoading,
    setStore,
    setStores,
    setAccessToken,
    setUserName,
    setUserAddress,
    setUserAddresses,
    setAddressByCEP,
    setAddress,
    setCEP,
    setUserCard,
    setCart,
    setUserCards,
    setSignUp,
    theme,
    location
  } = props

  const {
    primaryColor
  } = props.theme.main.general

  useCustomLayout({
    backgroundColor: primaryColor
  })

  useEffect(() => {
    setAccessToken(null)
    setUserName(null)
    setUserAddresses(null)
    setAddressByCEP(null)
    setAddress(null)
    setCEP(null)
    setUserCard(null)
    setCart({ products: null })
    setUserCards(null)
    setSignUp(null)
    setStore(null)
    setStores([])
    setModality({ id: 4 })
    setStoresPagination({
      offset: 0,
      total: 1
    })
    setLoading(null)
  }, [
    setModality,
    setStoresPagination,
    setLoading,
    setStore,
    setStores,
    setAccessToken,
    setUserName,
    setUserAddress,
    setUserAddresses,
    setAddressByCEP,
    setAddress,
    setCEP,
    setUserCard,
    setCart,
    setUserCards,
    setSignUp
  ])

  return <>
    <Stepper steps={[{
      element: (stepProps) => <Home theme={theme} location={location} {...stepProps} />
    }, {
      element: (stepProps) => <Splash theme={theme} {...stepProps} />
    }]} />
  </>
}

WelcomeComponent.propTypes = {
	setModality: PropTypes.func,
  setStoresPagination: PropTypes.func,
  setStore: PropTypes.func,
  setStores: PropTypes.func,
  setLoading: PropTypes.func,
	setAddress: PropTypes.func,
	setCEP: PropTypes.func,
	setAddressByCEP: PropTypes.func,
	setUserAddress: PropTypes.func,
	setUserAddresses: PropTypes.func,
	setAccessToken: PropTypes.func,
	setUserName: PropTypes.func,
	setSignUp: PropTypes.func,
	setUserCard: PropTypes.func,
	setCart: PropTypes.func,
	setUserCards: PropTypes.func,
  setURLParameters: PropTypes.func,
	theme: PropTypes.shape({
		main: PropTypes.shape({
			general: PropTypes.shape({
				primaryColor: PropTypes.string
			})
		})
	}),
	location: PropTypes.string
}

const mapStateToProps = () => {
  return {}
}

const GetConnection = connect(mapStateToProps, {
  setModality: modality.setModality,
  setStoresPagination: store.setStoresPagination,
  setStore: store.setStore,
  setStores: store.setStores,
  setLoading: loading.setLoading,
	setAddress: addressActions.setAddress,
	setCEP: addressActions.setCEP,
	setAddressByCEP: addressActions.setAddressByCEP,
	setUserAddress: userAddress.setUserAddress,
	setUserAddresses: userAddress.setUserAddresses,
	setAccessToken: user.setAccessToken,
	setUserName: user.setUserName,
	setSignUp: signUp.setSignUp,
	setUserCard,
	setCart,
	setUserCards,
  setURLParameters
})

export const Welcome = React.memo(pipe(
  GetConnection
)(WelcomeComponent))
