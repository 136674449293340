export default ({
  storeRepository,
  maps,
  auth
}) => {
  return async (payload) => {
    const {
      onSuccess,
      onError,
			onEmpty,
      data,
      actions,
      dispatch,
      getState
    } = payload

    try {
      const stores = await storeRepository.fetchStores({
        data,
        actions,
        maps,
        dispatch,
        getState,
        auth: auth()
      })

			if (stores && stores.list && stores.list.length) {
				onSuccess({ stores })	
			} else {
				onEmpty()
			}
    } catch(e) {
      console.log(e)

      onError()
    }
  }
}
