import React from 'react'
import PropTypes from 'prop-types'
import {
	connect
} from 'react-redux'

import {
	pipe
} from '../../../domain/helpers'

import {
	setQuantity
} from '../../../redux/actions/main'

import {
	CustomLoading
} from '../../components'

import {
	VerticalCardMenu,
	HorizontalMenu,
	VerticalMenu
} from '..'

import {
	Wrapper,
	LoadingContainer
} from './styles'

export function MenuComponent(props) {
	const {
		setQuantity,
		storeLogo,
		selectedProduct,
		handleAddProduct,
		handleViewProduct,
		direction,
		status,
		handleSelectedProductCategories,
		loading,
		handleSelectedCategory,
		storeImg,
		setVisibleCategories,
		setVisibleCategoriesCard,
		opened
	} = props

	function handleQuantity(args) {
		setQuantity(args)
	}

	return <Wrapper id='store-products' className={direction !== 'vertical' ? 'horizontal' : ''}>
		{loading.includes('menu-search') || loading.includes('store') ? <LoadingContainer>
			<CustomLoading
				type={'spin'}
				id='default-loading'
				height={40}
				width={40}
			/>
		</LoadingContainer> : (
			direction === 'vertical' ? <VerticalMenu
				storeLogo={storeLogo}
				storeImg={storeImg}
				selectedProduct={selectedProduct}
				handleQuantity={handleQuantity}
				handleViewProduct={handleViewProduct}
				handleAddProduct={handleAddProduct}
			/> : (
				direction === 'horizontal' ? <HorizontalMenu
					storeImg={storeImg}
					storeLogo={storeLogo}
					selectedProduct={selectedProduct}
					handleViewProduct={handleViewProduct}
					handleAddProduct={handleAddProduct}
					status={status}
					handleViewCategoriesProducts={handleSelectedProductCategories}
					handleSelectedCategory={handleSelectedCategory}
					setVisibleCategories={setVisibleCategories}
					opened={opened}
				/> : (direction === 'card' ? <VerticalCardMenu
					storeImg={storeImg}
					storeLogo={storeLogo}
					selectedProduct={selectedProduct}
					handleViewProduct={handleViewProduct}
					handleAddProduct={handleAddProduct}
					status={status}
					handleViewCategoriesProducts={handleSelectedProductCategories}
					handleSelectedCategory={handleSelectedCategory}
					setVisibleCategories={setVisibleCategoriesCard}
					opened={opened}
				/> : null)))}
	</Wrapper>
}

MenuComponent.propTypes = {
	modalityId: PropTypes.number,
	storeName: PropTypes.string,
	storeLogo: PropTypes.string,
	selectedProduct: PropTypes.shape({}),
	selectedProductsCategories: PropTypes.bool,
	handleAddProduct: PropTypes.func,
	handleViewProduct: PropTypes.func,
	handleGTM: PropTypes.func,
	direction: PropTypes.string,
	status: PropTypes.number,
	handleSelectedProductCategories: PropTypes.func,
	handleSelectedCategory: PropTypes.func,
	storeImg: PropTypes.string,
	setVisibleCategories: PropTypes.func,
	setVisibleCategoriesCard: PropTypes.func,
	opened: PropTypes.bool,
	loading: PropTypes.arrayOf(PropTypes.string),
	setQuantity: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		categories: state.menu.categories || [],
		loading: (state.loading && state.loading.loading) || [],
		search: state.main.search,
		selectedProduct: (state.menu.selectedProduct) || null
	}
}

const GetConnection = connect(mapStateToProps, {
	setQuantity
})

export const Menu = React.memo(pipe(
	GetConnection
)(MenuComponent))
