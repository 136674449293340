export default ({
  orderRepository,
  maps,
  auth
}) => {
  return async (payload) => {
    const {
      onSuccess,
      onError,
      data,
      actions,
      dispatch
    } = payload

    try {
      const analysis = await orderRepository.addAnalysis({
        data,
        actions,
        maps,
        dispatch,
        auth: auth()
      })

      onSuccess({ analysis })
    } catch(e) {
      console.log(e)

      onError(e)
    }
  }
}
