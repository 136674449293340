import React, {
  useCallback,
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import history from '../../history'

import {
  order,
  modality
} from '../../state'

import {
  pipe
} from '../../domain/helpers'

import {
  useInterval
} from '../hooks'

import {
  setUserHistory
} from '../../redux/actions/main'

import {
  Wrapper,
  ItemsWrapper,
  H2
} from './styles'

import {
  Order
} from '.'

function itemsMap({
  handleOrder,
  scheduling
}) {
  return (mappedItem, index) => {
    const key = `order-tracking-item-${ index }-${ new Date().getTime() }`

    return <Order key={ key } { ...mappedItem } handleOrder={ handleOrder } scheduling={scheduling}/>
  }
}

const Items = (props) => {
  const {
    handleOrder,
    orders,
    scheduling
  } = props

  return <ItemsWrapper id='home-order-tracking-items'>
    { orders && orders.orders && orders.orders.map(itemsMap({
      handleOrder,
      scheduling
   })) }
  </ItemsWrapper>
}

function OrderTrackingComponent(props) {
  const {
    fetchOrders,
    setOrder,
    setUserHistory,
    setModality,
    orders,
    scheduling,
    URLParameters
  } = props

  const getOrders = useCallback(async () => {
    await fetchOrders()
  }, [
    fetchOrders
  ])

  useInterval(() => {
    if (orders.retry) {
      getOrders()
    }
  }, 5000)

  useEffect(() => {
    fetchOrders()

    return () => {}
  }, [
    fetchOrders
  ])

  function handleOrder({
    id,
    modalityId
 }) {
    setOrder({
      id
    })
    setUserHistory({
      back: 'store',
      next: 'store'
    })
    setModality({
      id: modalityId
    })

    history.push(`/receipt${URLParameters}`)
  }

  return orders && orders.orders && orders.orders.length ? <Wrapper id='order-tracking'>
    <H2>Pedidos em Andamento</H2>

    <Items handleOrder={handleOrder} orders={orders} scheduling={scheduling}/>
  </Wrapper> : null
}

OrderTrackingComponent.propTypes = {
  orders: PropTypes.shape({}),
  fetchOrders: PropTypes.func,
  setOrder: PropTypes.func,
  setOrders: PropTypes.func,
  setUserHistory: PropTypes.func,
  setModality: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    orders: state.main.orders || [],
    scheduling: state.store.store && state.store.store.scheduling,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchOrders: order.fetchOrders,
  setOrders: order.setOrders,
  setOrder: order.setOrder,

  setUserHistory,
  setModality: modality.setModality
})

export const OrderTracking = React.memo(pipe(
  GetConnection
)(OrderTrackingComponent))
