import React from 'react'

import { ReactComponent as ClockIcon } from '../../../assets/icons/icon_clock.svg'
import { ReactComponent as LocationIcon } from '../../../assets/icons/icon_checkout_address.svg'
import { selectMonth } from '../../../../domain/helpers/SelectedMonth'

import {
  getCardBrandImageById,
  formatCpf
} from '../../../../infra/utils'
import Selo from '../../../assets/icone_menu_fidelidade.png';
import CashbackIcon from '../../../assets/icons/cashback_green.svg';


import {
  DetailsContainer,
  DeliveryEstimate,
  DetailsItem,
  ItemContent,
  ItemLabel,
  OrderDate,
  CustomerMessage,
  CardImage,
  FollowLink
} from './styles'
import { HAS_CASHBACK, HAS_LOYALTY } from '../../../../config'

const SelectedCard = ({ brand, number }) => {
  return <>
    {brand && number ? <>
      <CardImage src={brand} alt={`Bandeira do cartao: ${getCardBrandImageById(brand)}`} />

      <span>{number}</span>
    </> : '...'}
  </>
}

const Points = ({ earnedPoints }) => {
  return <>
    {earnedPoints ? <>
      <img src={Selo} width="40px" alt="Sele de pontos" />

      <span>{earnedPoints} pontos</span>
    </> : '...'}
  </>
}

export const CashbackPoints = ({ earnedPoints }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {earnedPoints ? <>
        <img src={CashbackIcon} width="30px" alt="Sele de pontos" />

        <span style={{ marginLeft: 8, marginTop: 5 }}>
          Cashback de R${earnedPoints.toFixed(2).replace('.', ',')}
        </span>
      </> : '...'}
    </div>
  )
}

export function Details({
  userName,
  deliveryTime,
  deliveryAddress,
  date,
  cardBrand,
  cardNumber,
  cpf,
  hasCpf,
  scheduling,
  orderId,
  followLink,
  status,
  requestNumber,
  earnedPoints
}) {

  const hasCashback = JSON.parse(HAS_CASHBACK);
  const hasLoyalty = JSON.parse(HAS_LOYALTY);


  return (
    <DetailsContainer>
      <CustomerMessage>{userName},<br /> seu pedido <span>{(requestNumber && requestNumber >= 1) ? `#${requestNumber}` : '...'}</span> foi confirmado!</CustomerMessage>
      <OrderDate>{date}</OrderDate>
      {!scheduling && <DeliveryEstimate>
        <ClockIcon />
        {deliveryTime}
      </DeliveryEstimate>}
      {scheduling && <DetailsItem>
        <ItemLabel>Data da entrega</ItemLabel>
        <ItemContent>
         <span> {scheduling}</span>
        </ItemContent>
      </DetailsItem>}
      <DetailsItem>
        <ItemLabel>Local de entrega</ItemLabel>
        <ItemContent>
          <LocationIcon />
          <span>{deliveryAddress}</span>
        </ItemContent>
      </DetailsItem>
      <DetailsItem>
        <ItemLabel>Forma de pagamento</ItemLabel>
        <ItemContent iconWidth='2rem'>
          <SelectedCard brand={cardBrand} number={cardNumber} />
        </ItemContent>
      </DetailsItem>
      <DetailsItem>
        <ItemLabel>CPF na nota</ItemLabel>
        <ItemContent>
          <span>{hasCpf ? formatCpf(cpf) : '000.000.000-00'}</span>
        </ItemContent>
      </DetailsItem>
      {(hasLoyalty && earnedPoints > 0) &&
        (<DetailsItem>
          <ItemLabel>Pontos Ganhos</ItemLabel>
          <ItemContent iconWidth='2rem'>
            <Points earnedPoints={earnedPoints} />
          </ItemContent>
        </DetailsItem>)
      }
      {
        (hasCashback && earnedPoints > 0) && (
          <DetailsItem cashback>
            <ItemLabel>Cashback Ganho</ItemLabel>
            <ItemContent iconWidth='2rem'>
              <CashbackPoints earnedPoints={earnedPoints} />
            </ItemContent>
          </DetailsItem>
        )
      }
      {status <= 4 ? <FollowLink href={status === 4 && followLink ? followLink : '#'} onClick={(event) => { if (!followLink || status !== 4) { event.preventDefault(); return false } }} className={`${!followLink && status !== 4 ? 'disabled' : ''}`} target={'_blank'}>Acompanhe a entrega</FollowLink> : <></>}
    </DetailsContainer>
  )
}
