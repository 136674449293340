import React from 'react'
import PropTypes from 'prop-types'

import {
  Wrapper
} from './styles'

import { StoreAlternative } from '../StoreAlternative'

export function ListAlternative(props) {
  const {
		data,
		handleStore,
		modalityId,
		selectedStoreId
	} = props

  return <Wrapper>
    {data.filter(filteredItem => !!filteredItem).map((mappedItem, index) => {
      const key = `storeslist-${mappedItem.id}-${index}`

      const {
        id,
        name,
        logo,
        category,
        distance,
        valueFrom,
        fullAddress,
        deliveryEstimate,
        status,
        pickup,
        delivery,
        coupon = {},
        highlight,
        address,
        cashback,
				minForFreeShipping
      } = mappedItem

      const kmDistance = String(distance).replace('.', ',')

      const Content = {
        component: StoreAlternative,
        props: {
          data: {
            logo,
            name,
            category,
            id,
            distance: kmDistance,
            valueFrom,
            fullAddress,
            deliveryEstimate,
            status,
            coupon,
            pickup,
            delivery,
            modalityId,
						selectedStoreId,
            highlight,
            address,
            cashback,
						minForFreeShipping
          },

          handles: {
            handleStore
          }
        }
      }

      return (
        <StoreAlternative key={key} {...Content.props} />
      )
    })}
  </Wrapper>
}

ListAlternative.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
		images: PropTypes.shape({
			highlight: PropTypes.string,
			logo: PropTypes.string
		})
	})),
	store: PropTypes.shape({}),
	handleStore: PropTypes.func,
	modalityId: PropTypes.number,
	selectedStoreId: PropTypes.number
}
