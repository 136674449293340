import { actionTypes } from '.'

const localStorageKey = 'signUp'

const initialState = {
	signUp: {
		ddi: 55,
    country: 'brasil'
	},
  method: null
}

const reducer = (state = initialState, action) => {
  const localStorageData = JSON.parse(localStorage.getItem(localStorageKey)) || {}
  const loadedState = {
    ...state,
    ...localStorageData
  }

  const newState = {
    value: loadedState
  }

  switch (action.type) {
    case actionTypes.SET_SIGN_UP:
      Object.assign(newState, {
        value: {
          ...newState.value,
          signUp: action.payload
        }
      })

      break

    case actionTypes.SET_METHOD:
      Object.assign(newState, {
        value: {
          ...newState.value,
          method: action.payload
        }
      })

      break

    default:
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState.value))

  return newState.value
}

export default reducer
