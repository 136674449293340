import styled from 'styled-components'

export const Content = styled.div`
  	display: flex;
	padding: .1rem 1rem;
	align-items: center;
	background-color: #E3FFF0;
	border-radius: 12px;
	color: #04A74D;
	gap: 8px;
	margin: auto;
	margin-top: 2rem;
	box-sizing: border-box;
	& svg {
		width: 18px;
	}
`
export const CashbackText = styled.span`
	font-size: 14px;
`
