import React, {
  useEffect,
  useState
} from 'react'

import {
  Link
} from 'react-scroll'

import {
  Wrapper,
  ListWrapper
} from './styles'

export function Categories(props) {
  const {
    data  
  } = props

  const [fixed, setFixed] = useState(false)
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400 && !fixed) {
        setFixed(true)
      } else if (window.scrollY < 400 && !!fixed) {
        setFixed(false)
      }
    }

    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [
    fixed
  ])

  function handleScrollCategory () {
    const itemActive = document.getElementsByClassName('active')
    const mapItemActive = Array.from(itemActive)
    const finishMapItem = mapItemActive && mapItemActive.length ? mapItemActive[0].classList[0] : ''

    if (finishMapItem && finishMapItem.length){
      localStorage.setItem('scrollCategory' ,finishMapItem)

      const categoryClassName = localStorage.getItem('scrollCategory')

      const categoryItemSelector = document.querySelector(`#store-categories > div > a.${categoryClassName}`)
      const categoryItem = categoryItemSelector

      const scrolledCategoriesHolder = document.querySelector(`#store-categories > div`)

      scrolledCategoriesHolder.scrollLeft = categoryItem && categoryItem.offsetLeft - 30
    }
  }

  return <Wrapper id='store-categories' fixed={fixed}>
    <ListWrapper>
      { data && data.length && data.map((mappedItem, index) => {
        const key = `store-categories-items-${index}}`
        const {
          name
        } = mappedItem

        const categoryClassName = String(name).replace(/&amp;/g, '&').toLowerCase().replace(/ /g, '-').replace(/[^a-zA-Z]/g, '')

        return <Link 
            key={key} 
            activeClass='active' 
            className={categoryClassName} 
            to={name} 
            spy={true} 
            smooth={true} 
            offset={-170} 
            duration={500} 
            isDynamic={true} 
            onSetActive={handleScrollCategory} 
          >
          {name}
        </Link>
      }) }
    </ListWrapper>
  </Wrapper>
}
