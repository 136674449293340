
import React from 'react'
import { maskCpf } from '../../../../domain/helpers'
import history from '../../../../history'

import { getCardBrandImageById } from '../../../../infra/utils'

import { ReactComponent as LocationIcon } from '../../../assets/icons/icon_checkout_address.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/icon_edit_fill.svg'

import { SelectedItem } from './SelectedItem'

import {
  Wrapper,
  SelectedItemContent,
  PaymentMethodContent,
  CPFContent,
  DeliveryContent,
  ItemLabel,
  SelectedItemAction,
  EditButton,
  BrandImage,
  SelectedItemContentText
} from './styles'

export function SelectedItems(props) {
  const {
    userCard,
    address,
    cpf,
    modalityId,
    storeAddress,
    scheduling,    
		above,
		changeSelectedPaymentMethod,
    changeSelectedDeliveryDate,
    selectedDeliveryDate,    
  } = props

  const selectAddressDelivery = address.main || address.backup

  const displayAddress = modalityId === 4 ?
    (selectAddressDelivery && selectAddressDelivery.length > 30 ?
      (selectAddressDelivery.slice(0, -17) + '...') : selectAddressDelivery) + (address.number ? `, ` + address.number : '')
    : storeAddress.slice(0, -9) + '...'

  const fullDisplayAddress = modalityId === 4 ?
    selectAddressDelivery + (address.number ? `, ` + address.number : '') :
    storeAddress

    const getFormatedDate = () => {
      const date = new Date(selectedDeliveryDate.date)
      const week = date.toLocaleDateString('pt-BR', { weekday: 'long' }).split('-')[0]
      const month = date.toLocaleDateString('pt-BR', { month: 'long' })
      const day = date.toLocaleDateString('pt-BR', { day: 'numeric' })
      const year = date.toLocaleDateString('pt-BR', { year: 'numeric' })
      return `${week}, ${day} de ${month} de ${year}, ${selectedDeliveryDate.hour.time}`
    }

  return <Wrapper>
      {scheduling && (modalityId === 4 || modalityId === 2) && <SelectedItem>
      <SelectedItemContent>
      <ItemLabel>Data da entrega</ItemLabel>
        <DeliveryContent>          
          <SelectedItemContentText onClick={() => changeSelectedDeliveryDate()} style={{
            cursor: 'pointer'
          }}>
            {selectedDeliveryDate ? getFormatedDate() : 'Selecionar data e horário'}            
            </SelectedItemContentText>
        </DeliveryContent>
      </SelectedItemContent>
      <SelectedItemAction>
          <EditButton onClick={() => {
            changeSelectedDeliveryDate()
          }}>
          <EditIcon />
        </EditButton>
      </SelectedItemAction>
    </SelectedItem>}


    <SelectedItem>
      <SelectedItemContent>
        <ItemLabel>{modalityId === 4 ? 'Local de entrega' : 'Local de retirada'}</ItemLabel>
        <DeliveryContent>
          <LocationIcon />
          <span>{fullDisplayAddress && fullDisplayAddress.length > 16 ? displayAddress : fullDisplayAddress}</span>
        </DeliveryContent>
      </SelectedItemContent>
    </SelectedItem>
    {above && <SelectedItem>
      <SelectedItemContent>
        <ItemLabel>Forma de pagamento</ItemLabel>
        <PaymentMethodContent>
          <BrandImage
            src={getCardBrandImageById(userCard.brandId)}
            alt={`Bandeira do cartao: ${getCardBrandImageById(userCard.brand)}`}
          />
          <span>{userCard.data}</span>
        </PaymentMethodContent>
      </SelectedItemContent>
      <SelectedItemAction>
        <EditButton onClick={() => {
					changeSelectedPaymentMethod()
        }}>
          <EditIcon />
        </EditButton>
      </SelectedItemAction>
    </SelectedItem>}
    <SelectedItem>
      <SelectedItemContent>
        <ItemLabel>CPF na nota</ItemLabel>
        {cpf ? <CPFContent>
          <span>{maskCpf(cpf)}</span>
        </CPFContent> : <CPFContent>
          <span>000.000.000-00</span>
        </CPFContent>}
      </SelectedItemContent>
    </SelectedItem>
  </Wrapper>
}
