import React, { useState } from 'react'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import { ReactComponent as IconClose } from '../../assets/icons/icon_close.svg'
import { ReactComponent as AntiFraudIcon } from '../../assets/icons/icon_anti_fraud.svg'
import THEME from '../../../theme.json'

import {
  Wrapper,
  H2,
  H3,
  Paragraph,
  ButtonLabel,
  Button,
  ErrorMessage
} from './styles'

const GreenCheckbox = withStyles({
  root: {
    color: THEME.main.general.primaryColor,
    '&$checked': {
      color: THEME.main.general.primaryColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export function AntiFraud(props) {
  const {
    handleAntiFraudConfirmation,
    errorMessage
  } = props

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  }

  return <Wrapper>
    {errorMessage &&
      <ErrorMessage>
        <span>{errorMessage}</span>
        <IconClose />
      </ErrorMessage>
    }
    <H2>Dica de Segurança</H2>
    <AntiFraudIcon />
    <H3>Não aceite cobranças<br /> adicionais na entrega!</H3>
    <Paragraph>Seu pedido já foi pago pelo<br /> site com sucesso</Paragraph>
    <FormControlLabel
      control={<GreenCheckbox checked={checked} onChange={handleChange} name="checkedG" />}
      label={<Typography variant="body2">Li e entendi a dica de segurança.</Typography>}
    />
    <Button
      onClick={() => { if (checked) { handleAntiFraudConfirmation() } }}
      className={!checked ? 'disabled' : ''}
    >
      <ButtonLabel>Continuar</ButtonLabel>
    </Button>
  </Wrapper>
}

AntiFraud.propTypes = {
	handleAntiFraudConfirmation: PropTypes.func,
	errorMessage: PropTypes.string
}
