import React from 'react'
import { Dialog } from '@material-ui/core'
import {
  Wrapper,
  Title,
  ModalWrapper,
  Paragraph,
  Div,
  Button,
  Form,
  FormField
} from './styles'
import { InputRounded } from '../../components/Input'

export function CustomModalNotification({
  name,
  title,
  content,
  openModal,
  handleCloseModal,
  size,
  fullWidth,
  actions,
  input,
  inputValue,
  setInputValue,
  inputType,
  inputPlaceholder
}) {

  return <Wrapper>
    <Dialog
      onClose={() => handleCloseModal()}
      aria-labelledby={`${name}-modal`}
      open={openModal}
      maxWidth={size}
      fullWidth={fullWidth}
      PaperProps={{
        style: {
          borderRadius: '.75rem'
        }
      }}
    >
      <ModalWrapper>
        {title && <Title>{title}</Title>}
        {content && <Paragraph>{content}</Paragraph>}
        {input && <Form onSubmit={(event) => {
          event.preventDefault()
        }}>
          <FormField>
            <InputRounded
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              type={inputType}
              name={inputValue}
              placeholder={inputPlaceholder}
            />
          </FormField>
        </Form>}
        {actions ? <Div className={`actions`}>
          {actions.map((actionsMappedItem, index) => {
            const {
              label,
              handleClick,
              classes
            } = actionsMappedItem

            const key = `modal-item-${index}-${new Date().getTime()}`

            return <Button className={classes} key={key} onClick={() => {
              handleClick()
            }}>
              {label}
            </Button>
          })}
        </Div> : null}
      </ModalWrapper>
    </Dialog>
  </Wrapper>
}