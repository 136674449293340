import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as IconStoreOff } from './../../assets/icons/store-off.svg'
import { ReactComponent as IconStoreUnavailable } from './../../assets/icons/store-unavailable.svg'
import { ReactComponent as IconStoreNoConnection } from './../../assets/icons/store-no-connection.svg'

import {
    Wrapper,
    H4,
    Paragraph,
    Button,
    Div,
    CloseButton
} from './styles'
import { CustomIcon } from '../../components';

const useStyles = makeStyles({
    paper: {
        margin: 0,
        position: 'absolute',
        bottom: '-10px'
    },
    paperWidthMd: {
        width: '100%',
    },
    paperWidthMdMobile: {
        width: '400px'
    }
});

export function StatusOverlay(props) {
    const {
        overlay,
        setOverlay,
        status,
        actions,
        className = '',
        isMobile = false
    } = props
    const classes = useStyles();
    const [fullWidth, setFullWidth] = useState(false);
    const [maxWidth, setMaxWidth] = useState('md');
    const [pageStatus, setPageStatus] = useState("")
    const [pageTitle, setPageTitle] = useState("")
    const [pageContent, setPageContent] = useState("")

    useEffect(() => {
        if (status) {
            setFullWidth(true)
            setMaxWidth('md')

            if (status === 2) {
                setPageStatus("fechado")
                setPageTitle("Esse estabelecimento está fechado no momento!")
            }
            if (status === 3) {
                setPageStatus("em-manutencao")
                setPageTitle("Esta loja está em manutenção no momento!")
                setPageContent("Estamos trabalhando para melhorar sua experiência! Infelizmente não será possivel fazer o seu pedido agora!")
            }
            if (status === 4) {
                setPageStatus("sem-conexao")
                setPageTitle("Esta loja está sem conexão no momento!")
                setPageContent("Perdemos temporariamente a conexão com a loja e não conseguimos finalizar o seu pedido. Tente novamente mais tarde.")
            }

						if (status === 6) {
							setPageStatus("fechado")
							setPageTitle("Esse estabelecimento está fechado temporariamente!")
					}
        }
    }, [status])

    return <Dialog
        open={overlay}
        onClose={() => setOverlay(false)}
        aria-labelledby={`alert-dialog-title-${pageStatus}`}
        aria-describedby={`alert-dialog-description-${pageStatus}`}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        className='status-notification'
        classes={isMobile ? { paper: classes.paper, paperWidthMd: classes.paperWidthMd } : { paperWidthMd: classes.paperWidthMdMobile }}
        PaperProps={{ style: { borderRadius: !isMobile ? '16px' : '10px' } }}
    >
        <DialogContent>
            <Wrapper className={`status ${className}`}>
                <CloseButton className='close-button' onClick={() => {
                    setOverlay(false)
                }}>
                    <CustomIcon name='Times' />
                </CloseButton>

                {
									(status === 2 || status === 6) ? 
										<IconStoreOff /> : status === 3 ? <IconStoreUnavailable /> : <IconStoreNoConnection />
								}

                {pageTitle ? <H4>{pageTitle}</H4> : null}

                {pageContent ? <Paragraph>{pageContent}</Paragraph> : null}

                {actions ? <Div className={`actions`}>
                    {actions.map((actionsMappedItem, index) => {
                        const {
                            label,
                            handleClick
                        } = actionsMappedItem

                        const key = `overlay-status-store-item-${index}-${new Date().getTime()}`

                        return <Button key={key} onClick={() => {
                            handleClick()
                        }}>
                            {label}
                        </Button>
                    })}
                </Div> : null}
            </Wrapper>
        </DialogContent>
    </Dialog>
}
