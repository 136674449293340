import styled from 'styled-components'

export const Image = styled.img`
  border-radius: .625rem;
`

export const Wrapper = styled.a`
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 9.625rem;
  max-width: 9.625rem;
  margin: 0;
  margin-bottom: 1.875rem;
  max-height: 14.375rem;
  min-height: 14.375rem;
  position: relative;
  padding-bottom: .625rem;
  min-width: 33.33%;
  text-align: left;

  &.normal {
    margin: 0 1.5rem .5rem 0;

    &.hidden {
      display: none;
    }
  }

  &.center {
    text-align: center;

    > .holder {
      margin: 0 auto;
    }
  }

  &.right {
    text-align: right;

    > .holder {
      margin: 0;
      right: 0;
      left: auto;

      @media (min-width: 60rem) {
        position: absolute;
      }
    }
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &:last-child {
    border-bottom: 0 none;
  }

  > .holder {
    min-width: 7.625rem;
    max-width: 7.625rem;
    position: relative;
    min-height: 15.375rem;

    > div {
      max-width: 100%;
      min-width: 100%;

      &.quantity {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        position: absolute;
        left: 0;
        bottom: 0;
        min-width: 100%;
        padding: 0 1rem;
        box-sizing: border-box;

        > span {
          flex: 1;
          text-align: center;
          color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333' };
          font-weight: bold;
          font-size: 1rem;
        }
      }
    }

    > .image {
      flex: 0;
      max-height: 6.625rem;
      min-height: 6.625rem;
      max-width: 7.625rem;
      min-width: 7.625rem;
      border-radius: .625rem;
      overflow: hidden;
      position: relative;
      -webkit-overflow-scrolling: touch;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#EFEFEF' };
      margin-bottom: .625rem;

      &.store-logo {
        background: #fff;
        border: .063rem solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#EFEFEF' };

        > img {
          opacity: .2;
        }
      }

      > img {
        min-height: auto;
        max-height: unset;
        min-width: 128%;
        max-width: 128%;
        flex: 1;
        transform: scale(1);
      }
    }

    > .image-store {
      flex: 0;
      max-height: 6.625rem;
      min-height: 6.625rem;
      max-width: 7.625rem;
      min-width: 7.625rem;
      border-radius: .625rem;
      overflow: hidden;
      position: relative;
      -webkit-overflow-scrolling: touch;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#EFEFEF' };
      margin-bottom: .625rem;

      > img {
        min-height: auto;
        max-height: unset;
        min-width: 70%;
        max-width: 70%;
        flex: 1;
        transform: scale(1);
      }
    }


    > button {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
    }

    > button, > div > button {
      border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey01) || '#333'} .125rem solid;
      border-radius: 50%;
      flex: 1;
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333' };
      font-size: .688rem;
      min-width: 2.075rem;
      min-height: 2.075rem;
      max-width: 2.075rem;
      max-height: 2.075rem;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'} .125rem solid;
        min-width: 1.575rem;
        min-height: 1.575rem;
        max-width: 1.575rem;
        max-height: 1.575rem;
      }

      > span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`

export const H2 = styled.h2`
  flex: none;
  margin: 0;
  padding: 0;
  font-size: .875rem;
  line-height: .875rem;
  font-weight: 600;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
`

export const Div = styled.div``

export const Paragraph = styled.p`
  flex: none;
  margin: 0;
  padding: 0;
  margin-bottom: .313rem;
  font-size: .75rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
  text-align: left;

  &.description {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
  }

  &.measure {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    font-size: .75rem;
  }

  &.value {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    margin-bottom: 0;
    font-weight: bold;
    font-size: 1rem;
    position: absolute;
    left: 0;
    top: 11.538rem;
  }

  &.promo {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    margin-bottom: 0;
    font-size: .75rem;
    font-weight: bold;
  }

  >span{
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    margin-bottom: 0;
    font-size: .438rem;
    text-decoration: line-through;
    margin-left: .313rem;
  }
`

export const Button = styled.button`
  background: none;
  padding: 0;
  margin: 0;

  &.disabled{
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.greyMedium) || '#333'};
  }
`

export const Span = styled.span``
