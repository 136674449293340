import React, {
    useEffect, useState
} from 'react'

import {
    Content,
    CashbackText
} from './styles'

import { ReactComponent as CashbackIcon } from '../../assets/icons/cashback_green.svg';

export function CashbackValue(props) {
    const {
        points,
        cartTotal
    } = props;

    const calcPercentage = (percent, total) => ((percent/ 100) * total).toFixed(2).replace('.', ',');
    const [value, setValue] = useState(calcPercentage(points, cartTotal));

    useEffect(() => {
        setValue(calcPercentage(points, cartTotal));
    }, [points, cartTotal])

    return (
        <Content>
            <CashbackIcon />
            <CashbackText>Cashback de R${value} neste pedido</CashbackText>
        </Content>
    )
}
