
import React, {
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  pipe,
  GetContent
} from '../../domain/helpers'

import {
  CustomIcon
} from '../components'

import {
	useLayout,
	useSidebar
} from '../hooks'

import {
  removeSelectedProduct,
  addProduct,
  removeProduct,
  handleGTM,
  removeSelectedProductAdditional,
  setSelectedCategory,
  setOpenCart,
	setQuantity
} from '../../redux/actions/main'

import {
  Wrapper,
  ImageWrapper,
  Textarea,
  Button,
  CloseButton,
  Image,
  H1,
  H4,
  Paragraph,
  Span,
  NotesWrapper,
  Div
} from './styles'

import {
  Additionals
} from './'

import {
	addressActions,
	menuActions
} from '../../state'

export const Context = React.createContext({})

const Notes = ({
  status,
  quantity,
  total,
  update,
  handleQuantity,
  handleAdd,
  scheduling
}) => {
  return <NotesWrapper id='product-notes'>
    {!scheduling && <><H4>Alguma observação?</H4>
      <Textarea placeholder='Deseja fazer alguma observação adicional? Digite aqui' /></>}

    <Div className='row'>
      <Div className='quantity'>
        <Button onClick={() => {
          handleQuantity({
            quantity: -1
          })
        }}>-</Button>
        <Span>{quantity}</Span>
        <Button onClick={() => {
          handleQuantity({
            quantity: 1
          })
        }}>+</Button>
      </Div>
      {status && status === 1 ? <Div className='add'>
        <Button onClick={() => {
          handleAdd()
        }}>
          <Span>{update ? 'Atualizar' : 'Adicionar'}</Span>
          <Span>{`R$ ${total && (Number(total).toFixed(2)).replace('.', ',')}`}</Span>
        </Button>
      </Div> : <Div className='add'>
        <Button disabled onClick={() => {
          handleAdd()
        }}>
          <Span>{'Adicionar'}</Span>
          <Span>{`R$ ${total && (Number(total).toFixed(2)).replace('.', ',')}`}</Span>
        </Button>
      </Div>}
    </Div>
  </NotesWrapper>
}

Notes.propTypes = {
	status: PropTypes.number,
  quantity: PropTypes.number,
  total: PropTypes.number,
  update: PropTypes.bool,
  handleQuantity: PropTypes.func,
  handleAdd: PropTypes.func,
  additionals: PropTypes.arrayOf(PropTypes.shape({})),
	scheduling: PropTypes.shape({})
}

function ProductPage(props) {
  const {
    storeStatus,
    selectedProduct,
    selectedCategory,
    removeSelectedProductAdditional,
    setSelectedProduct,
    addProduct,
    //removeProduct,
    cart,
    //handleGTM,
		setQuantity,
    handleClose,
    scheduling,
		setAddressPostalCodeRequest,
		address
  } = props

	const {
		layoutModeEnum,
		handleLayoutMode
	} = useLayout()

	const {
		sidebarContentEnum,
		handleSidebarContent,
		handleSidebarOpened
	} = useSidebar()

  const [loaded, setLoaded] = useState(false)
  const [lastQuantity, setLastQuantity] = useState(0)
  const [update, setUpdate] = useState(0)
  const [total, setTotal] = useState(0)
  const [value, setPrice] = useState(0)
  const [cartProductPreviousQuantity, setCartProductPreviousQuantity] = useState(0)
  
  useEffect(() => {
		if (selectedProduct) {
			const {
				products
			} = cart

			const cartProductPrevious = (products && products.length) ? (products.filter(filteredItem => filteredItem.id === selectedProduct.id))[0] : null
			const cartProductPreviousQuantity = selectedProduct.isNewAddition ? 1 : ((cartProductPrevious && cartProductPrevious.quantity) || 0)
			const finalQuantity = !update && cartProductPreviousQuantity ? cartProductPreviousQuantity : selectedProduct.quantity
			const value = `R$ ${Number(selectedProduct.value).toFixed(2).replace('.', ',')}`

			const selectedAdditionals = (selectedProduct && selectedProduct.selectedAdditionals) || (cartProductPrevious && cartProductPrevious.selectedAdditionals)

			const selectedAdditionalsGroups = selectedAdditionals && selectedAdditionals.length && selectedAdditionals.map((mappedItem) => {
				const selectedAdditionalsMaximum = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 3)
				const selectedAdditionalsAverage = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 2)
				const selectedAdditionalsFixes = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 1)

				const additionalsMaximumTotal = (selectedAdditionalsMaximum && selectedAdditionalsMaximum.length && Math.max.apply(Math, selectedAdditionalsMaximum.map((selectedAdditionalsMaximumItem) => selectedAdditionalsMaximumItem.value))) || 0
				const additionalsAverageTotal = selectedAdditionalsAverage && selectedAdditionalsAverage.length ? (selectedAdditionalsAverage.reduce((acc, current) => Number(acc + current.value), [])) / selectedAdditionalsAverage.length : 0
				const additionalsFixesTotal = selectedAdditionalsFixes && selectedAdditionalsFixes.length ? selectedAdditionalsFixes.reduce((acc, current) => {
					if (current.quantity > 1) {
						return Number(acc + (current.value * current.quantity))
					}

					return Number(acc + current.value)
				}, []) : 0

				return additionalsMaximumTotal + additionalsAverageTotal + additionalsFixesTotal
			})
			const finalPriceProduct = !selectedProduct.promotionalValue ? selectedProduct.value : selectedProduct.promotionalValue

			const selectedAdditionalsGroupsTotal = (selectedAdditionalsGroups && selectedAdditionalsGroups.length && selectedAdditionalsGroups.reduce((acc, current) => Number(acc + current), [])) || 0

			setLastQuantity(finalQuantity)
			setTotal(finalQuantity * (finalPriceProduct + selectedAdditionalsGroupsTotal))
			setPrice(value)
			setCartProductPreviousQuantity(cartProductPreviousQuantity)
		}

    return () => { }
  }, [
    cart,
    selectedProduct,
    update,
    loaded,
    setLoaded
  ])

  function handleQuantity(item) {
    const {
      quantity: quantityIncrement
    } = item

    const newProduct = {
      ...selectedProduct,
      quantity: lastQuantity + quantityIncrement,
      category: selectedCategory,
    }

    setUpdate(true)

    if (newProduct.quantity > 999) return false
    if (newProduct.quantity < 1) return false

    setLastQuantity(newProduct.quantity)
    setSelectedProduct(newProduct)
  }

  function handleAdditionalsChange(item, hasAdded) {
    const {
      quantity: quantityIncrement
    } = item

    if (hasAdded) {
      removeSelectedProductAdditional(item)

      return
    }

    const {
      groupId,
      calcType,
      minimum,
      maximum
    } = item

    const {
      products
    } = cart

    const cartProductPrevious = (products && products.length) ? (products.filter(filteredItem => filteredItem.id === item.id))[0] : null
    const selectedAdditionals = (selectedProduct && selectedProduct.selectedAdditionals) || cartProductPrevious.selectedAdditionals
    const selectedAdditionalGroup = (selectedAdditionals && selectedAdditionals.length && (selectedAdditionals.filter(filteredItem => filteredItem.groupId === groupId && filteredItem.calcType === calcType)[0])) || null

    if (selectedAdditionalGroup && selectedAdditionalGroup.items && selectedAdditionalGroup.items.length + quantityIncrement > maximum) {
      const group = document.getElementById(`additionals-group-${selectedAdditionalGroup.groupId}-${selectedAdditionalGroup.calcType}`)

      group.classList.add('maximum')

      return false
    }

    if (!selectedAdditionalGroup) {
      const newGroup = {
        groupId,
        calcType,
        minimum,
        maximum,
        items: [
          item
        ]
      }

      const newProduct = {
        ...selectedProduct,
        selectedAdditionals: [
          ...selectedAdditionals,
          newGroup
        ]
      }

      setSelectedProduct(newProduct)

      return
    }

    if (selectedAdditionalGroup) {
      const {
        items = []
      } = selectedAdditionalGroup

      const newSelectedAdditionals = selectedAdditionals.map(mappedItem => {
        if (mappedItem.groupId === groupId && mappedItem.calcType === calcType) {
          const newItems = [
            ...items,
            item
          ]

          const newMappedItem = {
            ...mappedItem,
            items: newItems
          }

          return newMappedItem
        }

        return mappedItem
      })

      const newProduct = {
        ...selectedProduct,
        selectedAdditionals: [
          ...newSelectedAdditionals
        ]
      }

      setSelectedProduct(newProduct)

      return
    }
  }

  function handleAdditionalsQuantity(item) {
    const {
      id,
      quantity: quantityIncrement,
      groupId,
      calcType,
      minimum,
      maximum
    } = item

    const {
      products
    } = cart

    const cartProductPrevious = (products && products.length) ? (products.filter(filteredItem => filteredItem.id === item.id))[0] : null
    const selectedAdditionals = (selectedProduct && selectedProduct.selectedAdditionals) || cartProductPrevious.selectedAdditionals
    const selectedAdditionalGroup = (selectedAdditionals && selectedAdditionals.length && (selectedAdditionals.filter(filteredItem => filteredItem.groupId === groupId && filteredItem.calcType === calcType)[0])) || null

    if (selectedAdditionalGroup && selectedAdditionalGroup.items && selectedAdditionalGroup.items.length + quantityIncrement > maximum) {
      return false
    }

    if (!selectedAdditionalGroup) {
      const newItem = {
        ...item,
        quantity: 1
      }

      const newGroup = {
        groupId,
        calcType,
        minimum,
        maximum,
        items: [
          newItem
        ]
      }

      const newProduct = {
        ...selectedProduct,
        selectedAdditionals: [
          ...selectedAdditionals,
          newGroup
        ]
      }

      setSelectedProduct(newProduct)

      return
    }

    if (selectedAdditionalGroup) {
      const {
        items = []
      } = selectedAdditionalGroup

      const newSelectedAdditionals = selectedAdditionals.map(mappedItem => {
        const hasSelectedAdditional = mappedItem.items && mappedItem.items.filter(filteredItem => filteredItem.code === id)

        const quantityTotal = items.reduce((acc, current) => {
          return Number(acc + current.quantity)
        }, 0)
        const quantityRemaining = maximum - quantityTotal

        if (!hasSelectedAdditional.length && mappedItem.groupId === groupId && mappedItem.calcType === calcType && quantityRemaining) {
          const newItem = {
            ...item,
            quantity: 1
          }

          const newItems = [
            ...items,
            newItem
          ]

          const newMappedItem = {
            ...mappedItem,
            items: newItems
          }

          return newMappedItem
        }

        if (hasSelectedAdditional.length && mappedItem.groupId === groupId && mappedItem.calcType === calcType && ((quantityRemaining && quantityIncrement > 0) || ((!quantityRemaining || quantityRemaining) && quantityIncrement < 0))) {
          const newItems = items && items.length && (items.map((newItemsMappedItem) => {
            const {
              quantity: newItemsMappedItemQuantity = 0
            } = newItemsMappedItem

            const newQuantity = newItemsMappedItemQuantity + quantityIncrement

            if (newQuantity >= 1 && newQuantity <= maximum && hasSelectedAdditional && hasSelectedAdditional[0] && hasSelectedAdditional[0].code === newItemsMappedItem.code) {
              return {
                ...newItemsMappedItem,
                quantity: newQuantity
              }
            }

            if (newQuantity < 1 && hasSelectedAdditional && hasSelectedAdditional[0] && hasSelectedAdditional[0].code === newItemsMappedItem.code) {
              return false
            }

            return {
              ...newItemsMappedItem
            }
          })).filter(filteredItem => filteredItem !== false)

          const newMappedItem = {
            ...mappedItem,
            items: newItems
          }

          return newMappedItem
        }

        return mappedItem
      })

      const newProduct = {
        ...selectedProduct,
        selectedAdditionals: [
          ...newSelectedAdditionals
        ]
      }

      setSelectedProduct(newProduct)

      return
    }
  }

  async function handleAdd() {
		if (address && !address.main) {
			setAddressPostalCodeRequest(true)
		}

    const product = {
      ...selectedProduct,
      quantity: lastQuantity
    }

    const {
      additionals,
      selectedAdditionals
    } = selectedProduct

    const requiredAdditionals = additionals && additionals.filter(filteredItem => filteredItem.minimum >= 1)

    const hasMinimum = requiredAdditionals && requiredAdditionals.filter(requiredAdditionalsFilteredItem => {
      const selectedAdditional = selectedAdditionals.filter(selectedAdditionalsFilteredItem =>
        selectedAdditionalsFilteredItem.groupId === requiredAdditionalsFilteredItem.groupId &&
        selectedAdditionalsFilteredItem.calcType === requiredAdditionalsFilteredItem.calcType &&
        (selectedAdditionalsFilteredItem.items && selectedAdditionalsFilteredItem.items.length >= requiredAdditionalsFilteredItem.minimum)
      )

      if (!selectedAdditional.length) {
        return false
      }

      const {
        items = []
      } = selectedAdditional[0]

      if (!items.length) {
        return false
      }

      return true
    })

    const hasNoMinimum = requiredAdditionals && requiredAdditionals.filter(requiredAdditionalsFilteredItem => {
      const selectedAdditional = selectedAdditionals.filter(selectedAdditionalsFilteredItem =>
        selectedAdditionalsFilteredItem.groupId === requiredAdditionalsFilteredItem.groupId &&
        selectedAdditionalsFilteredItem.calcType === requiredAdditionalsFilteredItem.calcType &&
        (selectedAdditionalsFilteredItem.items && selectedAdditionalsFilteredItem.items.length >= requiredAdditionalsFilteredItem.minimum)
      )

      if (!selectedAdditional.length) {
        return true
      }

      const {
        items = []
      } = selectedAdditional[0]

      if (!items.length) {
        return true
      }

      return false
    })

    hasMinimum && hasMinimum.length && hasMinimum.forEach((hasMinimum) => {
      const group = document.getElementById(`additionals-group-${hasMinimum.groupId}-${hasMinimum.calcType}`)

      group.classList.remove('required')
    })

    if (hasMinimum.length < requiredAdditionals.length && hasNoMinimum.length) {
      hasNoMinimum.forEach((hasNoMinimumItem) => {
        const group = document.getElementById(`additionals-group-${hasNoMinimumItem.groupId}-${hasNoMinimumItem.calcType}`)

        group.classList.add('required')
      })

      return false
    }

    const verified = await checkDifference(product)

		if (cartProductPreviousQuantity && !verified) {
			setQuantity({
				id: product.id,
				quantity: 1
			})

			handleLayoutMode(layoutModeEnum.sideFullContent)
			handleSidebarContent(sidebarContentEnum.cart)
			handleSidebarOpened(true)

			handleClose()

			return
		} else {
			await addProduct(product)
			handleLayoutMode(layoutModeEnum.sideFullContent)
			handleSidebarContent(sidebarContentEnum.cart)
			handleSidebarOpened(true)

			handleClose()
		}
  }

	const checkDifference = (product) => {
		const {
			products
		} = cart

		const cartProductPrevious = (products && products.length) ? (products.filter(filteredItem => filteredItem.id === product.id))[0] : null

		let difference = false
		product && product.selectedAdditionals.forEach((additional) => {
			const selectedProductGroupId = (cartProductPrevious && cartProductPrevious.selectedAdditionals.find((selected) => selected.groupId === additional.groupId)) || null
			if (selectedProductGroupId) {
				if (additional.items && additional.items.length > 0) {
					const checkAddionals = []
					additional.items.forEach((mappedItem) => {
						const objCheck = selectedProductGroupId.items.find((selected) => selected.code === mappedItem.code) || null

						if (objCheck) checkAddionals.push("found")
						else checkAddionals.push("not_found")
					})

					const verified = (checkAddionals.indexOf("not_found") > -1) ? true : false
					difference = verified
				} else {
					difference = false
				}
			}
		})

		console.log(difference)
		return difference
	}

  if (selectedProduct && (!selectedProduct.name || !selectedProduct.value)) {
    return null
  }

  return <Wrapper id='store-product'>
    <CloseButton className='close-button' onClick={() => {
      handleClose()
    }}>
      <CustomIcon name='Times' />
    </CloseButton>

		{selectedProduct && <>
			{selectedProduct.image ? <ImageWrapper>
				<Image className='main' src={selectedProduct.image} alt={selectedProduct.name} />
			</ImageWrapper> : null}

			<H1>{selectedProduct.name}</H1>

			<Paragraph>{selectedProduct.description}</Paragraph>

			{!selectedProduct.promotionalValue && <Paragraph className='value'>{value}</Paragraph>}

			{selectedProduct.promotionalValue && <Paragraph className='promo'>{`R$ ${Number(selectedProduct.promotionalValue).toFixed(2).replace('.', ',')}`} <Span>{value}</Span></Paragraph>}

			{selectedProduct.additionals && selectedProduct.additionals.length ? <Additionals
				data={selectedProduct.additionals}
				cart={cart}
				selectedProduct={selectedProduct}
				handleQuantity={handleAdditionalsQuantity}
				handleChange={handleAdditionalsChange}
			/> : null}

			<Notes
				total={total}
				update={!!cartProductPreviousQuantity}
				quantity={lastQuantity}
				handleQuantity={handleQuantity}
				handleAdd={handleAdd}
				status={storeStatus}
				scheduling={scheduling}
			/>
		</>}
  </Wrapper>
}

ProductPage.propTypes = {
	storeStatus: PropTypes.number,
	selectedProduct: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		description: PropTypes.string,
		image: PropTypes.string,
		quantity: PropTypes.number,
		value: PropTypes.number,
		promotionalValue: PropTypes.number,
		selectedAdditionals: PropTypes.arrayOf(PropTypes.shape({})),
		additionals: PropTypes.arrayOf(PropTypes.shape({})),
		isNewAddition: PropTypes.bool
	}),
	selectedCategory: PropTypes.shape({}),
	removeSelectedProductAdditional: PropTypes.func,
	setSelectedProduct: PropTypes.func,
	addProduct: PropTypes.func,
	removeProduct: PropTypes.func,
	cart: PropTypes.shape({
		products: PropTypes.arrayOf({})
	}),
	handleGTM: PropTypes.func,
	handleClose: PropTypes.func,
	scheduling: PropTypes.bool,
	setAddressPostalCodeRequest: PropTypes.func,
	address: PropTypes.shape({
		main: PropTypes.string
	})
}

const mapStateToProps = (state) => {
  return {
    storeStatus: state.store.store && state.store.store.status,
    storeName: state.store.store && state.store.store.name,
    selectedProduct: (state.menu.selectedProduct) || null,
    selectedCategory: state.store.store && state.store.store.selectedCategory,
    openModalCategory: state.store.store && state.store.store.selectedCategory && state.store.store.selectedCategory.openModalCategory,
    cart: state.main.cart,
    modalityId: state.main.modality.id,
    scheduling: state.store.store && state.store.store.scheduling,
    address: (state.address.address) || {}
  }
}

const GetConnection = connect(mapStateToProps, {
  setSelectedProduct: menuActions.setSelectedProduct,
	setAddressPostalCodeRequest: addressActions.setAddressPostalCodeRequest,
  removeSelectedProduct,
  removeSelectedProductAdditional,
  addProduct,
  removeProduct,
  handleGTM,
  setSelectedCategory,
  setOpenCart,
	setQuantity
})

export const Product = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'payment' })
)(ProductPage))
