import React from 'react'

import {
  DefaultImage as Image
} from '../../components'

import {
  VerticalProductContainer,
  VerticalProductContent,
  VerticalProductDescription,
  VerticalProductImageContainer,
  VerticalProductName,
  VerticalProductPrice,
  VerticalProductPriceContainer,
  VerticalProductPromotionalPrice
} from './styles'

export function VerticalProduct(props) {
  const {
    handleView,
    name,
    description,
    value,
    image,
    promotionalValue,
    storeLogo,
    storeImg
  } = props

  return (
    <VerticalProductContainer onClick={handleView}>
      <VerticalProductContent>
        <VerticalProductName>{name}</VerticalProductName>
        <VerticalProductDescription>
          {description ? description.length < 50 ? description :
            name && name.length > 20 ? `${description.substring(0, 50)}...` :
              `${description.substring(0, 90)}...` : description}
        </VerticalProductDescription>
        <VerticalProductPriceContainer>
          <VerticalProductPrice className={promotionalValue ? 'promo' : 'value'} >{promotionalValue ? promotionalValue : value}</VerticalProductPrice>
          {promotionalValue && <VerticalProductPromotionalPrice>{value}</VerticalProductPromotionalPrice>}
        </VerticalProductPriceContainer>
      </VerticalProductContent>
      <VerticalProductImageContainer>
        {image && <Image src={image} alt={name} />}

        {!image && <Image src={storeImg} alt={name} />}

        {!image && storeLogo && <Image src={storeLogo} alt={name} />}
      </VerticalProductImageContainer>
    </VerticalProductContainer>
  )
}
