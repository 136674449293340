import React, {
	useEffect,
	useState
} from 'react'
import { connect } from 'react-redux'
import {
	Dialog,
	Slide
} from '@material-ui/core'
import { Helmet } from 'react-helmet'

import {
	useStoreSettings
} from '../hooks'

import {
	cart,
	coupon,
	order
} from '../../state'

import history from '../../history'

import {
	pipe,
	GetContent,
	calculateCartTotal
} from '../../domain/helpers'

import {
	setCart,
	setUserHistory,
	setNotification,
	setCVV,
	setSelectedDeliveryDate,
	handleGTM,
	handlePixel
} from '../../redux/actions/main'

import {
	CouponsList
} from '../coupons/simple'

import {
	MainHeader
} from '../main-header'

import { DeliveryDateSelecion } from './Details/DeliveryDateSelecion'

import {
	Wrapper,
	Main,
} from './styles'

import {
	Details,
	Summary
} from './'

export const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

function OrderConfirmationComponent(props) {
	const {
		userCard,
		address,
		storeName,
		storeStatus,
		loading,
		cpf,
		location,
		theme,
		addCoupon,
		setCoupon,
		setCartCoupon,
		coupon,
		cartCoupon,
		handleGTM,
		postOrder,
		setCart,
		setUserHistory,
		setNotification,
		modalityId,
		storeImg,
		storeAddress,
		scheduling,
		selectedDeliveryDate,
		setSelectedDeliveryDate,
		deliveryFee,
		products,
		URLParameters,
		couponField,
		handlePixel
	} = props

	const storeLogo = theme.logoImageStore

	const [hasCpf, setHasCpf] = useState(false)
	const [visibleCoupons, setVisibleCoupons] = useState(false)
	const [deliveryDateSelectionOpened, setDeliveryDateSelectionOpened] = useState(false)
	const [error, setError] = useState(undefined)
	const [success, setSuccess] = useState(false)
	const [above, setAbove] = useState(false)
	const [cartTotal, setCartTotal] = useState(0)

	const {
		url,
		title,
		description
	} = useStoreSettings()

	useEffect(() => {
		//Pixel 1.0 - Confirmação do pedido
		const checkoutDataFinish = {
			eventName: `InitiateCheckout`,
			body: {
				currency: 'BRL',
				content_category: products ? products.map(function (item) {
					return item.category.name || null
				}) : [],
				content_ids: products ? products.map(function (item) {
					return item.id
				}) : [],
				contents: products ? products.map(function (item) {
					return {
						id: item.id,
						quantity: item.quantity
					}
				}) : [],
				num_items: products.length,
				value: 1
			}
		}

		handlePixel(checkoutDataFinish)
	}, [])

	useEffect(() => {
		setCartTotal(calculateCartTotal(products))
	}, [
		products,
	])

	function postCoupon() {
		addCoupon([(data) => {
			setCartCoupon({
				error: true
			})
			setError(data.message)
			setCoupon('')
			setTimeout(() => {
				setError(undefined)
			}, 5500)
		}, (data) => {
			setCartCoupon({
				code: coupon,
				...data
			})
			setSuccess(true)
			setTimeout(() => {
				setSuccess(false)
			}, 5500)
		}])
	}

	function handleCoupon(data) {
		setCartCoupon({
			...data
		})

		setVisibleCoupons(false)
	}

	function handleAddCoupon() {
		postCoupon()
	}

	function handleRemoveCoupon() {
		setCartCoupon('')
		setCoupon('')
	}

	function handleViewCoupons() {
		setVisibleCoupons(true)
	}

	function handleCloseDialogCoupons() {
		setVisibleCoupons(false)
	}

	function handleHasCpf() {
		setHasCpf(!hasCpf)
	}

	async function handleFinish() {
		if (storeStatus && storeStatus !== 1) {
			setNotification({
				type: 'warning',
				message: 'Ops! A loja está fechada. Tente em outro horário!!'
			})

			return
		}

		await postOrder([{
			name: 'order',
			type: 'error',
			callback: () => {

			}
		}, {
			name: 'order',
			type: 'success',
			callback: (payload) => {

				const cart = cartTotal?.toFixed(2)
				const finishCart = parseFloat(cart)

				const checkoutDataFinish = {
					'event': `purchase`,
					'ecommerce': {
						'currency': 'BRL',
						'store': storeName,
						'value': finishCart || null,
						'shipping': deliveryFee || null,
						'affiliation': storeName || null,
						'transaction_id': payload.orderId || 0,
						'coupon': couponField || null,
						'modalidade_retirada': modalityId === 2 ? 'retirar_na_loja' : 'delivery',
						'items': products ? products.map(function (item) {
							return {
								'item_name': item.name,
								'item_id': item.id,
								'price': item.promotionalValue ? item.promotionalValue : item.value,
								'discount': null,
								'item_category': item.category.name || null,
								'quantity': item.quantity
							}
						}) : []
					}
				}

				handleGTM(checkoutDataFinish)

				setCart({
					products: null
				})

				setSelectedDeliveryDate(null)

				history.push(`/receipt${URLParameters}`)
			}
		}, {
			name: 'address',
			type: 'error',
			callback: () => {
				setUserHistory({
					next: 'cart',
					back: 'cart'
				})

				setNotification({
					type: 'warning',
					message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
				})

				history.push(`/new-address${URLParameters}`)
			}
		}, {
			name: 'address',
			type: 'success',
			callback: () => {
				console.warn('Address created')
			}
		}], {
			update: false
		})
	}

	return <Wrapper>
		<Helmet>
			<title>{`${title} - Confirmação de pedido`}</title>
			<meta name='description' content={description} />
			<link rel='canonical' href={`${url}/order-confirmation`} />
		</Helmet>

		<MainHeader
			theme={theme}
			mode='simple'
			storeName={storeName}
			storeLogo={storeLogo}
		/>

		<Main>
			<Details
				loading={loading}
				userCard={userCard}
				address={address}
				coupon={cartCoupon ? cartCoupon.code : coupon}
				cartCoupon={cartCoupon}
				setCoupon={setCoupon}
				hasCpf={hasCpf}
				cpf={cpf}
				storeStatus={storeStatus}
				handleRemoveCoupon={handleRemoveCoupon}
				handleHasCpf={handleHasCpf}
				handleFinish={handleFinish}
				handleAddCoupon={handleAddCoupon}
				handleViewCoupons={handleViewCoupons}
				theme={theme}
				modalityId={modalityId}
				storeAddress={storeAddress}
				scheduling={scheduling}
				setDeliveryDateSelectionOpened={setDeliveryDateSelectionOpened}
				selectedDeliveryDate={selectedDeliveryDate}
				URLParameters={URLParameters}
				error={error}
				success={success}
				couponField={couponField}
				above={above}
			/>

			<Summary
				scheduling={scheduling}
				mode='simple'
				storeName={storeName}
				storeLogo={storeLogo}
				storeImg={storeImg}
				pathName={location.pathname}
				above={above}
				setAbove={setAbove}
			/>

			<Dialog
				onClose={() => handleCloseDialogCoupons()}
				aria-labelledby="coupons-list-dialog"
				open={visibleCoupons}
				TransitionComponent={Transition}
				maxWidth={'xs'}
				className="my-coupons"
				fullWidth={true}
			>
				<CouponsList
					theme={theme}
					handleCoupon={handleCoupon}
					handleCloseCoupon={setVisibleCoupons}
					modal={true}
				/>
			</Dialog>
			<Dialog
				onClose={() => setDeliveryDateSelectionOpened(false)}
				aria-labelledby='delivery-date-selection'
				open={deliveryDateSelectionOpened}
				TransitionComponent={Transition}
				maxWidth={'xs'}
				PaperProps={{
					style: {
						width: '25rem',
						borderRadius: '.75rem'
					}
				}}
				fullWidth={false}
			>
				<DeliveryDateSelecion
					selectedDeliveryDate={selectedDeliveryDate}
					close={() => setDeliveryDateSelectionOpened(false)} />
			</Dialog>

		</Main>
	</Wrapper>
}

const mapStateToProps = (state) => {
	return {
		storeName: state.store.store && state.store.store.name,
		storeStatus: state.store.store && state.store.store.status,
		coupon: state.main.coupon || '',
		couponField: state.main.couponField || '',
		cartCoupon: (state.main.cart && state.main.cart.coupon) || '',
		cpf: state.user.cpf || '',
		userCard: state.main.userCard || '',
		address: state.address.address || '',
		loading: state.loading.loading || '',
		products: (state.main.cart && state.main.cart.products) || [],
		modalityId: state.main.modality && state.main.modality.id,
		storeImg: (state.store.store && state.store.store.logo) || '',
		storeAddress: state.store.store && state.store.store.address,
		scheduling: state.store.store && state.store.store.scheduling,
		selectedDeliveryDate: state.main.selectedDeliveryDate,
		deliveryFee: state.store.store && state.store.store.deliveryFee,
		URLParameters: (state.main.URLParameters) || ''
	}
}

const GetConnection = connect(mapStateToProps, {
	addCoupon: coupon.addCoupon,
	setCoupon: coupon.setSelectedCoupon,
	postOrder: order.addOrder,
	setCartCoupon: cart.setCartCoupon,
	setCart,
	setUserHistory,
	setNotification,
	setCVV,
	setSelectedDeliveryDate,
	handleGTM,
	handlePixel
})

export const OrderConfirmation = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'order-confirmation' })
)(OrderConfirmationComponent))
