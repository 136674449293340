import styled,{keyframes} from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`
export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 149px;
  width: 88%;
  background: #000;
  position: absolute;
  opacity: 0.8;
  border-radius: 15px;
  z-index: 10;

  > div {
    margin-top: 30px;

    &.over{
      height: 100%;
      min-height: 100%;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      color: #fff;
      text-align: center;
    } 

    &.text{
      height: 100%;
      min-height: 100%;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      color: #fff;
      font-size: 12px;
      margin-top: 5px;
      text-align: center;
    }
  }
`

export const Div = styled.div``


export const H2 = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: ${ props => props.theme.main.general.blue06Color || '#000'};
`

export const StoreInfoWrapper = styled.div`
  > span {
    font-size: 13px;
    color: ${ props => props.theme.main.general.blue02Color || '#545454'};
  }
`

export const BoxFreeShipping = styled.div`
	font-family: 'Roboto';
	display: flex;
	flex-direction: row;
	border: 1px solid #007141;
	color: #007141;
	border-radius: 4px;
	background: #fff;
	font-size: 12px;
	padding: 2px 2px;
	& .icon {
		width: 22px;
		height: 17px;
		margin-right: 5px;
	}	
`;

const text = (amount) => keyframes`
  0% { content:"Entrega grátis"; }
	80% { content:"Entrega grátis"; }
	80% { content:"Acima de ${amount}"; }
  100% { content:"Acima de ${amount}"; }
`;

export const FreeShippingLabel = styled.span`
	&::after {
		content: "";
		animation: ${props => text(props.amount)} 8s linear infinite;
		}
`;

export const Span = styled.span`
  &.dot {
    margin-left: 5px;
    margin-right: 5px;
  }
`
export const Background = styled.div`
  position: relative;
  margin-bottom: 10px;
  min-width: 100%;
  max-width: 100%;

  > img {
    width: 100%;
    border: #C6C6C6 1px solid;
    border-radius: 15px;

    &.banner{
      height: 149px;
    }

    &.logo {
      width: 65px;
      height: auto;
      position: absolute;
      right: 30px;
      top: 100%;
      transform: translate(0, -50%);
      border-radius: 50%;
      z-index: 10;
    }
  }
`

export const ImageLogo = styled.img``

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
`
export const Distance = styled.div`
  font-size: 12px;
  line-height: 2;
`
