import styled from 'styled-components'

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: calc(100% + 1.25rem);
  max-width: calc(100% + 1.25rem);
  overflow: hidden;
  max-height: 24rem;
  min-height: 24rem;
`
export const H1 = styled.h1`
  margin: 0;
  padding: 0;
  min-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey01) || '#e2e2e2'} .063rem solid;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
  font-size: 1.125rem;
  flex: none;
  line-height: 1rem;
  position: relative;

  > span {
    font-size: 1rem;
    line-height: 1.25rem;
    position: absolute;
    top: 50%;
    right: 1.25rem;
    transform: translate(0, -1.25rem);
  }
`

export const Span = styled.span``

export const Button = styled.button`
  background: #fff;
  padding: 0;
  margin: 0;

  float: left;
  &.disabled{
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.greyMedium) || '#333'};
  }
`
export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  position:absolute;
  margin-top: 6rem;

  min-width: ${props => {
    if (props.maxWidth) {
      return `${props.maxWidth}px`
    }

    return '100%'
  }};
  max-width: ${props => {
    if (props.maxWidth) {
      return `${props.maxWidth}px`
    }

    return '100%'
  }};

  > button {
    border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey01) || '#333'} .125rem solid;
    border-radius: 50%;
    flex: 1;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    font-size: .888rem;
    min-width: 2rem;
    min-height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 60rem){
    display: none;
  }
`
