interface UserAddressesList  {
  id: number
  address: string
  number: string | number
  cep: string
  neighborhood: string
  city: string
  state: string
  country: string
  complement: string
  reference: string
  category: string | number
  latitude: string | number
  longitude: string | number
}

export const mapUserAddressesList = (data: any) : UserAddressesList[] | [] => {
  const addresses = (data['Enderecos'] && data['Enderecos'].length && data['Enderecos'].map((mappedItem: any) => {
    return {
      id: mappedItem['Id'],
      address: mappedItem['Endereco'],
      number: mappedItem['Numero'],
      cep: mappedItem['Cep'],
      neighborhood: mappedItem['Bairro'],
      city: mappedItem['Cidade'],
      state: mappedItem['Estado'],
      country: mappedItem['Pais'],
      complement: mappedItem['Complemento'],
      reference: mappedItem['Referencia'],
      type: mappedItem['TipoEndereco'],
      latitude: mappedItem['Latitude'],
      longitude: mappedItem['Longitude']
    }
 })) || []

  return addresses
}

interface UserAddressCreateRequestAddresses {
  Endereco: string
  Numero: number
  Cep: string
  Bairro: string
  Cidade: string
  Estado: string
  Pais: string
  Complemento: string
  Referencia: string
  TipoEndereco: string
  Latitude: string
  Longitude: string
  IdLocal: number
}
interface UserAddressCreateRequest {
  Enderecos: UserAddressCreateRequestAddresses[]
}

export const mapUserAddressCreateRequest = (data: any = {}) : UserAddressCreateRequest => {
  const {
    main,
    number,
    cep,
    neighborhood,
    city,
    state,
    country = 'Brasil',
    complement,
    reference,
    type,
    latitude,
    longitude
  } = data

  return {
    Enderecos: [{	
      Endereco: main,
      Numero: number,
      Cep: cep && cep.replace('-', ''),
      Bairro: neighborhood,
      Cidade: city,
      Estado: state,
      Pais: country,
      Complemento: complement,
      Referencia: reference,
      TipoEndereco: (type && String(type)) || '0',
      Latitude: latitude,
      Longitude: longitude,
      IdLocal: 4
    }]
  }
}

interface UserAddressCreateData {
  id: number
  success: boolean
  addresses: any[]
}

export const mapUserAddressCreateData = (data: any) : UserAddressCreateData => {
  return {
    id: data['EnderecoDeliveryId'] || (data['Enderecos'] && data['Enderecos'][0] && data['Enderecos'][0]['Id']),
    success: data['Sucesso'],
    addresses: data['Enderecos']
  }
}
interface UserAddressDeleteData {
  success: boolean
}

export const mapUserAddressDeleteData = (data: any) : UserAddressDeleteData => {
  return {
    success: data['Sucesso']
  }
}
