import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  max-height: 2.5rem;
  min-height: 2.5rem; 
  z-index: 2;
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundMain) || '#999' };
  padding-left: 1.25rem;
  margin-top: 1rem;
  border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#999' } .063rem solid;
  transition: all .5s ease-out;
  display: none;

  ${ props => {
    if (props.fixed) {
      return `position: fixed; top: 47px; left: 0; opacity: 1; overflow: hidden;  margin-top: 0; background: #fff; display: unset;`
    }

    return ``
  }}

  @media (min-width: 60rem) {
    display: none;
  }
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  max-height: 3.75rem;
  min-height: 3.75rem;
  height: 2.5rem;
  min-width: 100%;
  max-width: 100%;
  transition: all .25s ease-in;

  &::-webkit-scrollbar {
    width: 0 !important
  }
  
  > a{
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bold;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#999' };
    font-size: .875rem;
    padding-bottom: .625rem;
    min-height: 2.5rem;
    max-height: 2.5rem;
    line-height: 2.5rem;
    padding: 0;
    transition: all .25s;
    text-decoration: none;
    margin-right: 1.25rem;
    padding: 0 .125rem;

    &:hover, &:active, &.active {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
      border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } .125rem solid;
      text-decoration: none;
      font-size: 1rem;
    }

    &:last-child {
      margin-right: 1.25rem;
      padding-right: 1.25rem;
    }
  }
`
