import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 5.5rem;
  min-width: 100%;
  max-width: 100%;
  border: .1rem solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
  border-radius: .8rem;
  padding: .75rem 1rem;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;

  @media (min-width: 60rem) {
    border-radius: 1rem;
    height: 5.5rem;
    padding: .75rem 1rem;
  }

  > * {
    box-sizing: border-box;
    pointer-events: none;
  }

  label {
    cursor: pointer;
  }
`

export const CardSide = styled.div`
  flex: 0 1 3rem;
  height: 100%;
  padding: 0 1rem 0 0;
  margin: 0 1rem 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-right: .1rem solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };

  svg {
    width: 2.5rem;

    path {
      fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    }
  }
`

export const CardContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 100%;
  justify-content: space-between;

  svg {
    width: 1.625rem;
    height: 1.625rem;
    align-self: center;
    cursor: pointer;

    g {
      stroke: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    }

    path {
      fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    }
  }
` 

export const CuponDetails = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  max-width: calc(100% - 2.5rem);
`

export const CuponCode = styled.label`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0 0 .5rem 0;
  min-height: 1.2rem;
`

export const CuponDescription = styled.p`
  font-weight: 0;
  font-size: .8rem;
  line-height: .8rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
	margin: 0;
	padding: 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
	min-width: 100%;
`
