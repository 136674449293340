import React, {
  useState,
  useRef,
  useEffect,
  useCallback
} from "react"

import {
  Wrapper,
  Steps
} from './styles'

interface StepperProps {
  steps: Step[]
}

interface Step {
  title: string
  element: (stepProps: StepProps) => JSX.Element
}

export interface StepProps {
  goNextStep: () => void
  goPreviousStep: () => void
  currentStep: number
  isLast: boolean
  isFirst: boolean
  step: number
}

export const Stepper: React.FC<StepperProps> = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState<number>(1)
  const stepperSelector = useRef<HTMLDivElement>(null)

  const goNextStep = (step?: number) => {
    const nextStep = step || currentStep + 1
    if (nextStep <= steps.length) {
      setCurrentStep(nextStep)
    }
  }

  const goPreviousStep = () => {
    const previousStep = currentStep - 1
    if (previousStep >= 1) {
      setCurrentStep(previousStep)
    }
  }

  const moveStepper = useCallback(() => {
    if (stepperSelector.current) {
      const stepper = stepperSelector.current
      const stepWidth = stepper.offsetWidth
      stepper.style.transform = `translate(-${
        stepWidth * (currentStep - 1)
      }px, 0)`
    }
  }, [
    currentStep
  ])

  useEffect(() => {
    moveStepper()
  }, [
    currentStep,
    moveStepper
  ])

  return (
    <Wrapper>
      <Steps ref={stepperSelector}>
        {steps.map((step, i) => {
          const key = `stepper-item-${i}`
          
          return <step.element
            key={key}
            step={i + 1}
            goNextStep={goNextStep}
            goPreviousStep={goPreviousStep}
            currentStep={currentStep}
            isFirst={i === 0}
            isLast={i === steps.length - 1}
          />
        })}
      </Steps>
    </Wrapper>
  )
}
