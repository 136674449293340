import React from 'react'

import {Order} from '../order'

import {
  Wrapper
} from './styles'

export function Content({
  data,
  handleOrder,
  orderId
}) {
  return <Wrapper>
    { data.map((data, index) => {
      const key = `placed-orders-content-list-item-${ index }}`

      const {
        amountPaid: total,
        deliveryPlace: address,
        store,
        id,
        inProgress,
        items,
        modalityId,
        shortDate: date,
      } = data

      const qty = items.length || 0

      return (
        <Order
          key={key}
          selected={id === orderId}
          id={id}
          date={date}
          total={total}
          qty={qty}
          address={address}
          store={store}
          modalityId={modalityId}
          handleClick={handleOrder}
          inProgress={inProgress}
          />
      )
    })}
  </Wrapper>
}
