import styled from 'styled-components'

import { Wrapper as Card } from '../order/styles'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding: 0 1rem;

  @media (min-width: 60rem) {
    padding: 0 2rem;
  }

  ${Card} {
    flex: 1;
    margin: 0 0 1.25rem 0;

    @media (min-width: 60rem) {
      flex: 0 1 calc(50% - .5rem);
      max-width: calc(50% - .5rem);
      margin: 0 0 1.25rem 0;
      min-width: unset;

      &:nth-child(2n + 1) {
        max-width: calc(50% - .775rem);
        margin: 0 1.25rem 1.75rem 0;
        min-width: unset;
      }
    }
  }
`
