import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  background: #fff;
  max-height: 5.625rem;
  min-height: 5.625rem;
  padding: 1.25rem;
  box-shadow: 7px -3px .313rem #cecece;
  cursor: pointer;
  position: fixed;
  left: 0;
  bottom: 0;
  max-width: 100%;
  min-width: 100%;
  z-index: 1;

  &.motion {
    opacity: 1;
  }

  @media (min-width: 60rem) {
    display: none;
  }
`

export const Button = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border-radius: .938rem;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  padding: .625rem;
  box-sizing: border-box;
  height: 3.438rem;

  > span {
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff' };
    font-size: .875rem;
    font-weight: bold;
  }
  
  > div {
    &.icon {
      position: relative;

      > span {
        &.quantity {
          position: absolute;
          right: -.313rem;
          top: 0;
          color: #fff;
          font-size: .625rem;
          line-height: .625rem;
          border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff' } .125rem solid;
          height: 1rem;
          width: 1rem;
          border-radius: 50%;
          text-align: center;
          vertical-align: middle;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
        }
      }

      > span {
        &.icon {
          > svg {
            width: 1.875rem;
            height: auto;
          }
        }
      }
    }
  }
`

export const Span = styled.span``

export const Div = styled.div``
