import styled from 'styled-components'

export const ReceiptSumaryContainer = styled.div`
  grid-area: summary;
  width: 100%;
  box-sizing: border-box;
  box-sizing: border-box;
	padding: 1.5rem;

  * {
    box-sizing: border-box;
  }

  @media (min-width: 60rem) {
    padding: 0 0 0 1.5rem;
  }

  #cart-content {
    margin: 2rem 0 0 0;
    
    #notification {
      display: none;
    }
  }
`

export const ReceiptSummaryStore = styled.div`
  display: none;
  align-items: center;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  padding: 0 0 1rem 0;
  margin: 0 0 1rem 0;

  @media (min-width: 60rem) {
    display: flex;
  }
`

export const ReceiptSummaryContent = styled.div`
  display: flex;
  flex-direction: column;

	> div {
		> ul {
			padding: 1.5rem 0 0;
		}
	}

  @media (min-width: 40rem) {
    overflow: auto;

		> div {
			> ul {
				padding: 1.5rem 0 0;
			}
		}

    &::-webkit-scrollbar {
      width: 1.5rem;
    }

    &::-webkit-scrollbar-thumb {
      border-left: 1rem solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
`

export const StoreContainer = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1 1 70%;
`

export const StoreLabel = styled.label`
  font-weight: 600;
  font-size: .875rem;
  line-height: 1.188rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
`

export const StoreName = styled.h3`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' }; 
  margin: 0;
`

export const StoreLogo = styled.img`
  flex: 0 1 30%;
  max-width: 4rem;
`
