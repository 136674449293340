import React, {
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import {
  connect
} from 'react-redux'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
  CustomIcon
} from '../../components'

import {
  CategoryWrapper,
  H1,
  Span,
  Button,
  Actions
} from './styles'

import {
  List
} from '.'

const Context = React.createContext({})

function HorizontalMenuComponent(props) {
  const {
    selectedProduct,
    handleViewProduct,
    handleAddProduct,
    handleViewCategoriesProducts,
    categories,
    storeLogo,
    status,
    storeImg,
    setVisibleCategories,
    opened
  } = props

  useEffect(() => {
    const products = []

    if (categories && categories.length) {
      categories.forEach(function (item) {
        products.push(item.products)
      })
    }

    return () => { }
  }, [
    categories
  ])

  useEffect(() => {
    const products = []

    if (categories) {
      categories.forEach(function (item) {
        products.push(item.products)
      })
    }

    return () => { }
  }, [
    categories
  ])

  function handleCategoriesProducts(name) {
    const params = {
      name,
      selectedProductsCategories: true,
      openModalCategory: true
    }

    handleViewCategoriesProducts(params)
  }

  if (selectedProduct) {
    return null
  }

  const handleScrollLeft = (categorie) => {
    document.getElementById(`list-${categorie}`).scrollLeft += 100
  }

  const handleScrollRight = (categorie) => {
    document.getElementById(`list-${categorie}`).scrollLeft -= 100
  }

  return <>
    {categories && categories.length && categories.map((mappedItem, index) => {
      const {
        products,
        name: categoryName,
        id: categoryId
      } = mappedItem

      let hasScroll = null
      let maxwidth = null
      let div = document.getElementById(`list-${categoryName}`)

      if (div) {
        const max = div.getBoundingClientRect()
        const hasHorizontalScrollbar = div.scrollWidth > div.clientWidth
        hasScroll = hasHorizontalScrollbar

        maxwidth = max.width
      }

      const key = `stores-products-category-${categoryId}-${index}`

      return <CategoryWrapper name={mappedItem.name} className={`store-category-products`} id={`store-category-products-${mappedItem.name}`} key={key}>
        <H1>{mappedItem.name}
           <Span onClick={() => { 
             handleCategoriesProducts(mappedItem.name)
             setVisibleCategories(true)
            }}>Ver tudo <CustomIcon name='AngleRight' /></Span>
        </H1>

        <List
          direction={'horizontal'}
          categoryId={categoryId}
          categoryName={categoryName}
          products={products.slice(0, 8)}
          handleViewProduct={handleViewProduct}
          handleAddProduct={handleAddProduct}
          storeLogo={storeLogo}
          status={status}
          storeImg={storeImg}
          opened={opened}
        />
        {hasScroll &&
          <>
            <Actions maxWidth={maxwidth}>
              <Button onClick={() => {
                handleScrollRight(categoryName)
              }}><CustomIcon name='AngleLeft' /></Button>
              <Button onClick={() => {
                handleScrollLeft(categoryName)
              }}><CustomIcon name='AngleRight' /></Button>
            </Actions>
          </>
        }
      </CategoryWrapper>
    })}
  </>
}

HorizontalMenuComponent.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  handleAddProduct: PropTypes.func,
  handleViewCategoriesProducts: PropTypes.func,
  handleViewProduct: PropTypes.func,
  selectedProduct: PropTypes.string,
  status: PropTypes.number,
  storeLogo: PropTypes.string,
	storeImg: PropTypes.string,
  setVisibleCategories: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    categories: state.menu.categories || []
  }
}

const GetConnection = connect(mapStateToProps, {})

export const HorizontalMenu = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'store' })
)(HorizontalMenuComponent))
