import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  * {
    box-sizing: border-box;
  }


	> p {
		margin: 0;
		margin-bottom: 1.25rem;

		&:last-child {
			margin: 0;
		}
	}
`

export const ContentWrapper = styled.form`
  min-width: 100%;
  max-width: 100%;
  flex: 1;
  display: flex;
  flex: 0;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  flex: 1;
  align-content: flex-start;
  position: relative;
  padding: 1.5rem;

  &.motion {
    opacity: 1;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`

export const H1 = styled.h1`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  font-size: 1.25rem;
  padding: 0;
  margin: 0;
	margin-bottom: 1.25rem;
	flex: 1;
	text-align: center;

  @media (min-width: 60rem) {
    width: 100%;
    flex: 1 0 100%;
  }
`

export const Button = styled.button`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff'};
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  border-radius: .938rem;
  font-size: .875rem;
  font-weight: bold;
  flex: 1;
  max-height: 3.75rem;
  min-height: 3.75rem;
  line-height: 3.75rem;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
`

export const Input = styled.input`
	border: 0 none;
	border-bottom: #E1E1E1 .063rem solid;
	font-size: 1.125rem;
	text-align: center;
	height: 1.875rem;
	min-height: 1.875rem;
	max-height: 1.875rem;
	line-height: 1.875rem;
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
	margin: 0 auto;
	flex: 1;
	margin-bottom: 1.25rem;
	min-width: 50%;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`

export const FormFieldCardNumber = styled.div`
  flex: 1;
  width: 100%;
  margin: 0 0 1rem 0;

  @media (min-width: 60rem) {
    margin: 0 1rem 1rem 0;
  }
`

export const FormFieldCardName = styled.div`
  flex: 1 0 100%;
  width: 100%;
  margin: 0 0 1rem 0;
`

export const FormFieldCPF = styled.div`
  flex: 1 0 100%;
  width: 100%;
  margin: 0 0 1rem 0;
`

export const FormFieldValidity = styled.div`
  flex: 1 0 calc(50% - .5rem);
  width: calc(50% - .5rem);
  margin: 0 1rem 1rem 0;
`

export const FormFieldBirth = styled.div`
  flex: 1 0 calc(50% - .5rem);
  width: calc(50% - .5rem);
  margin: 0 1rem 1rem 0;
`

export const FormFieldCVV = styled.div`
  flex: 1 0 calc(50% - .5rem);
  width: calc(50% - .5rem);
  margin: 0 0 1rem 0;
`

export const FormFieldCPFNote = styled.div`
  flex: 1 0 100%;
  width: 100%;
  margin: 0 0 1rem 0;
`

export const FormActionContainer = styled.div`
  bottom: 1.5rem;
  position: absolute;
  right: 1.5rem;
  left: 1.5rem;
`
