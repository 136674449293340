import React, {
  useEffect
} from 'react'
import PropTypes from 'prop-types'

import {
  Wrapper
} from './styles'

import { Icon } from './../../components/common'
import { CustomIcon } from '../../components'

export function Coupon(props) {
  const {
    data,
    show
  } = props

  useEffect(() => {
    return () => { }
  }, [])

  if (!show || !data) {
    return null
  }

  const {
    description,
    value,
    minValue,
    typeValueModalityDelivery
  } = data

  if(typeValueModalityDelivery){
    return (<>
      {data && description && <Wrapper id='store-coupon'>
        <CustomIcon name='Gift' width={'50px'} height={'50px'} /> {`Ganhe frete grátis em pedidos a partir de R$ ${minValue && minValue.toFixed(2).replace('.', ',')}`}
      </Wrapper>}
    </>)
  }

  return (<>
    {data && description && <Wrapper id='store-coupon'>
      <Icon name='coupon' width={'50px'} height={'50px'} /> {`Ganhe R$ ${value && value.toFixed(2).replace('.', ',')} em pedidos a partir de R$ ${minValue && minValue.toFixed(2).replace('.', ',')}`}
    </Wrapper>}
  </>)
}

Coupon.propTypes = {
  data: PropTypes.shape({})
}
