interface CartList  {
  items: CartListItems
  store: CartStore
  modality: CartModality
  deliveryAddress: string
}

interface CartListItems {
  id: number
  name: string
  quantity: number
  description: string
  value: number
  orderId: number
  additionalText: string
  notes: string
  additionals: []
  fractionalQuantity: boolean
}

interface CartStore {
  id: number
  name: string
  address: string
}

interface CartModality {
  id: number
  fee: number
  minimumValue: number
}

export const mapCartList = (data: any) : CartList => {
  const items = (data['PedidoItems'] && data['PedidoItems'].length && data['PedidoItems'].map((mappedItem: any) => {
    return {
      name: mappedItem['Produto'],
      description: mappedItem['Description'],
      value: mappedItem['Valor'],
      additionalText: mappedItem['TextoAcompanhamento'],
      additionals: mappedItem['Acompanhamentos'] ? mappedItem['Acompanhamentos'] : [],
      quantity: mappedItem['Quantidade'],
      id: mappedItem['ProdutoId']
    }
 })) || []
  const store = data['Estabelecimento'] && {
    id: data['Estabelecimento']['Id'],
    name: data['Estabelecimento']['Nome'],
    address: data['Estabelecimento']['Endereco'],
  }
  const modality = data['ModalidadeRetirada'] && {
    id: data['ModalidadeRetirada']['Id'],
    fee: data['ModalidadeRetirada']['Valor'],
    minimumValue: data['ModalidadeRetirada']['ValorMinimo'],
  }
  const deliveryAddress = data['LocalEntrega']

	return {
    items,
    store,
    modality,
    deliveryAddress
  }
}
