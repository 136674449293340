interface AddressByGeolocationData {
	main: string
	number: string
	complement: string
	reference: string,
	neighborhood: string
	cep: string
	city: string
	state: string
	type: number
	error: boolean
}

export const mapAddressByGeolocationData = (data: any): AddressByGeolocationData => {
	const address = data && data.results && data.results[0].address_components && data.results[0].address_components.map((mappedItem: any) => {
		const filteredMain = mappedItem.types.filter((filteredItem: any) => filteredItem === 'route')
		if (filteredMain.length) {
			const main = (filteredMain && mappedItem.long_name) || ''

			return {
				main
			}
		}

		const filteredNumber = mappedItem.types.filter((filteredItem: any) => filteredItem === 'street_number')
		if (filteredNumber.length) {
			const number = (filteredNumber && mappedItem.long_name) || ''

			return {
				number
			}
		}

		const filteredNeighborhood = mappedItem.types.filter((filteredItem: any) => filteredItem === 'sublocality_level_1')
		if (filteredNeighborhood.length) {
			const neighborhood = (filteredNeighborhood && mappedItem.long_name) || ''

			return {
				neighborhood
			}
		}

		const filteredPostalCode = mappedItem.types.filter((filteredItem: any) => filteredItem === 'postal_code')
		if (filteredPostalCode.length) {
			const postalCode = (filteredPostalCode && mappedItem.long_name) || ''

			return {
				postalCode
			}
		}

		const filteredCity = mappedItem.types.filter((filteredItem: any) => filteredItem === 'administrative_area_level_2')
		if (filteredCity.length) {
			const city = (filteredCity && mappedItem.long_name) || ''

			return {
				city
			}
		}

		const filteredState = mappedItem.types.filter((filteredItem: any) => filteredItem === 'administrative_area_level_1')
		if (filteredState.length) {
			const state = (filteredState && mappedItem.long_name) || ''

			return {
				state
			}
		}

		return null
	})

	const fullAddress = address.filter((filteredItem: any) => !!filteredItem).reduce((acc: any, current: any) => {
		const keys = Object.keys(current)

		return {
			...acc,
			[keys[0]]: current[keys[0]]
		}
	}, {})

	return {
		main: fullAddress.main,
		number: '',
		complement: '',
		reference: '',
		neighborhood: fullAddress.neighborhood,
		cep: fullAddress.postalCode,
		city: fullAddress.city,
		state: fullAddress.state,
		type: 0,
		error: false
	}
}

interface AddressByCEP {
	main: string
	number: string
	complement: string
	reference: string,
	neighborhood: string
	cep: string
	city: string
	state: string
	type: number
	error: boolean
}

export const mapAddressByCEPData = (data: any): AddressByCEP => {
	return {
		main: data['logradouro'],
		number: '',
		complement: '',
		reference: '',
		neighborhood: data['bairro'],
		cep: data['cep'],
		city: data['localidade'],
		state: data['uf'],
		type: 0,
		error: data['erro']
	}
}

export const mapAddressByCEPDataBrasilApi = (data: any): AddressByCEP => {
	return {
		main: data['street'],
		number: '',
		complement: '',
		reference: '',
		neighborhood: data['neighborhood'],
		cep: data['cep'],
		city: data['city'],
		state: data['state'],
		type: 0,
		error: data['erro']
	}
}

interface GeolocationByAddress {
	geometry: any
}

export const mapGeolocationByAddressData = (data: any): GeolocationByAddress => {
	const {
		geometry
	} = data.results[0]

	return {
		geometry
	}
}
