import styled from 'styled-components'

export const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5rem;
`

export const Dialog = styled.div`
	padding: 1rem;
	display: grid;
	grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
	row-gap: 1.25rem;
	position: relative;
	overflow: hidden;
  max-height: 50rem;

	@media (min-width: 768px) {
		padding: 1.5rem;
	}
`

Dialog.Header = styled.div`
  text-align: start;
`
Dialog.Content = styled.div`
  overflow: auto;
	padding: 0 1.5rem;
  margin: 0 -1.5rem;
	display: flex;
	flex-direction: column;
	row-gap: 1.25rem;
`

Dialog.CloseButton = styled.button`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0;
	position: absolute;
	top: 0.75rem;
  right: 0.75rem;
	cursor: pointer;

	.custom-icon svg {
		height: 1.25rem;
    width: 1.25rem;
    opacity: .6;

		path {
			fill: #000000;
		}
	}
`

Dialog.Title = styled.span`
	text-align: center;
	font-size: 1.125rem;
	font-weight: 700;
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
`

export const PaymentMethodSection = styled.div``

PaymentMethodSection.Title = styled.span`
  color: #949494;
  margin: 0 0 0.5rem 0;
  display: block;
  font-weight: 600;
  font-size: .85rem;
	opacity: .8;
`

export const PaymentMethods = styled.ul`
	display: flex;
  flex-direction: column;
  row-gap: 1rem;
  box-sizing: border-box;
  list-style: none;
	padding: 0;
  margin: 0;
`

export const PaymentMethod = styled.li`
  border: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};  
	border-radius: 8px;
  padding: 0.5rem;
	display: flex;
  align-items: center;
  column-gap: 1rem;
	cursor: pointer;
	height: 2.5rem;
	position: relative;

	&:hover {
    background-color: #0000000d;
	}
`

PaymentMethod.BrandImage = styled.img`

`

PaymentMethod.Icon = styled.div`
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);
	opacity: .6;
`

PaymentMethod.Identification = styled.span`
  font-size: 1rem;
  color: #7D7D7D;
`
