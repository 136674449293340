import styled from 'styled-components'

export const Wrapper = styled.div`
	flex-direction: column;
  min-height: 100%;
  min-width: 100%;
  background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundBody) || '#CCC'};

  * {
    box-sizing: border-box;
  }

  @media (min-width: 60rem) {
    &.header-full-content--opened {
      margin: 0px;

      > main {
        margin: 0px;

				> div.open-cart{
					right: 21rem;
				}
      }

      > aside {
        background: none;
        top: 5.625rem;
				display: unset;
				${props => props.isSideOpen ? `
					left: calc(100% - 20rem);
					min-width: 20rem;
					max-width: 20rem;
				` : ``}

        > nav {
          min-height: 100%;
        }
      }
    }

    &.side-full-content--opened {
      margin: 0px;

      > main {
        margin: 0px;

				> div.open-cart{
					right: 21rem;
				}
      }

      > aside {
				display: unset;
				left: calc(100% - 20rem);
				min-width: 20rem;
				max-width: 20rem;
      }
    }

		> aside.placed-order {
			left: calc(100% - 20rem);
			min-width: 20rem;
			max-width: 20rem;
			top: 5.5rem;
			overflow: hidden;
    	overflow-y: auto;
		}

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    
    @keyframes fade-out {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }

  @media (min-width: 90rem) {
    &.side-full-content--opened,
    &.header-full-content--opened {
      margin: 0px;
    }
  }
`
