import styled from "styled-components";

export const Wrapper = styled.div`
	background: #fff;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	box-sizing: border-box;
	margin: 0 auto;
	border-radius: 0.938rem;
	padding: 0 1.5rem 1.5rem 1.5rem;
	min-width: 100%;
	max-width: 100%;

	> p {
		margin: 0;
		margin-bottom: 1.25rem;

		&:last-child {
			margin: 0;
		}
	}
`;

export const ContentWrapper = styled.div`
	min-width: 100%;
	max-width: 100%;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&.motion {
		opacity: 1;
	}

	> form {
		> div {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 1.25rem;
			flex-wrap: wrap;
		}
	}
`;

export const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	min-width: 100%;
`;

export const LoadingImage = styled.img`
	width: 6.25rem;
	height: auto;
`;

export const H1 = styled.h1`
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.textHighlightColor) ||
		"#000"};
	font-size: 1.25rem;
	padding: 0;
	margin: 0;
	margin-bottom: 1.25rem;
	flex: 1;
	text-align: center;
`;

export const Button = styled.button`
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.invertedPrimaryColor) ||
		"#fff"};
	background: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.primaryColor) ||
		"#000"};
	border-radius: 0.938rem;
	font-size: 0.875rem;
	font-weight: bold;
	flex: 1;
	max-height: 3.75rem;
	min-height: 3.75rem;
	line-height: 3.75rem;
	padding: 0;
	margin: 1.5rem 0 0 0;
	font-size: 1rem;
	box-sizing: border-box;
	min-width: 100%;
	max-width: 100%;
	cursor: pointer;

	&.no-highlight {
		background: none;
		border: 0 none;
		min-height: auto;
		max-height: auto;
		height: auto;
		color: ${(props) =>
			(props.theme &&
				props.theme.main &&
				props.theme.main.general &&
				props.theme.main.general.textHighlightColor) ||
			"#000"};

		&:disabled {
			opacity: 0.5;
		}
	}

	&:hover {
		opacity: 0.9;
	}

	&:disabled {
		opacity: 0.5;
		cursor: default;
	}
`;

export const Input = styled.input`
	border: 0 none;
	border-bottom: #e1e1e1 0.063rem solid;
	font-size: 1rem;
	text-align: center;
	line-height: 1.875rem;
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.textPrimaryColor) ||
		"#000"};
	margin: 0 auto;
	flex: 0;
	min-width: 50%;
	border: 1px solid
		${(props) =>
			(props.theme &&
				props.theme.main &&
				props.theme.main.general &&
				props.theme.main.general.textNinethColor) ||
			"#CCC"};
	width: 100%;
	border-radius: 15px;
	height: 3rem;
	padding: 1rem;
	box-sizing: border-box;
`;

export const PhoneNumberInput = styled(Input)`
	width: 100%;
	margin: 0;
	flex: 1 0 100%;

	@media (min-width: 30rem) {
		flex: 1 1 50%;
	}
`;

export const SelectWrapper = styled.div`
	border: 1px solid
		${(props) =>
			(props.theme &&
				props.theme.main &&
				props.theme.main.general &&
				props.theme.main.general.textNinethColor) ||
			"#CCC"};
	flex: 1 0 100%;
	width: 100%;
	border-radius: 15px;
	height: 3rem;
	padding: 1rem;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0 1rem 0;
	min-width: 100%;
`;

export const Select = styled.select`
	border: 0 none;
	background: none;
	flex: 1;
	max-width: 5.313rem;
	min-width: 5.313rem;
	padding: 0;
	height: 1.875rem;
	min-height: 1.875rem;
	max-height: 1.875rem;
	line-height: 1.875rem;
	font-size: 1rem;
	color: #000;

	> option {
		font-size: 1rem;
		color: #000;
	}
`;

export const Paragraph = styled.p`
	margin: 0 0 1.5rem 0;
	text-align: start;
`;

Paragraph.Bold = styled.span`
	font-weight: 700;
`;

export const Form = styled.form`
	flex: 1;
	min-width: 100%;
	max-width: 100%;
	display: flex;
	flex-direction: column;

	> p {
		text-align: start;
	}
`;

export const Div = styled.div``;

export const PhoneFieldsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const SelectedPhoneWrapper = styled.span`
	display: flex;
	align-items: center;
	font-weight: 700;
	text-transform: uppercase;
	justify-content: center;
	margin: 0 0 2rem 0;
`;
