import { useCallback, useEffect } from 'react'
import { useStore, useDispatch } from 'react-redux'

import {
	layout
} from '../../state'

import {
	layoutModeEnum
} from '../../domain/layout'

export function useLayout() {
	const store = useStore()
	const dispatch = useDispatch()

	const layoutMode  = store.getState().layout && store.getState().layout.mode

	function makeLayoutAdjusts() {
		const screenElement = window
		const asideElement = document.getElementsByTagName('aside')[0]
		const mainElement = document.getElementsByTagName('main')[0]

		const scrollbarWidth = 16
		const screenWidth = screenElement && screenElement.innerWidth
		const asideWidth = asideElement && asideElement.offsetWidth
		const mainWidth = mainElement && mainElement.offsetWidth
		const availableMainWidth = screenWidth - (asideWidth + scrollbarWidth)
		const mainPositionX = (availableMainWidth - mainWidth) / 2

		if (!mainWidth || !screenWidth) {
			return
		}

		if (mainPositionX > 0) {
			mainElement.style.marginLeft = `${mainPositionX}px`
		}
	}

	const resize = useCallback(() => {
		setTimeout(() => {
			makeLayoutAdjusts()
		}, 250)
	}, [])

	const handleMode = (mode) => {
    dispatch(layout.setMode(mode))
		resize()
  }

	useEffect(() => {
		makeLayoutAdjusts()

		window.addEventListener('resize', resize)

		return () => {
			window.removeEventListener('resize', resize)
		}
	}, [
		resize
	])

	return {
		layoutMode,
		layoutModeEnum,
		handleLayoutMode: handleMode
	}
}
