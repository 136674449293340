
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
	connect,
	useDispatch,
	useStore
} from 'react-redux'

import { List } from './List'

import {
	store,
	addressActions
} from '../../state'

import { useDeviceLayout } from '../hooks'

import {
	pipe,
	GetContent
} from '../../domain/helpers'

import {
	setCart,
	fetchStoreFees,
	setModality,
	setStoreId,
	handleGTM
} from '../../redux/actions/main'

import { CustomTitle } from '../components/CustomTitle'

import {
	Skeleton
} from '../'

import {
	ContentWrapper,
	LoadingWrapper,
	Wrapper,
} from './styles'
import { ListAlternative } from './ListAlternative'
import { ALTERNATIVE_STYLE_MULTI_STORE, MULTI_STORE, MULTI_STORE_SELECT_FIRST_STORE } from '../../config'
import { useSnackbar } from 'react-simple-snackbar'
import { errorOptions } from '../../infra/utils/styleNotification'

export const Context = React.createContext({})

function StoresListPage(props) {
	const store = useStore()
	const dispatch = useDispatch()
	const [storesLoading, setStoresLoading] = useState(false)
	const address = (store.getState().address && store.getState().address.address) || {}

	const setAddress = (args) => dispatch(addressActions.setAddress(args))

	const {
		modalityId,
		selectedStoreId,
		store: currentStore,
		stores,

		close,

		fetchStores,
		fetchStore,
		fetchStoreFees,
		setSelectedStoreId,
		setModality,
		setCart,
		setStore,
		setStoreId,
		setStoreModalities,
		handleGTM,
		handleViewAddress
	} = props

	const storesData = stores.list || []
	const isMobile = useDeviceLayout({
		isMobile: true
	})
	const [openErrorSnackbar] = useSnackbar(errorOptions({ modal: true }))

	useEffect(() => {
		if (MULTI_STORE === "true") {
			setStoresLoading(true)
			fetchStores([() => {
				openErrorSnackbar('Endereço fora da área de serviço! Nenhum estabelecimento encontrado :(', 7000)
				setStoresLoading(false)
				close()
				handleViewAddress()
			}, () => {
				setStoresLoading(false)
			}])
		}
	}, [
		fetchStores
	])

	useEffect(() => {
		if (MULTI_STORE === "true" && MULTI_STORE_SELECT_FIRST_STORE === "true") {
			if (storesData && storesData.length > 0 && selectedStoreId === 0) {
				const [firstStore] = storesData;

				handleStore({
					id: firstStore.id,
					coupon: firstStore.coupon,
					modality: firstStore.delivery ? 4 : 2,
					name: firstStore.name,
					modalities: {
						delivery: firstStore.delivery,
						pickup: firstStore.pickup,
					},
				});
			}
		}
	}, [storesData]);

	async function handleStore(args) {
		//GTM 3.0 - Evento para seleção do estabelecimento
		handleGTM({
			'event': 'escolha_estabelecimento',
			'affiliation': args.name
		})

		setStoreModalities(args.modalities)
		setModality({ id: args.modality })

		setSelectedStoreId(args.id)
		setStoreId(args.id)
		setAddress({
			...address,
			verified: true
		})

		if (isMobile) {
			postStore(args)
			postCart({
				products: null
			})

			handleFetchStore(args.modality)
			close()

			return
		}

		postStore(args)
		postCart({
			products: null
		})

		handleFetchStore(args.modality)

		close()
	}

	async function postStore(args) {
		await setStore(args)
	}

	async function postCart(args) {
		await setCart(args)
	}

	async function handleFetchStore(modality) {
		if (modality === 4) {
			await fetchStoreFees([{
				name: 'store-fees',
				type: 'success',
				callback: () => {
					setModality({ id: 4 })
					fetchStore()
				}
			}, {
				name: 'store-fees',
				type: 'error',
				callback: () => {
					setStoreModalities({ delivery: false, pickup: true })
					setModality({ id: 2 })
					fetchStore()
				}
			}])
		}
	}

	return <Wrapper>
		{storesLoading && <LoadingWrapper id='stores-list-loading'>
			<Skeleton module='stores-list' />
		</LoadingWrapper>}

		{!storesLoading && <>
			<ContentWrapper id='stores-list-content'>
				<CustomTitle title="Selecione onde você gostaria de fazer o seu pedido:"></CustomTitle>

				{ALTERNATIVE_STYLE_MULTI_STORE === "false" ?
					<List
						selectedStoreId={selectedStoreId}
						data={storesData}
						store={currentStore}
						handleStore={handleStore}
						modalityId={modalityId}
					/> :
					<ListAlternative
						selectedStoreId={selectedStoreId}
						data={storesData}
						store={currentStore}
						handleStore={handleStore}
						modalityId={modalityId}
					/>}
			</ContentWrapper></>}
	</Wrapper>
}

StoresListPage.propTypes = {
	loading: PropTypes.arrayOf(PropTypes.string),
	modalityId: PropTypes.number,
	store: PropTypes.shape({}),
	stores: PropTypes.shape({
		list: PropTypes.arrayOf(PropTypes.shape({}))
	}),

	close: PropTypes.func,
	fetchStore: PropTypes.func,
	fetchStores: PropTypes.func,
	fetchStoreFees: PropTypes.func,
	setStore: PropTypes.func,
	setCart: PropTypes.func,
	selectedStoreId: PropTypes.number,
	setSelectedStoreId: PropTypes.func,
	setModality: PropTypes.func,
	setStoreId: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		loading: state.loading.loading || [],
		modalityId: state.main.modality && state.main.modality.id,
		store: state.store.store || {},
		stores: state.store.stores || [],
		selectedStoreId: state.store.selectedStoreId || 0
	}
}

const GetConnection = connect(mapStateToProps, {
	fetchStore: store.fetch,
	fetchStores: store.fetchStores,
	setSelectedStoreId: store.setSelectedStoreId,
	fetchStoreFees,
	setCart,
	setModality,
	setStore: store.setStore,
	setStoreModalities: store.setStoreModalities,
	setStoreId,
	handleGTM
})

export const StoresList = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'stores-list' })
)(StoresListPage))
