import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import { APP_VERSION, GOOGLE_ANALYTICS, LOGROCKET, LOGROCKET_PROJECT, SENTRY, SENTRY_DSN, SENTRY_PROJECT, SENTRY_RATE } from './config'
import * as container from './container'
import * as serviceWorker from './infra/service-worker'
import createStore from './state/createStore'
import App from './view/app'

const CURRENT_VERSION = APP_VERSION

function cleanLocalStorage() {
	const localStorageItem = window.localStorage.getItem('vqp') 
	const localStorageItemVQP = (localStorageItem && JSON.parse(localStorageItem)) || {version: null}

	if (localStorageItemVQP && localStorageItemVQP.version !== CURRENT_VERSION) {
		window.localStorage.clear();

		const initialLocalStorageVersion = { version: CURRENT_VERSION }

		window.localStorage.setItem('vqp', JSON.stringify(initialLocalStorageVersion))
	}
}

cleanLocalStorage()

const store = createStore({
  container
})

if (SENTRY === 'true') {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: `${ SENTRY_PROJECT }@` + process.env.npm_package_version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: SENTRY_RATE
  })
}

if (LOGROCKET === 'true') {
  LogRocket.init(`${ LOGROCKET_PROJECT }`)
  setupLogRocketReact(LogRocket)

  if (SENTRY === 'true') {
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra('sessionURL', sessionURL)
      })
    })
  }

  if (GOOGLE_ANALYTICS) {
    LogRocket.getSessionURL(function (sessionURL) {
      window.ga && window.ga('send', {
        hitType: 'event',
        eventCategory: 'LogRocket',
        eventAction: sessionURL
      })
    })
  }
}

ReactDOM.render(<App store={store} />, document.getElementById('root'))

serviceWorker.unregister()
