import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles'

export function PageContainerSide({
	children,
	styles,
	className,
	opened,
	orderId
}) {
	const asideElement = document.querySelectorAll('aside')[0]

	const [diff, setDiff] = useState(0)

	const handleResize = useCallback(() => {
		if (asideElement) {
			setDiff(asideElement.classList.contains('placed-order') ? 5.5 * 16 : 0)
		} else {
			setDiff(0)
		}

		if (asideElement) {
			const asideHeight = window.innerHeight - diff
			asideElement.style.height = asideHeight + 'px'
		}
	}, [
		asideElement,
		diff
	])

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		handleResize()
	}, [handleResize])

	return (
		<Wrapper className={className} style={styles} opened={diff ? (orderId ? opened : false) : opened && !orderId}>
			{ children }
		</Wrapper>
	)
}

PageContainerSide.propTypes = {
	children: PropTypes.node,
	styles: PropTypes.shape({}),
	className: PropTypes.string,
	opened: PropTypes.bool,
	orderId: PropTypes.number
}
