export function GetQueryString(a) {
  a = a || window.location.search.substr(1).split('&').concat(window.location.hash.substr(1).split("&"))

  if (typeof a === "string") {
    a = a.split("#").join("&").split("&")
  }

  if (!a) return {}

  var b = {}
  for (var i = 0; i < a.length; ++i) {
    var p = a[i].split('=')

    if (p.length !== 2) continue

    b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "))
  }

  return b
}
