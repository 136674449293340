export const calculateCartTotal = (items) => {
	const total =
		items &&
		items.length &&
		items.reduce((acc, item) => acc + calculateItemTotal(item), 0);

	return total || 0;
};

export const calculateItemTotal = (item) => {
	const { selectedAdditionals } = item;

	const valorFinalProduct = parseFloat(item.promotionalValue) || item.value;

	const selectedAdditionalsGroups =
		selectedAdditionals &&
		selectedAdditionals.length &&
		selectedAdditionals.map((mappedItem) => {
			const selectedAdditionalsMaximum =
				mappedItem.items &&
				mappedItem.items.length &&
				mappedItem.items.filter((filteredItem) => filteredItem.calcType === 3);
			const selectedAdditionalsAverage =
				mappedItem.items &&
				mappedItem.items.length &&
				mappedItem.items.filter((filteredItem) => filteredItem.calcType === 2);
			const selectedAdditionalsFixes =
				mappedItem.items &&
				mappedItem.items.length &&
				mappedItem.items.filter((filteredItem) => filteredItem.calcType === 1);

			const additionalsMaximumTotal =
				(selectedAdditionalsMaximum &&
					selectedAdditionalsMaximum.length &&
					Math.max.apply(
						Math,
						selectedAdditionalsMaximum.map(
							(selectedAdditionalsMaximumItem) =>
								selectedAdditionalsMaximumItem.value
						)
					)) ||
				0;
			const additionalsAverageTotal =
				selectedAdditionalsAverage && selectedAdditionalsAverage.length
					? selectedAdditionalsAverage.reduce(
							(acc, current) => Number(acc + current.value),
							[]
					  ) / selectedAdditionalsAverage.length
					: 0;
			const additionalsFixesTotal =
				selectedAdditionalsFixes && selectedAdditionalsFixes.length
					? selectedAdditionalsFixes.reduce((acc, current) => {
							if (current.quantity > 1) {
								return Number(acc + current.value * current.quantity);
							}

							return Number(acc + current.value);
					  }, [])
					: 0;

			return (
				additionalsMaximumTotal +
				additionalsAverageTotal +
				additionalsFixesTotal
			);
		});
	const selectedAdditionalsGroupsTotal =
		(selectedAdditionalsGroups &&
			selectedAdditionalsGroups.length &&
			selectedAdditionalsGroups.reduce(
				(acc, current) => Number(acc + current),
				[]
			)) ||
		0;

	const total =
		item.quantity * (valorFinalProduct + selectedAdditionalsGroupsTotal);

	return total;
};

export const caculateProductsQuantity = (products) =>
	products
		? products.reduce((quantity, product) => quantity + product.quantity, 0)
		: 0;
