import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  box-sizing: border-box;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  position: relative;
`

export const Paragraph = styled.p`
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 1.875rem;
  flex-direction: column;
  flex: 1;
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`

export const Span = styled.span``

export const Image = styled.img`
  max-width: .938rem;
  max-height: 1.25rem;
  flex: 1;
  margin-bottom: .625rem;
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`

export const LoadingWrapper = styled.div`
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const ContentWrapper = styled.div`
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  position: relative;

`
