import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;

  * {
    font-family: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.fontFamily) || 'Roboto, sans-serif' };
  }
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  font-size: 1.375rem;
  margin: 0;
  padding: 0;
  line-height: 1.375rem;
  margin-bottom: 1.875rem;
  padding-bottom: 1.875rem;
  border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'} .063rem solid;
  min-width: 100%;
  text-align: center;
`

export const ContentWrapper = styled.div`
  opacity: 0;
  transition: all .25s ease-in;
  min-width: 100%;
  padding: 1.875rem .938rem;
  box-sizing: border-box;

  &.motion {
    opacity: 1;

    > p {
      &:nth-child(6) {
        margin-bottom: 1.25rem;
      }
    }
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`

export const Input = styled.input`
  border: 0 none;
  border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'} .063rem solid;
  font-size: 1.125rem;
  text-align: center;
  height: 1.875rem;
  line-height: 1.875rem;
  padding: 0;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
  margin: 0 auto;
  flex: 1;
  margin-bottom: 1.25rem;
  min-width: 100%;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 1.1+ */
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
  }

  &.required {
    border-bottom: #f00 .063rem solid;
    margin-bottom: .313rem;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 1.1+ */
      color: #f00;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #f00;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #f00;
    }
  }
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  min-width: 100%;
  text-align: center;
  margin-bottom: 1.25rem;

  &.warning {
    color: #f00;
  }
`

export const H2 = styled.h2`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
  line-height: 1.5rem;
  min-width: 100%;
  text-align: center;
  margin-bottom: 1.25rem;
`

export const Button = styled.button`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff'};
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  border-radius: .938rem;
  font-size: .875rem;
  font-weight: bold;
  flex: 1;
  max-height: 3.125rem;
  min-height: 3.125rem;
  line-height: 3.125rem;
  min-width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 1.25rem;
  box-sizing: border-box;

  &:last-child {
    margin-bottom: 0;
  }
`
