import React from 'react'

import { getCardBrandImageByPaymentTypeAndId } from '../../../infra/utils'

import {
  Wrapper,
  ListWrapper,
  ListItemWrapper,
  Image,
  Paragraph,
  Span,
  Button,
  H1
} from './styles'

function ListItem(props) {
  const {
    number,
    brand,
    brandName,
    paymentType,
    brandId,
    handleCard
  } = props

  const newProps = Object.assign({}, props)

  delete newProps.id
  delete newProps.number
  delete newProps.brand
  delete newProps.brandName
  delete newProps.brandId
  delete newProps.cvv

  return <ListItemWrapper { ...newProps }>
    <Button onClick={ () => {
      handleCard()
    }}>
      { brand ? <Image src={ getCardBrandImageByPaymentTypeAndId(paymentType, brandId) } alt={ brandName } /> : null }

      { number ? <Span>{ number }</Span> : null }
    </Button>
  </ListItemWrapper>
}

export function List(props) {
  const {
    data,
    selectedCard,
    handleCard
  } = props

	return <Wrapper id='payment-list'>
		{ data && data.length ? <>
      <H1>Cartões cadastrados</H1>

      <ListWrapper>
        { data.map((mappedItem, index) => {
          const key = `payment-list-item-${index}`
          
          const stringfiedCard = JSON.stringify(mappedItem)

          return <ListItem key={ key } className={ `${ JSON.stringify(selectedCard) === stringfiedCard ? 'selected' : '' }` } { ...mappedItem } handleCard={ () => {
            return handleCard(mappedItem)
          } } />
        }) } </ListWrapper>
      </>: <>
        <Paragraph>Nenhum cartão cadastrado.</Paragraph>
      </> }
	</Wrapper>
}
