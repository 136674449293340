interface OrdersListOrders {
	storeLogo: string
	storeName: string
	modalityId: number
	id: number
	type: string
	status: number
	time: string
}

interface OrderData {

}

interface OrderHistoryListOrder {
	id: number
	cpf: string
	modalityId: number
	serviceFee: number
	discount: number
	deliveryPlace: string
	date: string
	shortDate: string
	type: string
	status: number
	time: string
}

interface OrderHistoryListOrderStore {
	id: number
	name: string
	imageUrl: string
	logoUrl: string
	address: string
	menuType: number
	phone: string
	whatsapp: string
}

interface OrderHistoryListOrderItem {
	productId: number
	product: string
	quantity: number
	description: string
	value: number
	orderId: number
	additionalText: string
	notes: string
	additionals: []
	fractionalQuantity: boolean
}

interface OrdersList {
	retry: boolean
	orders: OrdersListOrders[]
	success: boolean
}

interface OrderHistoryList {
	message: string
	orders: OrderHistoryListOrder[]
	success: boolean
}

export const mapOrdersList = (data: any): OrdersList => {
	const orders = (data['StatusPedidos'] && data['StatusPedidos'].length && data['StatusPedidos'].map((mappedItem: any) => {
		return {
			storeLogo: mappedItem['Estabelecimento'] && mappedItem['Estabelecimento']['Logo'],
			storeName: mappedItem['Estabelecimento'] && mappedItem['Estabelecimento']['Nome'],
			id: mappedItem['Pedido'] && mappedItem['Pedido']['ComandaId'],
			modalityId: (
				mappedItem['Pedido'] && mappedItem['Pedido']['Tipo'] ?
					(mappedItem['Pedido'] && mappedItem['Pedido']['Tipo'] && mappedItem['Pedido']['Tipo'] === 'Delivery' ? 4 : 2) : null
			),
			schedule: mappedItem['Pedido'] && mappedItem['Pedido']['DataAgendamentoSeara'],
			status: mappedItem['Pedido'] && mappedItem['Pedido']['Status'] && mappedItem['Pedido']['Status']['Descricao'],
			currentStep: mappedItem['Pedido'] && mappedItem['Pedido']['Status'] && mappedItem['Pedido']['Status']['Atual'],
			stepsTotal: mappedItem['Pedido'] && mappedItem['Pedido']['Status'] && mappedItem['Pedido']['Status']['Quantidade'],
			time: mappedItem['Pedido'] && mappedItem['Pedido']['Status'] && mappedItem['Pedido']['Status']['Horario']
		}
	})) || []

	return {
		retry: data['RefazerChamada'],
		success: data['Sucesso'],
		orders
	}
}

export const mapOrderData = (data: any): OrderData => {
	const statuses = data['NotificacaoPedido'] && data['NotificacaoPedido']['ListaStatusDelivery'].map((mappedItem: any) => {
		return {
			time: mappedItem['Horario'],
			status: mappedItem['Status'],
			active: mappedItem['Ativo']
		}
	})

	const notification = data['NotificacaoPedido'] && {
		status: data['NotificacaoPedido']['StatusDelivery'],
		statuses,
		deliveryTime: data['NotificacaoPedido']['PrevisaoEntregaTexto']
	}

	const store = data['Estabelecimento'] && {
		id: data['Estabelecimento']['EstabelecimentoId'],
		name: data['Estabelecimento']['Nome'],
		address: data['Estabelecimento']['Endereco'],
		logoUrl: data['Estabelecimento']['UrlLogo'],
		whatsapp: data['Estabelecimento']['NumeroWhatsapp'],
		phone: data['Estabelecimento']['Telefone']
	}

	const scheduling = data['AgendamentoSeara'] && {
		date: data['AgendamentoSeara']['Data'],
		schedule: data['AgendamentoSeara']['Hora']
	}

	const items: OrderHistoryListOrderItem[] = data['PedidoItems'] && data['PedidoItems'].length && data['PedidoItems'].map((mappedItem: any) => {
		return {
			productId: mappedItem['ProdutoId'],
			product: mappedItem['Produto'],
			quantity: mappedItem['Quantidade'],
			description: mappedItem['Descricao'],
			value: mappedItem['Valor'],
			orderId: mappedItem['PedidoId'],
			additionalText: mappedItem['TextoAcompanhamento'],
			notes: mappedItem['Observacao'],
			additionals: mappedItem['Acompanhamentos'],
			fractionalQuantity: mappedItem['QuantidadeFracionada']
		}
	})

	return {
		id: data['ComandaBalcaoId'],
		list: items,
		store,
		cpf: data['Cpf'],
		hasCpf: data['Cpf'] ? true : false,
		inProgress: data['EmAndamento'],
		brandCard: data['BandeiraCartao'],
		numberCard: data['NumeroCartao'],
		requestNumber: data['NumeroChamada'],
		scheduling,
		status: data['NumeroChamada'] > -1 ? 2 : 1,
		paymentStatus: data['StatusPagamento'],
		orderDate: data['DataComprovante'],
		orderId: data['ComandaBalcaoId'],
		deliveryAddress: data['LocalEntrega'],
		notification,
		modalityId: data['ModalidadeRetiradaId'],
		userName: data['Nome'],
		earnedPoints: data['PontosGanhos'],
		summary: {
			total: data['ValorPago'],
			deliveryFee: data['ModalidadeRetiradaValor'],
			discount: data['ValorDesconto'],
			subtotal: data['ValorPago'] - data['ModalidadeRetiradaValor']
		}
	}
}

export const mapOrderHistoryList = (data: any): OrderHistoryList => {
	const orders = (data['MeusPedidos'] && data['MeusPedidos'].length && data['MeusPedidos'].map((mappedItem: any) => {
		const store: OrderHistoryListOrderStore = mappedItem['Estabelecimento'] && {
			id: mappedItem['Estabelecimento']['EstabelecimentoId'],
			name: mappedItem['Estabelecimento']['Nome'],
			imageUrl: mappedItem['Estabelecimento']['UrlImagem'],
			logoUrl: mappedItem['Estabelecimento']['UrlLogo'],
			address: mappedItem['Estabelecimento']['Endereco'],
			menuType: mappedItem['Estabelecimento']['TipoCardapio'],
			phone: mappedItem['Estabelecimento']['Telefone'],
			whatsapp: mappedItem['Estabelecimento']['NumeroWhatsapp']
		}

		const items: OrderHistoryListOrderItem[] = mappedItem['PedidoItems'] && mappedItem['PedidoItems'].length && mappedItem['PedidoItems'].map((mappedItem: any) => {
			return {
				productId: mappedItem['ProdutoId'],
				product: mappedItem['Produto'],
				quantity: mappedItem['Quantidade'],
				description: mappedItem['Descricao'],
				value: mappedItem['Valor'],
				orderId: mappedItem['PedidoId'],
				additionalText: mappedItem['TextoAcompanhamento'],
				notes: mappedItem['Observacao'],
				additionals: mappedItem['Acompanhamentos'],
				fractionalQuantity: mappedItem['QuantidadeFracionada']
			}
		})

		return {
			id: mappedItem['ComandaBalcaoId'],
			cpf: mappedItem['Cpf'],
			hasCpf: mappedItem['Cpf'] ? true : false,
			modalityId: mappedItem['ModalidadeRetiradaId'],
			serviceFee: mappedItem['ModalidadeRetiradaValor'],
			modalityDiscount: mappedItem['DescontoValorModalidadeRetirada'],
			deliveryPlace: mappedItem['LocalEntrega'],
			date: mappedItem['Data'],
			shortDate: mappedItem['DataComprovante'],
			cardBrand: mappedItem['BandeiraCartao'],
			cardNumber: mappedItem['NumeroCartao'],
			paymentStatus: mappedItem['StatusPagamento'],
			cardImage: mappedItem['ImagemCartao'],
			cardImageVisa: mappedItem['ImagemCartaoVisa'],
			cardBrandId: mappedItem['ImagemBandeiraId'],
			rating: mappedItem['Avaliacao'],
			inProgress: mappedItem['EmAndamento'],
			requestNumber: mappedItem['NumeroChamada'],
			amountPaid: mappedItem['ValorPago'],
			discount: mappedItem['ValorDesconto'],
			reversal: mappedItem['Estorno'],
			payOnDelivery: mappedItem['PagarNaEntrega'],
			store,
			items
		}
	})) || []

	return {
		message: data['Mensagem'],
		success: data['Sucesso'],
		orders
	}
}

interface OrderAnalysisRequest {
	EstabelecimentoId: number
	Produtos: any[]
	ModalidadeRetirada: number
	SubTotal: number
	PagamentoAdHoc?: boolean
	ComandaId?: number
	CartaoId?: number
	TipoPagamento?: number
	Cpf?: boolean
	Cvv?: number | string
	ModalidadeRetiradaValor?: number
	EnderecoDeliveryId?: number
	BandeiraId?: number
	PagarNaEntrega?: boolean
	Troco?: number
	CupomId?: number
	Ip?: string
	FidelidadeModeloPontosId?: number
	LocalEntregaBalcao?: string
	Agendamento: any;
}

interface OrderAnalysisDataKonduto {
	id: string | number
	requestStatus: number
	orderStatus: number
	recommendation: 0
	score: any
}

interface OrderAnalysisData {
	orderId: number
	success: boolean
	konduto: OrderAnalysisDataKonduto
}

interface OrderCreateRequestKonduto {
	StatusChamada: number
	StatusPedido: number
	Recomendacao: string | number
	Score: null
	IdPedidoKonduto: string | number
}

interface OrderCreateRequest {
	EstabelecimentoId: number
	Produtos: any[]
	ModalidadeRetirada: number
	Konduto: OrderCreateRequestKonduto
	SubTotal: number
	PagamentoAdHoc?: boolean
	ComandaId?: number
	CartaoId?: number
	TipoPagamento?: number
	Cpf?: boolean
	Cvv?: number | string
	ModalidadeRetiradaValor?: number
	EnderecoDeliveryId?: number
	BandeiraId?: number
	PagarNaEntrega?: boolean
	Troco?: number
	CupomId?: number
	Ip?: string
	FidelidadeModeloPontosId?: number
	LocalEntregaBalcao?: string
	Agendamento: any;
}

interface OrderCreateData {
	orderId: number
	success: boolean
	paymentStatus: boolean
	message: string
	cnpj: string
}

export const mapOrderCreateRequest = (data: any): OrderCreateRequest => {
	const products = data.products && data.products.length && data.products.map((productsMappedItem: any) => {
		const {
			quantity,
			selectedAdditionals = [],
			notes = '',
			id,
			loyaltyModelPointsId,
			loyaltyPoints
		} = productsMappedItem
		const value = productsMappedItem.promotionalValue || productsMappedItem.value

		const additionals: any = (selectedAdditionals || []).reduce((allAdditionals: any, currentAdditionals: any) => {
			const items = (currentAdditionals.items || []).reduce((allItems: any, currentItem: any) => {
				const item = {
					Codigo: currentItem.code,
					GrupoId: currentItem.groupId,
					TipoCalculoCustomizacao: currentItem.calcType,
					Valor: currentItem.value
				}

				const currentItems = currentItem.quantity > 1 ? Array.from({ length: currentItem.quantity }).fill(item) : [item]

				return [...allItems, ...currentItems]
			}, [])

			return [...allAdditionals, ...items]
		}, [])

		const selectedAdditionalsGroups = selectedAdditionals && selectedAdditionals.length && selectedAdditionals.map((mappedItem: any) => {
			const selectedAdditionalsMaximum = mappedItem.items && mappedItem.items.length && mappedItem.items.filter((filteredItem: any) => filteredItem.calcType === 3)
			const selectedAdditionalsAverage = mappedItem.items && mappedItem.items.length && mappedItem.items.filter((filteredItem: any) => filteredItem.calcType === 2)
			const selectedAdditionalsFixes = mappedItem.items && mappedItem.items.length && mappedItem.items.filter((filteredItem: any) => filteredItem.calcType === 1)

			const additionalsMaximumTotal = (selectedAdditionalsMaximum && selectedAdditionalsMaximum.length && Math.max.apply(Math, selectedAdditionalsMaximum.map((selectedAdditionalsMaximumItem: any) => selectedAdditionalsMaximumItem.value))) || 0
			const additionalsAverageTotal = selectedAdditionalsAverage && selectedAdditionalsAverage.length ? (selectedAdditionalsAverage.reduce((acc: any, current: any) => Number(acc + current.value), [])) / selectedAdditionalsAverage.length : 0
			const additionalsFixesTotal = selectedAdditionalsFixes && selectedAdditionalsFixes.length ? selectedAdditionalsFixes.reduce((acc: any, current: any) => {
				if (current.quantity > 1) {
					return Number(acc + (current.value * current.quantity))
				}

				return Number(acc + current.value)
			}, []) : 0

			return additionalsMaximumTotal + additionalsAverageTotal + additionalsFixesTotal
		})

		const selectedAdditionalsGroupsTotal = (selectedAdditionalsGroups && selectedAdditionalsGroups.length && selectedAdditionalsGroups.reduce((acc: any, current: any) => Number(acc + current), [])) || 0
		const total = quantity * (value + (selectedAdditionalsGroupsTotal && selectedAdditionalsGroupsTotal))

		return {
			Codigo: id,
			Acompanhamentos: additionals,
			Quantidade: quantity,
			Observacao: notes,
			Valor: total,
			FidelidadeModeloPontosId: loyaltyModelPointsId,
			Pontos: loyaltyPoints,
			FidelidadeProdutoPremioId: 0
		}
	})


	const dataDelivery = data?.selectedDeliveryDate?.date && data?.selectedDeliveryDate?.hourId ? {
		Data: data.selectedDeliveryDate.date,
		HorarioId: data.selectedDeliveryDate.hourId,
	} : null;


	return {
		CartaoId: data.cardId,
		TipoPagamento: data.paymentType,
		Cpf: data.cpf,
		Cvv: data.cardCvv,
		ModalidadeRetiradaValor: data.deliveryFee || data.pickupFee,
		EnderecoDeliveryId: data.deliveryAddressId,
		BandeiraId: data.cardBrandId,
		PagarNaEntrega: data.deliveryPayment,
		Troco: data.change,
		CupomId: data.couponId,
		Ip: data.ip,
		FidelidadeModeloPontosId: data.fidelityScoreModelId,
		LocalEntregaBalcao: data.counterDeliveryPlace,
		EstabelecimentoId: data.storeId,
		Produtos: products,
		ModalidadeRetirada: data.modality && data.modality.id,
		Konduto: data.konduto && {
			StatusChamada: data.konduto.requestStatus,
			StatusPedido: data.konduto.orderStatus,
			Recomendacao: data.konduto.recommendation,
			Score: data.konduto.score,
			IdPedidoKonduto: data.konduto.id
		},
		SubTotal: data.total,
		ComandaId: data.orderId,
		Agendamento: dataDelivery,
	}
}

export const mapOrderAnalysisRequest = (data: any): OrderAnalysisRequest => {
	const products = data.products && data.products.length && data.products.map((productsMappedItem: any) => {
		const {
			quantity,
			selectedAdditionals = [],
			notes = '',
			id,
		} = productsMappedItem
		const value = productsMappedItem.promotionalValue || productsMappedItem.value

		const additionals: any = (selectedAdditionals || []).reduce((allAdditionals: any, currentAdditionals: any) => {
			const items = (currentAdditionals.items || []).reduce((allItems: any, currentItem: any) => {
				const item = {
					Codigo: currentItem.code,
					GrupoId: currentItem.groupId,
					TipoCalculoCustomizacao: currentItem.calcType,
					Valor: currentItem.value
				}

				const currentItems = currentItem.quantity > 1 ? Array.from({ length: currentItem.quantity }).fill(item) : [item]

				return [...allItems, ...currentItems]
			}, [])

			return [...allAdditionals, ...items]
		}, [])

		const selectedAdditionalsGroups = selectedAdditionals && selectedAdditionals.length && selectedAdditionals.map((mappedItem: any) => {
			const selectedAdditionalsMaximum = mappedItem.items && mappedItem.items.length && mappedItem.items.filter((filteredItem: any) => filteredItem.calcType === 3)
			const selectedAdditionalsAverage = mappedItem.items && mappedItem.items.length && mappedItem.items.filter((filteredItem: any) => filteredItem.calcType === 2)
			const selectedAdditionalsFixes = mappedItem.items && mappedItem.items.length && mappedItem.items.filter((filteredItem: any) => filteredItem.calcType === 1)

			const additionalsMaximumTotal = (selectedAdditionalsMaximum && selectedAdditionalsMaximum.length && Math.max.apply(Math, selectedAdditionalsMaximum.map((selectedAdditionalsMaximumItem: any) => selectedAdditionalsMaximumItem.value))) || 0
			const additionalsAverageTotal = selectedAdditionalsAverage && selectedAdditionalsAverage.length ? (selectedAdditionalsAverage.reduce((acc: any, current: any) => Number(acc + current.value), [])) / selectedAdditionalsAverage.length : 0
			const additionalsFixesTotal = selectedAdditionalsFixes && selectedAdditionalsFixes.length ? selectedAdditionalsFixes.reduce((acc: any, current: any) => {
				if (current.quantity > 1) {
					return Number(acc + (current.value * current.quantity))
				}

				return Number(acc + current.value)
			}, []) : 0

			return additionalsMaximumTotal + additionalsAverageTotal + additionalsFixesTotal
		})

		const selectedAdditionalsGroupsTotal = (selectedAdditionalsGroups && selectedAdditionalsGroups.length && selectedAdditionalsGroups.reduce((acc: any, current: any) => Number(acc + current), [])) || 0
		const total = quantity * (value + (selectedAdditionalsGroupsTotal && selectedAdditionalsGroupsTotal))

		return {
			Codigo: id,
			Acompanhamentos: additionals,
			Quantidade: quantity,
			Observacao: notes,
			Valor: total,
			FidelidadeModeloPontosId: 0,
			FidelidadeProdutoPremioId: 0
		}
	})

	const dataDelivery = data?.selectedDeliveryDate?.date && data?.selectedDeliveryDate?.hourId ? {
		Data: data.selectedDeliveryDate.date,
		HorarioId: data.selectedDeliveryDate.hourId,
	} : null;

	return {
		CartaoId: data.cardId,
		TipoPagamento: data.paymentType,
		Cpf: data.cpf,
		Cvv: data.cardCvv,
		ModalidadeRetiradaValor: data.deliveryFee || data.pickupFee,
		EnderecoDeliveryId: data.deliveryAddressId,
		BandeiraId: data.cardBrandId,
		PagarNaEntrega: data.deliveryPayment,
		Troco: data.change,
		CupomId: data.couponId,
		Ip: data.ip,
		FidelidadeModeloPontosId: data.fidelityScoreModelId,
		LocalEntregaBalcao: data.counterDeliveryPlace,
		EstabelecimentoId: data.storeId,
		Produtos: products,
		ModalidadeRetirada: data.modality && data.modality.id,
		SubTotal: data.total,
		ComandaId: data.orderId,
		Agendamento: dataDelivery,
	}
}

export const mapOrderAnalysisData = (data: any): OrderAnalysisData => {
	return {
		orderId: data['ComandaId'],
		success: data['Sucesso'],
		konduto: {
			id: data['IdPedidoKonduto'],
			requestStatus: data['StatusChamada'],
			orderStatus: data['StatusPedido'],
			recommendation: data['Recomendacao'],
			score: data['Score']
		}
	}
}

export const mapOrderCreateData = (data: any): OrderCreateData => {
	return {
		orderId: data['ComandaBalcaoId'],
		cnpj: data['Cnpj'],
		success: data['Sucesso'],
		message: data['Mensagem'],
		paymentStatus: data['StatusPagamento']
	}
}

function flatten(arr: any) {
	return arr && arr.reduce(function (flat: any, toFlatten: any) {
		return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten)
	}, [])
}
