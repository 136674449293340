import React, {
  useState,
  useEffect,
  useCallback
} from 'react'
import { connect } from 'react-redux'

import history from '../../history'

import {
  pipe,
  GetContent
} from '../../domain/helpers'
import {
  //PWAAnalytics,
  PWAClose
} from '../../domain/helpers/ipiranga/PWA'

import {
  useCustomLayout,
  useDeviceLayout,
  useInterval
} from '../hooks'

import {
  CustomLoading,
  CustomIcon,
  DefaultImage as Image,
} from '../components'

import {
  fetchReceipt,
  setReceipt,
  fetchRequestNumber,
  fetchReceiptStatus,
  setCart,
  setReceiptNotification,
  handleGTM,
  fetchStatusAntiFraud,
  setAntiFraudConfirmation
} from '../../redux/actions/main'

import {
  ReceiptPageContainer,
  ReceiptMain,
  LoadingContainer,
  MobileHeader,
  BackButton,
  H1,
  WhattsAppImg
} from './styles'

import {
  Pickup,
  Delivery,
  AntiFraud
} from './'
import { MainHeader } from '../main-header'
import WhattsAppImage from '../assets/whatsapp_icone.png'
import { WHATSAPP, WHATSAPP_NUMBER } from '../../config'
import { Dialog, Slide } from '@material-ui/core'

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const Context = React.createContext({})

export function ReceiptPage(props) {
  const {
    loading,
    device,
    orderId,
    modalityId,
    cpf,
    cardBrand,
    cardNumber,
    cashChange,
    userName,
    storeName,
    setCart,
    fetchReceipt,
    receipt,
    storeAddress,
    deliveryAddress,
    deliveryTime,
    products,
    requestNumber,
    fetchRequestNumber,
    fetchReceiptStatus,
    notification,
    setReceiptNotification,
    setReceipt,
    completed,
    date,
    phone,
    whattsapp,
    logoStore,
    theme,
    scheduling,
    location,
    URLParameters,
    fetchStatusAntiFraud,
    antifraudCheck,
    antifraud,
    setAntiFraudConfirmation,
		followLink,
    earnedPoints
  } = props

  const storeLogo = theme.logoImageStore
  const hasCpf = cpf ? true : false
  const [dialog, setDialog] = useState(null)
  const [interval, setInterval] = useState(0)
  const [stateRequestNumber, setStateRequestNumber] = useState(-1)
  const [status, setStatus] = useState(1)
  const [isRequestNumberReady, setRequestNumberReady] = useState(true)
  const [isStatusReady, setStatusReady] = useState(true)
  const [delay, setDelay] = useState(0)
  const [visibleAntifraud, setVisibleAntifraud] = useState(true)
  const [fullScreen, setFullScreen] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const getRequestNumber = useCallback(async () => {
    await fetchRequestNumber({
      checkoutId: orderId
    })

    setRequestNumberReady(true)

    return () => { }
  }, [
    orderId,
    fetchRequestNumber
  ])

  const getOrderStatus = useCallback(async (modalityId = 0) => {
    if (status === 2) {
      return () => { }
    }

    await fetchReceiptStatus({
      checkoutId: orderId,
      modality: modalityId
    })

    setStatusReady(true)

    if (modalityId === 2 && completed) {
      setStatus(2)

      return () => { }
    }

    if (modalityId === 4 && notification) {
      setReceiptNotification(notification)
    }

    if (modalityId === 4
      && notification
      && notification.status >= 5
      && notification.statuses[3].active === true) {
      setStatus(2)

      return () => { }
    }

    return () => { }
  }, [
    fetchReceiptStatus,
    orderId,
    setReceiptNotification,
    status,
    notification,
    completed
  ])

  useCustomLayout({
    device,
    backgroundColor: '#fff'
  })

  useEffect(() => {
    setReceipt(null)

    return () => { }
  }, [
    setReceipt
  ])

  useEffect(() => {
    if (modalityId === 2 && stateRequestNumber === -1 && !!isRequestNumberReady) {
      setRequestNumberReady(false)
      sleep(10000).then(() => {
        getRequestNumber()
      })
    }

    return () => { }
  }, [
    modalityId,
    stateRequestNumber,
    getRequestNumber,
    isRequestNumberReady
  ])

  useEffect(() => {
    if (!receipt || (receipt && !receipt.isPaid)) {
      sleep(delay).then(() => {
        fetchReceipt()
        setDelay(5000)
      })

      return () => { }
    }

    if (receipt && !!receipt.isPaid && !!isStatusReady) {
      setStatusReady(false)
      sleep(10000).then(() => {
        setStateRequestNumber(receipt.requestNumber)
        getOrderStatus(receipt.modalityId)
      })

      return () => { }
    }

    return () => { }
  }, [
    fetchReceipt,
    getRequestNumber,
    getOrderStatus,
    receipt,
    isStatusReady,
    delay
  ])

  useEffect(() => {
    setFullScreen(false)
    if (modalityId === 4 && !antifraudCheck) {
      fetchStatusAntiFraud({ comandaId: orderId })

      return () => { }
    }
  }, [
    modalityId,
    orderId,
    fetchStatusAntiFraud,
    antifraudCheck,
  ])

  useEffect(() => {
    if (antifraud && !antifraud.available) {
      setVisibleAntifraud(false)
    } else {
      setVisibleAntifraud(true)
    }
  }, [
    antifraud
  ])

  useInterval(() => {
    if (interval > 15 && !dialog) {
      setDialog({
        title: 'Pagamento realizado com sucesso! :)',
        message: 'Mas por algum motivo não conseguimos gerar o seu comprovante, por favor consulte mais tarde dentro do historico de pedidos.',
        actions: [{
          label: 'Voltar ao início',
          handleClick: () => {
            PWAClose && PWAClose()
          }
        }]
      })

      return () => { }
    }

    setInterval(interval + 1)

    return () => { }
  }, 1000)

  function handleStatus(status) {
    setStatus(status)
  }

  function handleFinishOrder() {
    setCart({ products: null })

    history.push(`/home${URLParameters}`)

    return
  }

  function handleCloseDialogAntiFraud() {
    setVisibleAntifraud(false)
  }

  const handleAntiFraudConfirmation = async () => {
    const antiFraud = {
      orderId,
      antifraudId: antifraud.id
    }

    const result = await setAntiFraudConfirmation({ antiFraud })
    if (result && result.success) {
      handleCloseDialogAntiFraud() 
    }else {
			setErrorMessage(result.message)
		}
  }

  function handleWhattsApp() {
    let otherWindow = window.open()
    otherWindow.opener = null
    otherWindow.location = `http://api.whatsapp.com/send?1=pt_BR&phone=${WHATSAPP_NUMBER}`
  }

  return (
    <>
      <ReceiptPageContainer>
        <MobileHeader>
          <H1>Comprovante</H1>
          <BackButton className='back-button' onClick={() => {
             history.push(`/home${URLParameters}`)
          }}>
            <CustomIcon name='AngleLeft' />
          </BackButton>
        </MobileHeader>
        {!isMobile && <MainHeader
          theme={theme}
          mode='simple'
          storeName={storeName}
          storeLogo={storeLogo}
          pathName={location.pathname}
        />}
        {loading.includes('receipt') &&
          <LoadingContainer>
            <CustomLoading
              type={'spin'}
              id='default-loading'
              height={40}
              width={40}
            />
          </LoadingContainer>}
        {!loading.includes('receipt') && <ReceiptMain>
          {WHATSAPP === 'true' &&
            <WhattsAppImg onClick={() => handleWhattsApp()}>
              <Image src={WhattsAppImage} alt="" />
            </WhattsAppImg>}
          {modalityId === 4 && deliveryAddress && notification && <Delivery
            storeName={storeName}
            logoStore={logoStore}
            userName={userName}
            status={status}
            requestNumber={requestNumber}
            date={date}
            deliveryTime={deliveryTime}
            products={products}
            cpf={cpf}
            hasCpf={hasCpf}
            cashChange={cashChange}
            cardBrand={cardBrand}
            cardNumber={cardNumber}
            notification={notification}
            deliveryAddress={deliveryAddress}
            phone={phone}
            whattsapp={whattsapp}
            handleFinishOrder={handleFinishOrder}
            handleStatus={handleStatus}
            modalityId={modalityId}
            scheduling={scheduling}
            orderId={orderId}
            URLParameters={URLParameters}
            followLink={followLink}
            earnedPoints={earnedPoints}
          />}

          {modalityId === 2 && requestNumber && <Pickup
            storeName={storeName}
            logoStore={logoStore}
            userName={userName}
            status={status}
            requestNumber={requestNumber}
            date={date}
            deliveryTime={deliveryTime}
            products={products}
            cashChange={cashChange}
            cpf={cpf}
            hasCpf={hasCpf}
            cardBrand={cardBrand}
            cardNumber={cardNumber}
            storeAddress={storeAddress}
            phone={phone}
            whattsapp={whattsapp}
            handleFinishOrder={handleFinishOrder}
            orderId={orderId}
            earnedPoints={earnedPoints}
            scheduling={scheduling}
          />}
        </ReceiptMain>}
        <Dialog
          aria-labelledby="anti-fraud-dialog"
          open={visibleAntifraud}
          TransitionComponent={Transition}
          maxWidth={'sm'}
          fullScreen={fullScreen}
          className='anti-fraud'
          PaperProps={{
            style: {
              maxWidth: '20rem',
              minWidth: '20rem',
              borderRadius: '.75rem'
            }
          }}
        >
          <AntiFraud
            orderId={orderId}
            antifraud={antifraud}
            handleAntiFraudConfirmation={handleAntiFraudConfirmation}
            errorMessage={errorMessage}
          />
        </Dialog>
      </ReceiptPageContainer>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    deliveryAddress: (state.main.receipt && state.main.receipt.deliveryAddress) || '',
    notification: (state.main.receiptStatus && state.main.receiptStatus.notification) || {},
    deliveryTime: (state.main.receipt && state.main.receipt.notification && state.main.receipt.notification.deliveryTime) || '',
    products: (state.main.receipt && state.main.receipt.list && state.main.receipt.summary && {
      list: state.main.receipt.list,
      summary: state.main.receipt.summary
    }) || { list: [], summary: {} },
    device: (state.main.params && state.main.params.device) || '',
    isPaid: (state.main.receipt && state.main.receipt.isPaid) || '',
    loading: state.loading.loading || [],
    modalityId: (state.main.receipt && state.main.receipt.modalityId) || 0,
    completed: (state.main.receiptStatus && state.main.receiptStatus.completed) || false,
    orderId: (state.main.order && state.main.order.id) || (state.main.order && state.main.order.finishData && state.main.order.finishData.vqpOrderId) || 0,
    receipt: state.main.receipt || null,
    earnedPoints: (state.main.receipt && state.main.receipt.earnedPoints) || '',
    storeName: (state.main.receipt && state.main.receipt.store && state.main.receipt.store.name) || '',
    storeAddress: (state.main.receipt && state.main.receipt.store && state.main.receipt.store.address) || '',
    logoStore: (state.main.receipt && state.main.receipt.store && state.main.receipt.store.logoUrl) || '',
    phone: (state.main.receipt && state.main.receipt.store && state.main.receipt.store.phone) || '',
    whattsapp: (state.main.receipt && state.main.receipt.store && state.main.receipt.store.whattsapp) || '',
    date: state.main.receipt && state.main.receipt.orderDate,
    requestNumber: (state.main.receipt && state.main.receipt.requestNumber) || -1,
    userName: state.user.name || '',
    cardBrand: (state.main.receipt && state.main.receipt.cardBrand) || '',
    cardNumber: (state.main.receipt && state.main.receipt.cardNumber) || '',
    scheduling: state.main.receipt && state.main.receipt.scheduling,
    cpf: state.main.receipt && state.main.receipt.cpf,
    URLParameters: (state.main.URLParameters) || '',
    antifraudCheck: (state.main.antifraudCheck) || false,
    antifraud: (state.main.order && state.main.order.antifraud) || {},
		followLink: (state.main.receiptStatus && state.main.receiptStatus.followLink) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchReceipt,
  fetchRequestNumber,
  fetchReceiptStatus,
  setCart,
  handleGTM,
  setReceipt,
  setReceiptNotification,
  fetchStatusAntiFraud,
  setAntiFraudConfirmation
})

export const Receipt = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'receipt' })
)(ReceiptPage))
