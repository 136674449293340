import { actionTypes } from '.'

import {
	sidebarContentEnum
} from '../../domain/sidebar'

const localStorageKey = 'sidebar'

const initialState = {
	content: sidebarContentEnum.default,
	opened: false
}

const reducer = (state = initialState, action) => {
  const localStorageData = JSON.parse(localStorage.getItem(localStorageKey)) || {}
  const loadedState = {
    ...state,
    ...localStorageData
  }
  const newState = {
    value: loadedState
  }

  switch (action.type) {
    case actionTypes.SET_CONTENT:
			Object.assign(newState, {
				value: {
					...loadedState,
					content: action.payload
				}
			})

			break

		case actionTypes.SET_OPENED:
			Object.assign(newState, {
				value: {
					...loadedState,
					opened: action.payload
				}
			})

			break

    default:
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState.value))

  return newState.value
}

export default reducer
