import React from 'react'

import { OrderStatus } from './../'
import { OrderSummary } from '../../'

import {
  ReceiptSumaryContainer,
  ReceiptSummaryStore,
  ReceiptSummaryContent,
  StoreContainer,
  StoreLabel,
  StoreLogo,
  StoreName,
} from './styles'

export function Summary({
  storeLogo,
  products,
  storeName,
  deliveryAddress,
  cardBrand,
  cardNumber,
  cpf,
  hasCpf,
  modalityId,
  scheduling,
  URLParameters,
  earnedPoints,
  ...propStatus
}) {
  return (
    <ReceiptSumaryContainer>
      <ReceiptSummaryStore>
        <StoreContainer>
          <StoreLabel>Seu pedido em:</StoreLabel>
          <StoreName>{storeName}</StoreName>
        </StoreContainer>
        <StoreLogo src={storeLogo} />
      </ReceiptSummaryStore>
      <ReceiptSummaryContent>
        {!scheduling && <OrderStatus {...propStatus} />}
        <OrderSummary
        	URLParameters={URLParameters}
          scheduling={scheduling}
          deliveryAddress={deliveryAddress}
          cardBrand={cardBrand}
          cardNumber={cardNumber}
          cpf={cpf}
          hasCpf={hasCpf}
          products={products}
          modalityId={modalityId}
          buttonName='Recebi meu pedido'
          earnedPoints={earnedPoints}
        />
      </ReceiptSummaryContent>
    </ReceiptSumaryContainer>
  )
}
