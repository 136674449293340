import React from 'react'

import { CustomIcon } from '../../components'
import { getCardBrandImageByPaymentTypeAndId } from '../../../infra/utils'

import {
	Wrapper,
	Paragraph,
	Image,
	Button,
	H3
} from './styles'

export function Card(props) {
	const {
		number,
		brand,
		paymentType,
		brandId,
		handlePayment
	} = props

	return <Wrapper id='cart-card'>
		<div className="content">
			<H3>Forma de pagamento</H3>

			<div className="brand">
				{brand ? <Image src={getCardBrandImageByPaymentTypeAndId(paymentType, brandId)} alt='Bandeira do cartão' /> : null}

				{number ? <Paragraph>{number}</Paragraph> : null}
			</div>
		</div>
		<div className="edit">
			<Button onClick={() => {
				handlePayment()
			}}>
				<CustomIcon name='Pen' />
			</Button>
		</div>
	</Wrapper>
}
