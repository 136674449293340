import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	margin: 0 auto;
	flex: 1;
	background: #FFFFFF;
	border-radius: 15px;
	padding: 20px;
	min-width: 100%;

  &.dialog {
	  min-width: calc(100% - 40px);

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 100%;
        flex: 1;
        gap: 20px;

        > button {
          flex: 1;
        }
      }
    }
  }

	> p {
		margin: 0;
		margin-bottom: 10px;

		&:last-child {
			margin: 0;
		}
	}

  > div {
    min-width: 100%;
  }

  
  svg {
    path {
      fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    }
  }
`

export const H4 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
  font-size: 18px;
  line-height: 18px;
  padding: 0;
  margin: 20px 0;
	flex: 1;
	text-align: center;
`

export const Paragraph = styled.p`
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
	padding: 0;
	margin: 0;
	font-size: 14px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
  text-align: center;
	flex: 1;
	min-width: 100%;

  &.warning {
    color: #F00;
    justify-content: center;
    align-items: center;
  }
`

export const Button = styled.button`
	color: #FFFFFF;
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border-radius: 15px;
  font-weight: bold;
  flex: 1;
  max-height: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  box-sizing: border-box;
`

export const Input = styled.input`
	border: 0 none;
	border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey06Color) || '#EEE'} 1px solid;
	font-size: 18px;
	text-align: center;
	height: 30px;
	min-height: 30px;
	max-height: 30px;
	line-height: 30px;
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.blue06Color) || '#000' };
	margin: 0 auto;
	flex: 0;
	margin-bottom: 20px;
	min-width: 50%;
  flex: 1;

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const Div = styled.div`
  min-width: 100%;
  display: flex;

  &.warning {
    > input {
      color: #f00;
    }
  }

  &.input {
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > input {
      margin-bottom: 5px;
    }

    > p {
      font-size: 14px;
    }
  }
`

export const CloseButton = styled.button`
  padding: 1rem;
  background: none;
  border: 0 none;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
  text-align: end;
  position: absolute;
	right: 0;
	top: 0;
	font-size: 1.5rem;
	z-index: 1;

	@media (min-width: 40rem) {
		right: -1rem;
		top: -3.5rem;
	}

  @media (max-width: 40rem) {
		display: none;
	}
`
