import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 1.25rem 0;
  flex-direction: row;

  @media (min-width: 60rem) {
    margin: 0 2rem 1rem;
  }
`

export const CouponInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  flex: 1;
  min-width: calc(100% - 6.5rem);
  max-width: calc(100% - 6.5rem);
  margin-right: .5rem;

  input {
    background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#EEE' };
    flex: 1;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    font-size: .7rem;
    padding: .5rem .5rem .5rem 2.25rem;

    &::placeholder {
      font-size: .7rem;
    }

    &:focus {
      background: #fff;
    }
  }

  svg {
    position: absolute;
    left: .5rem;
    width: 1.5rem;
    top: 50%;
    transform: translate(0, -50%);

    path {
      fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' };
      stroke: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' };
    }
  }

  @media (min-width: 60rem) {
    max-width: 20rem;
    min-width: 20rem;
    margin-right: 1rem;

    input {
      font-size: 1rem;

      &::placeholder {
        font-size: 1rem;
      }
    }
  }
`

export const RegisterButton = styled.button `
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  color: #fff;
  text-align: center;
  font-weight: 700;
  max-width: 6rem;
  min-width: 6rem;
  height: 3rem;
  border: 0;
  border-radius: 1rem;
  cursor: pointer;
  flex: 1;

  &:hover {
    opacity: .9;
  }
`
