export function PWAClose() {
  window.PWAClose && window.PWAClose()
}

export function PWAFinish(data) {
  window.PWAFinish && window.PWAFinish(data)
}

export function PWAAnalytics(data) {
  window.PWAAnalytics && window.PWAAnalytics(data)
}

export function PWACall(data) {
  window.PWACall && window.PWACall(data)
}
