import styled from 'styled-components'

export const Wrapper = styled.div`
	font-family: ${props => props.theme.newFontFamily};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	box-sizing: border-box;
	margin: 0 auto;
	min-width: 100%;
	max-width: 100%;
	padding: 2.5rem 1.25rem;
	padding-bottom: 1.875rem;
	flex: 1;
	border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#EFEFEF' } .063rem solid;

	> h2 {
		margin-bottom: .625rem;
	}

	> p {
		margin-bottom: 1.25rem;
	}
`

export const TimeButton = styled.button`
	margin: 0;
	transition: all .25s ease-in;
	min-width: 5rem;
	margin-right: .625rem;
	min-height: 3.125rem;
	background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFifthColor) || '#f2f2f2' };
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333' };
	border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#999' } .063rem solid;
	border-radius: .625rem;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: .313rem .625rem;
	max-width: 5rem;

	* {
		transition: all .25s ease-in;
	}

	&:last-child {
		margin-right: 0;
	}

	&.active {
		border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } .063rem solid;
		background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
		color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff' };
	}
`

export const DayButton = styled.button`
	margin: 0;
	transition: all .25s ease-in;
	min-width: 5rem;
	margin-right: .625rem;
	padding: 0;
	min-height: 3.125rem;

	* {
		transition: all .25s ease-in;
	}

	&:last-child {
		margin-right: 0;
	}

	&.active {
		> span.date {
			border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } .063rem solid;
			background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff' };

			> h3 {
				color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#999' };
			}

			> p {
				color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#333' };
			}
		}
	}

	> span.date {
		background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFifthColor) || '#f2f2f2' };
		color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333' };
		border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#999' } .063rem solid;
		border-radius: .625rem;
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: .313rem .625rem;

		> h3 {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#999' };
			font-size: .75rem;
			text-transform: uppercase;
			font-weight: normal;
			line-height: 1rem;
			margin-bottom: .313rem;
		}

		> p {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#333' };
			font-size: 1rem;
			font-weight: bold;
			line-height: .875rem;
		}
	}
`

export const DatesWrapper = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	position: relative;
	min-width: 100%;
  max-width: 100%;

	> div {
		display: flex;
		flex: 1;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: row;
		position: relative;
		min-width: 100%;
		overflow: hidden;
		overflow-x: scroll;
		max-width: 100%;

		&::-webkit-scrollbar {
			width: 0 !important
		}
	}
`

export const Span = styled.span``

export const Paragraph = styled.p`
	margin: 0;
	padding: 0;
`

export const H2 = styled.h2`
	margin: 0;
	padding: 0;
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
	font-size: 1.25rem;
`

export const H3 = styled.h3`
	margin: 0;
	padding: 0;
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
`

export const Div = styled.div``
