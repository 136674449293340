import moment from 'moment'

interface SignUpRequest {
	name?: string
	lastName?: string
	email?: string
	password?: string
	password2?: string
	birthDate?: string
	CPF?: string
	phone?: string
	googleUserId?: string
	googleToken?: string
	facebookUserId?: string
	facebookToken?: string
	image?: string
}

export const mapSignUpRequest = (data: SignUpRequest): any => {
	return {
		'Nome': data.name,
		'SobreNome': data.lastName,
		'Email': data.email,
		'Senha': data.password,
		'ConfirmaSenha': data.password2,
		'DataNascimento': data.birthDate,
		'Cpf': data.CPF ? data.CPF.replace(/\D/g, '') : data.CPF,
		'Telefone': data.phone,
		'GoogleUsuarioId': data.googleUserId,
		'GoogleToken': data.googleToken,
		'FacebookUsuarioId': data.facebookUserId,
		'FacebookToken': data.facebookToken,
		'FotoId': data.image
	}
}

interface SignUpData {
	id: number
	authToken: string
	name: string
	lastname: string
	email: string
	cpf: string
	phone: string
	birthdate: string
	mustUpdate: boolean
	hasUpdate: boolean
	signUpStatus: number
	signUp: boolean
	updateData: boolean
	loginType: number
	token: {
		accessToken: string
		type: string
		expires: string
	}
	success: boolean
	message: string
}

export const mapSignUpData = (data: any): SignUpData => {
	const expiresSeconds = data['Token'] && data['Token']['ExpiresIn']

	return {
		id: data['UsuarioId'],
		authToken: data['AuthenticationToken'],
		name: data['Nome'],
		lastname: data['SobreNome'],
		email: data['Email'],
		cpf: data['Cpf'],
		phone: data['Telefone'],
		birthdate: data['DataNascimento'],
		mustUpdate: data['AtualizacaoObrigatoria'],
		hasUpdate: data['TemAtualizacao'],
		signUpStatus: data['StatusCadastro'],
		signUp: data['Cadastrar'],
		updateData: data['AtualizarDados'],
		loginType: data['TipoLogin'],
		token: {
			accessToken: data['Token'] && data['Token']['AccessToken'],
			type: data['Token'] && data['Token']['TokenType'],
			expires: moment().add('seconds', expiresSeconds).toISOString()
		},
		success: data['Sucesso'],
		message: data['Mensagem']
	}
}

// Facebook

interface SignUpFacebookRequest {
	name?: string
	lastName?: string
	email?: string
	birthDate?: string
	CPF?: string
	phone?: string
	facebookUserId?: string
	facebookToken?: string
	image?: string
}

export const mapSignUpFacebookRequest = (data: SignUpFacebookRequest): any => {
	return {
		'Nome': data.name,
		'SobreNome': data.lastName,
		'Email': data.email,
		'DataNascimento': data.birthDate,
		'Cpf': data.CPF ? data.CPF.replace(/\D/g, '') : data.CPF,
		'Telefone': data.phone,
		'FacebookUsuarioId': data.facebookUserId,
		'FacebookToken': data.facebookToken,
		'FotoId': data.image
	}
}

interface SignUpFacebookData {
	id: number
	authToken: string
	name: string
	lastname: string
	email: string
	cpf: string
	phone: string
	birthdate: string
	mustUpdate: boolean
	hasUpdate: boolean
	signUpStatus: number
	signUp: boolean
	updateData: boolean
	loginType: number
	token: {
		accessToken: string
		type: string
		expires: string
	}
	success: boolean
	message: string
}

export const mapSignUpFacebookData = (data: any): SignUpFacebookData => {
	const expiresSeconds = data['Token'] && data['Token']['ExpiresIn']

	return {
		id: data['UsuarioId'],
		authToken: data['AuthenticationToken'],
		name: data['Nome'],
		lastname: data['SobreNome'],
		email: data['Email'],
		cpf: data['Cpf'],
		phone: data['Telefone'],
		birthdate: data['DataNascimento'],
		mustUpdate: data['AtualizacaoObrigatoria'],
		hasUpdate: data['TemAtualizacao'],
		signUpStatus: data['StatusCadastro'],
		signUp: data['Cadastrar'],
		updateData: data['AtualizarDados'],
		loginType: data['TipoLogin'],
		token: {
			accessToken: data['Token'] && data['Token']['AccessToken'],
			type: data['Token'] && data['Token']['TokenType'],
			expires: moment().add('seconds', expiresSeconds).toISOString()
		},
		success: data['Sucesso'],
		message: data['Mensagem']
	}
}


// Google

interface SignUpGoogleRequest {
	name?: string
	lastName?: string
	email?: string
	birthDate?: string
	CPF?: string
	phone?: string
	googleUserId?: string
	googleToken?: string
	image?: string
}

interface SignUpGoogleResponse {
	Nome?: string
	SobreNome?: string
	Email?: string
	DataNascimento?: string
	Cpf?: string
	Telefone?: string
	GoogleUsuarioId?: string
	GoogleToken?: string
	FotoId?: string
}

export const mapSignUpGoogleRequest = (data: SignUpGoogleRequest): SignUpGoogleResponse => {
	return data && {
		Nome: data.name,
		SobreNome: data.lastName,
		Email: data.email,
		DataNascimento: data.birthDate,
		Cpf: data.CPF ? data.CPF.replace(/\D/g, '') : data.CPF,
		Telefone: data.phone,
		GoogleUsuarioId: data.googleUserId,
		GoogleToken: data.googleToken,
		FotoId: data.image
	}
}

interface SignUpGoogleData {
	id: number
	authToken: string
	name: string
	lastname: string
	email: string
	cpf: string
	phone: string
	birthdate: string
	mustUpdate: boolean
	hasUpdate: boolean
	signUpStatus: number
	signUp: boolean
	updateData: boolean
	loginType: number
	token: {
		accessToken: string
		type: string
		expires: string
	}
	success: boolean
	message: string
}

export const mapSignUpGoogleData = (data: any): SignUpGoogleData => {
	const expiresSeconds = data['Token'] && data['Token']['ExpiresIn']

	return {
		id: data['UsuarioId'],
		authToken: data['AuthenticationToken'],
		name: data['Nome'],
		lastname: data['SobreNome'],
		email: data['Email'],
		cpf: data['Cpf'],
		phone: data['Telefone'],
		birthdate: data['DataNascimento'],
		mustUpdate: data['AtualizacaoObrigatoria'],
		hasUpdate: data['TemAtualizacao'],
		signUpStatus: data['StatusCadastro'],
		signUp: data['Cadastrar'],
		updateData: data['AtualizarDados'],
		loginType: data['TipoLogin'],
		token: {
			accessToken: data['Token'] && data['Token']['AccessToken'],
			type: data['Token'] && data['Token']['TokenType'],
			expires: moment().add('seconds', expiresSeconds).toISOString()
		},
		success: data['Sucesso'],
		message: data['Mensagem']
	}
}

export const mapSignUpPhoneRequest = (data: any): any => {
	return {
		'Telefone': data.fullPhone || data.phone,
		'UsuarioId': data.userId
	}
}

export const mapSignUpPhoneCodeRequest = (data: any): any => {
	return {
		'Codigo': data.code
	}
}

export const mapSignUpEmailCodeRequest = (data: any): any => {
	return {
		'Email': data.email,
		'Codigo': data.code
	}
}

export const mapSignUpEmailData = (data: any): any => {
	return {
		success: data['Sucesso'],
		message: data['Mensagem']
	}
}


export const mapSignUpResendCodeRequest = (data: any): any => {
	return {
		'Email': data.email
	}
}

export const mapSignUpResendCodeData = (data: any): any => {
	return {
		success: data['Sucesso'],
		message: data['Mensagem']
	}
}

export const mapSignUpPasswordRequest = (data: any): any => {
	return {
		'Email': data.email,
		'Senha': data.password
	}
}

export const mapSignUpPasswordData = (data: any): any => {
	return {
		success: data['Sucesso'],
		message: data['Mensagem']
	}
}

export const mapSignUpPhoneData = (data: any): any => {
	const expiresSeconds = data['Token'] && data['Token']['ExpiresIn']

	return {
		user: {
			id: data['UsuarioId'],
			authToken: data['AuthenticationToken'],
			name: data['Nome'],
			lastname: data['SobreNome'],
			email: data['Email'],
			cpf: data['Cpf'],
			phone: data['Telefone'],
			birthdate: data['DataNascimento'],
			mustUpdate: data['AtualizacaoObrigatoria'],
			hasUpdate: data['TemAtualizacao'],
			signUpStatus: data['StatusCadastro'],
			signUp: data['Cadastrar'],
			updateData: data['AtualizarDados'],
			loginType: data['TipoLogin'],
			token: {
				accessToken: data['Token'] && data['Token']['AccessToken'],
				type: data['Token'] && data['Token']['TokenType'],
				expires: moment().add('seconds', expiresSeconds).toISOString()
			}
		},
		success: data['Sucesso'],
		message: data['Mensagem']
	}
}

export const mapSignUpPhoneCodeData = (data: any): any => {
	const expiresSeconds = data['Token'] && data['Token']['ExpiresIn']

	return {
		user: {
			id: data['UsuarioId'],
			authToken: data['AuthenticationToken'],
			name: data['Nome'],
			lastname: data['SobreNome'],
			email: data['Email'],
			cpf: data['Cpf'],
			phone: data['Telefone'],
			birthdate: data['DataNascimento'],
			mustUpdate: data['AtualizacaoObrigatoria'],
			hasUpdate: data['TemAtualizacao'],
			signUpStatus: data['StatusCadastro'],
			signUp: data['Cadastrar'],
			updateData: data['AtualizarDados'],
			loginType: data['TipoLogin'],
			token: {
				accessToken: data['Token'] && data['Token']['AccessToken'],
				type: data['Token'] && data['Token']['TokenType'],
				expires: moment().add('seconds', expiresSeconds).toISOString()
			}
		},
		success: data['Sucesso'],
		message: data['Mensagem']
	}
}
