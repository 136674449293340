import styled from 'styled-components'

export const Wrapper = styled.aside`
	position: fixed;
	top: 0;
	right: auto;
	z-index: 2;
	display: none;
  overflow-y: auto;
	background: #fff;
	overflow: hidden;

	${props => props.opened ? `
		left: calc(100% - 20rem);
		min-width: 20rem;
		max-width: 20rem;
	` : ``}

	&.placed-order {
		z-index: 1;
		display: block;

		> div {
			> div {
				> ul {
					padding: 0;
				}
			}
		}
	}
`
