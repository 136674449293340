import styled from 'styled-components'

import { fadeIn } from '../components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0;
  box-sizing: border-box;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  position: relative;
  background: #fff;

  ${fadeIn}

  * {
    font-family: 'Nunito', 'sans-serif';
  }

  > div {
    &#stores-list-loading {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      min-width: calc(100% - 2.5rem);
      max-width: calc(100% - 2.5rem);
      flex: 1;
    }
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
  padding: 1.25rem;
`

export const ContentWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.25rem;
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;

  ${fadeIn}

  > div {
    display: flex;
    flex-direction: column;

    &#stores-list-search {
      margin-bottom: 1.875rem;
    }
  }
`
export const MobileHeaderWrapper = styled.header`
  width: 100%;
  height: 2rem;

  > button {
    &.close {
      background: none;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 1.25rem;
      top: 1.25rem;
      font-size: 1.25rem;
      font-weight: 100;
    }
  }

  @media (min-width: 50rem) {
    display: none;
  }
`

export const CloseButton = styled.button`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0;
`
