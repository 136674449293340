import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #fff;
  padding: 2rem;
  display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    flex-direction: row;
    color: #fff;
		justify-content: center;
		align-items: center;
    padding: 10px 12px;
    border-radius: 15px;
    font-weight: 100;
    outline: 0;
    border: 0;
    margin: 0 .4rem 0 0;
    font-size: 0.875rem;
    line-height: 2;
    letter-spacing: 0.02857em;
    font-weight: bold
    width: 100%;
    background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
		cursor: pointer;
  }

	>p{
		font-size: 0.875rem;
		margin-bottom: 1rem;
		padding: 0 1rem;
	}
`

export const Title = styled.h1`
	margin: .1rem 0;
	font-size: 1.4rem;
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.titleColor) || '#000'};	
`

export const CustomButton = styled.button`
	>span{
		margin: 0 .7rem;

		@media(max-width: 60rem){
			margin: 0 .2rem;
		}
	}
`
