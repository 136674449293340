import React from 'react'
import PropTypes from 'prop-types'
import { CustomButton, ModalityContainer } from './styles'
//import { MULTI_STORE } from '../../../config'

export function ModalityMobile(props) {
	const {
		storeDelivery,
		opened,
		modalityId,
		storePickup,
		handleModality,
		handleGTM,
		content,
		storeName
	} = props

	const modalitySection = content.filter(item => item.id === 'modality')[0]
	const modalitySectionActions = modalitySection.actions
	const modalityDeliveryButton = modalitySectionActions.filter(item => item.id === 'delivery')[0]
	const modalityDeliveryButtonLabel = modalityDeliveryButton.label
	const modalityPickupButton = modalitySectionActions.filter(item => item.id === 'pickup')[0]
	const modalityPickupButtonLabel = modalityPickupButton.label

	return (
		<ModalityContainer storeDelivery={storeDelivery} className='btn-group' opened={opened}>
			{storeDelivery && storePickup ? <>
				<CustomButton
					onClick={() => { handleModality(4) }}
					className={modalityId === 4 ? 'active' : ''}>
					{modalityDeliveryButtonLabel}
				</CustomButton><CustomButton
					onClick={() => {
						handleModality(2)
						//GTM 3.0 - Evento seleção de modalidade retirada
						const dataGTM = {
							'event': 'modalidade_retirada',
							'store': storeName,
							'modalidade_retirada': 'retirar_na_loja'
						}
						console.warn(dataGTM)
						handleGTM(dataGTM)
					}}
					className={modalityId === 2 ? 'active' : ''}>
					{modalityPickupButtonLabel}
				</CustomButton></> : null}
		</ModalityContainer>
	)
}

ModalityMobile.propTypes = {
	handleModality: PropTypes.func,
	handleGTM: PropTypes.func,
}
