import styled from 'styled-components'

import { device } from '../../../domain/enum/responsive'

export const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 960px;
  margin-top: 0;

  @media ${ device.desktop } {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  > div {
    background: #fff;
    box-sizing: border-box;

    @media ${ device.desktop } {
      padding: 1.25rem;
      border-radius: .625rem;
      box-shadow: #ddd .188rem .188rem .375rem;
      min-width: 620px;
      max-width: 620px;
      text-align: left;

      &.status_0 {
        border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textBlueColor) || '#333' } .125rem solid;
      }

      &.status_1 {
        border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textBlueColor) || '#333' } .125rem solid;
      }
    }

    @media ${ device.mobile } {
      &.status_0 {
        border: 0 none;
      }

      &.status_1 {
        border: 0 none;
      }
    }

    &.sidebar {
      min-width: 280px;
      max-width: 280px;

      @media ${ device.desktop } {
        margin-left: 1.25rem;
        min-width: 320px;
        max-width: 320px;
      }
    }
  }
`;

export const HeaderBox = styled.div`
  > p {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
    text-align: left;
    margin: 1.25rem 0 1.875rem 0;
    padding: 0;
    min-width: 280px;
    flex: 1;

    &.date {
      font-weight: 400;
      font-size: .938rem;
    }
  }

  @media ${ device.desktop } {
    min-width: unset;
    max-width: unset;
    text-align: left;
    margin-bottom: 0;
  }

  > div {
    color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    font-size: 1.25rem;
  }

  > span.status_0 {
    color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textBlueColor) || '#FB9D19' };
  }

  > span.status_1 {
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textEighthColor) || '#444' };
  }
`

export const H3 = styled.h3`
  font-size: 1.125rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 0;
  margin-bottom: 1.25rem;
  min-width: 280px;
  max-width: 280px;
`

export const List = styled.ul`
  list-style: inside none;
  min-width: 280px;
  max-width: 280px;
  padding: 0;
  margin: 0;
  flex: 1;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#B8B8B8'} .063rem solid;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0 -1.25rem;
    padding: 0 1.25rem;
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
  }
`

export const ListItem = styled.li`
  width: 100%;
  margin-bottom: .625rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-size: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  > span {
    flex: 1;
    font-weight: bold;

    &:nth-child(1) {
      max-width: 1.875rem;
    }

    &:nth-child(2) {
      > span {
        display: block;
        font-size: .75rem;
        font-weight: 400;
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#999' };
      }
    }

    &:nth-child(3) {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textBlueColor) || '#999' };
      text-align: right;
    }
  }
`

export const ImageContainer = styled.img`
  width: 6.25rem;
  height: auto;
`

export const Paragraph = styled.p`  
  min-width: 280px;
  padding: 0;
  margin: 0;
  color: #7D7D7D;
`

export const SummaryParagraph = styled.p`
  color: '#7D7D7D';
	padding: 0;
	margin: 0;
	font-size: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1;
	min-width: 100%;
`

export const StatusBox = styled.div`
  min-width: 280px;
  max-width: 280px;
  flex: 1;
  margin-bottom: 1.875rem;
  border: #000 .125rem solid;
  padding: .938rem;
  box-sizing: border-box;
  border-radius: .625rem;
  text-align: center;

  @media ${ device.desktop } {
    min-width: unset;
    max-width: unset;
    text-align: left;
    padding: 0;
    margin-bottom: 0;

    > p:nth-child(3) {
      text-align: center;
      > strong {
        font-size: 1.5rem;
      }
    }
  }

  &.status_0 {
    border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textBlueColor) || '#333' } .125rem solid;
    background: #fff;

    @media ${ device.desktop } {
      border: 0 none;
      background: none;
    }
  }

  &.status_1 {
    border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textEighthColor) || '#444' } .125rem solid;
    background: #fff;

    @media ${ device.desktop } {
      border: 0 none;
      background: none;

      > p {
        color: #000;

        &.requestNumber {
          background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSeventhColor) || '#333' };

          > span {
            display: inline-block;
            color: #fff;
            font-size: 2.5rem;
          }

          > strong {
            color: #fff;
            font-size: 2.5rem;
          }
        }

        > strong {
          color: #000;
        }
      }
    }

    @media ${ device.mobile } {
      > p {
        color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };

        > div {
          color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
          font-size: 1.25rem;
        }

        &.requestNumber {
          > span {
            display: none;
          }

          > strong {
            color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
          }
        }

        > strong {
          color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
        }
      }
    }
  }

  > p {
    min-width: unset;
    margin: 0;
    padding: 0;
    margin-bottom: 1.875rem;

    &.only-mobile {
      @media ${ device.mobile } {
        display: block;
      }

      @media ${ device.desktop } {
        display: none;
      }
    }

    &.only-desktop {
      @media ${ device.mobile } {
        display: none;
      }

      @media ${ device.desktop } {
        display: block;
      }
    }

    &.date {
      margin-bottom: .938rem;
      color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey) || '#000' };

      @media ${ device.desktop } {
        margin-bottom: 1.875rem;
      }
    }

    &.requestNumber {
      margin-bottom: 0;

      @media ${ device.desktop } {
        background: #F7F7F7;
        flex: 1;
        border: #DDD .063rem solid;
        padding: .938rem 0;
        box-sizing: border-box;
        border-radius: .313rem;
        text-align: center;
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };

        > span {
          display: inline-block;
          color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
          text-transform: uppercase;
          font-size: 2.5rem;
          font-weight: bold;
        }

        > strong {
          color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
          font-size: 2.5rem;
        }
      }

      @media ${ device.mobile } {
        > strong {
          color: #000;
          font-size: 2.5rem;
        }

        > span {
          display: none;
        }
      }
    }

    > strong {
      color: #000;
      font-size: 1.25rem;
    }
  }
`

export const PreviewDeliveryBox = styled.div`
  background: #F7F7F7;
  min-width: 280px;
  max-width: 280px;
  flex: 1;
  margin-bottom: 1.875rem;
  border: #DDD .063rem solid;
  padding: .938rem 0;
  box-sizing: border-box;
  border-radius: .313rem;
  text-align: center;
  max-height: 3.125rem;

  @media ${ device.desktop } {
    margin-bottom: 0;
    min-width: unset;
    max-width: unset;
    display: none;
  }
 
  > p {
    font-size: .875rem;
    margin: 0;
    padding: 0;
  }
`

export const SummaryWrapper = styled.div`
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-width: 280px;
	
	flex: 1;
	padding: 0;
	padding-bottom: 2.5rem;
	border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'} .063rem solid;

	> p {
		line-height: 1rem;
    font-size: 1rem;
    max-width: 280px;
    margin: 0 auto;

		&.subtotal {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#333' };
			margin-bottom: .625rem;
		}

		&.fee {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#333' };
			margin-bottom: 1.25rem;
		}

		&.total {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333' };
			font-size: 1.125rem;
			line-height: 1.125rem;

			> span {
				font-weight: bold;
			}
		}

		> span {
			flex: 1;

			&.value {
				align-self: flex-end;
				flex: none;
			}
		}
	}
`
export const Span = styled.span``

export const Footer = styled.footer`  
  flex: 1;
  min-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: .938rem;
  padding-top: 0;
  margin-top: 0;

  @media ${ device.desktop } {
    margin: 0 -1.25rem;
  }

  > p {
    margin-bottom: .938rem;
  }

  > button {
    font-size: 1rem;
    padding: .938rem .938rem;
    box-sizing: border-box;
    max-width: 100%;
    min-width: 100%;
    color: #fff;
    border-radius: .938rem;
    font-weight: bold;
  }
`

export const Button = styled.button`
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && !props.disabled ? props.theme.main.general.primaryColor : '#999') || '#000' };
  cursor: pointer;
`

export const Block = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${ device.desktop } {
    max-width: 12.5rem;
    min-width: 12.5rem;
  }

  &.sidebar {
    display: unset;
    justify-content: unset;
  }

  > img {
    margin: 0 auto;
    flex: none;
    max-height: 6.25rem;
  }
`

export const PlaceBox = styled.div`
  flex: 1;
  margin-bottom: .625rem;
  padding: .938rem 0 .938rem 0;
  box-sizing: border-box;
	border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'} .063rem solid;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0 -1.25rem;
    padding: 0 1.25rem;
    margin-bottom: 1.875rem;
  }

  > h3 {
    margin-bottom: .625rem;
  }

  > p {
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#333' };
    font-size: .875rem;
  } 

  @media ${ device.mobile } {
    display: block;
  }

  &.only-mobile {
    @media ${ device.desktop } {
      display: none;
    }
  }

  &.only-desktop {
    @media ${ device.mobile } {
      display: none;
    }
  }
`

export const HelpBox = styled.div`
  flex: 1;
  margin-bottom: .625rem;
  padding: .625rem 0 .938rem 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0 -1.25rem;
    padding: 0 1.25rem;
    margin-bottom: 1.875rem;
  }

  > h3 {
    margin-bottom: .625rem;
  }

  > div{
    min-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: .625rem;
    
    > button {
      width: 100%;
      padding: .625rem;
      border-radius: .938rem;
      background: #fff;
      margin: 0;
      border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'} .125rem solid;
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333' };
      font-size: .813rem;

      &.off {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333' };
      }
    }
  }

  @media ${ device.mobile } {
    display: block;
  }

  &.only-mobile {
    @media ${ device.desktop } {
      display: none;
    }
  }

  &.only-desktop {
    @media ${ device.mobile } {
      display: none;
    }
  }
`

export const SelectedCardContainer = styled.p`
  margin: 0;
  width: 100%;
  max-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  > span {
    font-size: .75rem;
    font-weight: bold;
    color: #686868;

    &:nth-child(2) {
      font-size: .875rem;
      font-weight: normal;
    }

    > img {
      margin-right: .313rem;
    }

    > a {
      margin-left: .313rem;

      > img {
        width: .938rem;
        height: auto;
      }
    }
  }
`
export const Div = styled.div``
