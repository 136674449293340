import { actionTypes } from ".";

const localStorageKey = "user";

const initialState = {
	accessToken: null,
	changePassword: false,
};

const reducer = (state = initialState, action) => {
	const localStorageData =
		JSON.parse(localStorage.getItem(localStorageKey)) || {};
	const loadedState = {
		...state,
		...localStorageData,
	};

	const newState = {
		value: loadedState,
	};

	switch (action.type) {
		case actionTypes.SET_ACCESS_TOKEN:
			Object.assign(newState, {
				value: {
					...newState.value,
					accessToken: action.payload,
				},
			});

			break;

		case actionTypes.SET_USER_NAME:
			Object.assign(newState, {
				value: {
					...newState.value,
					name: action.payload,
				},
			});

			break;

		case actionTypes.SET_USER_CPF:
			Object.assign(newState, {
				value: {
					...newState.value,
					cpf: action.payload,
				},
			});

			break;

		case actionTypes.SET_USER_CALLBACK:
			Object.assign(newState, {
				value: {
					...newState.value,
					callback: action.payload,
				},
			});

			break;

		case actionTypes.SET_CHANGE_PASSWORD:
			Object.assign(newState, {
				value: {
					...newState.value,
					changePassword: action.payload,
				},
			});

			break;

		default:
	}

	localStorage.setItem(localStorageKey, JSON.stringify(newState.value));

	return newState.value;
};

export default reducer;
