import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0;
	margin: 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	min-width: 100%;
	flex: 1;
	padding: 0 1.25rem;
	padding-bottom: 1.25rem;
    justify-content: center;

	@media (min-width: 50rem) {
		padding: 0;
		padding-bottom: 1.25rem;
	}
`
export const Div = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: .15rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#EEE'};
`
export const Paragraph = styled.p`
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
	font-size: 1rem;
	flex: 1;
	min-width: 100%;
	padding: .7rem;
	text-align: center;
	font-size: .9rem;
	margin: 0;
	box-sizing: border-box;
	font-weight: bold;
`

export const Strong = styled.strong``
