import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  background: #fff;
  padding: 1rem;
  margin: 0;
  margin-bottom: 1rem;
  border-radius: .5rem;
	flex: 0;

  &.primary{
    flex: 1;
    flex-direction: column;
    background: #FFF;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } 1px solid;
    font-size: .875rem;
    justify-content: center;
  }
  
  &.positive{
    flex: 1;
    flex-direction: column;
    background: #FFF;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.positive) || '#000' };
    border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.positive) || '#000' } 1px solid;
    font-size: .875rem;
    justify-content: center;
  }

  &.negative{
    flex: 1;
    flex-direction: column;
    background: #FFF;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.negative) || '#000' };
    border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.negative) || '#000' } 1px solid;
    font-size: .875rem;
    justify-content: center;
  }

  &.bold{
    background: #FFF;
    color: #000;
    border: none;
    font-weight: bold;
    font-size: 1rem
  }
  
`

export const Paragraph = styled.div``
