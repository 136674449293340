import { actionTypes } from '.'

import {
	addressActions,
	loading
} from '../'

export const fetch = (callback) => (dispatch, getState, container) => {
	const done = () => {
    dispatch(loading.setLoading({ item: 'user-addresses', delete: true}))

		callback && callback()
  }

  try {
    const latitude = getState().address.latitude
    const longitude = getState().address.longitude
    const modalityId = getState().main.modality && getState().main.modality.id
    const address = getState().address.address
    const accessToken = getState().user.accessToken

		if (!accessToken) {
			return
		}

    const data = {
      latitude,
      longitude,
      modalityId,
      address
    }

    const actions = {
      setLoading: loading.setLoading
    }

    return container.fetchUserAddresses({
      onSuccess: ({ userAddresses}) => {
        dispatch(setUserAddresses(userAddresses))

				done()
      },
      onError: () => {
        dispatch(setUserAddresses([]))

				done()
      },
      onEmpty: () => {
        dispatch(setUserAddresses([]))

				done()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    dispatch(setUserAddresses([]))

		done()
  }
}

export const remove = (callback) => (dispatch, getState, container) => {
	const callbackErrorFilter = callback && callback.filter(filteredItem => filteredItem.name === 'delete-user-address' && filteredItem.type === 'error')
	const callbackError =  callbackErrorFilter && callbackErrorFilter[0] && callbackErrorFilter[0].callback
	const callbackSuccessFilter = callback && callback.filter(filteredItem => filteredItem.name === 'delete-user-address' && filteredItem.type === 'success')
	const callbackSuccess =  callbackSuccessFilter && callbackSuccessFilter[0] && callbackSuccessFilter[0].callback
	const callbackDataFilter = callback && callback.filter(filteredItem => filteredItem.name === 'delete-user-address' && filteredItem.type === 'data')
	const callbackData = (callbackDataFilter && callbackDataFilter[0] && callbackDataFilter[0].data) || {}

	const {
		id
	} = callbackData

	const done = () => {
    dispatch(loading.setLoading({ item: 'delete-user-address', delete: true}))
  }

  try {
    const data = {
      id
    }

    const actions = {
      setLoading: loading.setLoading
    }

    return container.deleteUserAddress({
      onSuccess: () => {
        done()

				callbackSuccess && callbackSuccess()
      },
      onError: () => {
        done()

				callbackError && callbackError()
      },
      onEmpty: () => {
        done()

				callbackError && callbackError()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

		done()

		callbackError && callbackError()
  }
}

export const add = (callback) => async (dispatch, getState, container) => {
  const callbackError = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const callbackSuccess = callback && callback[0] && typeof callback[1] === 'function' ? callback[1] : () => {}
	
	const stateLoading = (getState().main && getState().loading.loading) || []
	const stateAddress = getState().address.address
	const { latitude, longitude } = getState().address;

	const done = () => {
    dispatch(loading.setLoading({ item: 'add-user-address', delete: true}))
  }

  try {
		if (stateLoading && stateLoading.includes('add-user-address')) {
			throw new Error({loading: true})
		}

		dispatch(loading.setLoading('add-user-address'))

		await dispatch(addressActions.fetchGeolocationByAddress([{
			name: 'geolocation-by-address',
			type: 'error',
			callback: () => {}
		}, {
			name: 'geolocation-by-address',
			type: 'success',
			callback: () => {}
		}]))

		if (stateAddress && !stateAddress.main) {
			throw new Error({main: false})
		}

    const data = { 
			address: {
				...stateAddress, 						
				latitude: stateAddress.latitude || latitude,
				longitude: stateAddress.longitude || longitude
			}
		}
    const actions = {
      setLoading: loading.setLoading
    }

    return container.addUserAddress({
      onSuccess: ({address}) => {
        if (!address.success) {
          callbackError && callbackError()

          return
        }

        if (address.success) {
					done()

          callbackSuccess && callbackSuccess()

					dispatch(addressActions.setAddress({
						...stateAddress,
						...address,
						latitude: stateAddress.latitude || address.latitude || latitude,
						longitude: stateAddress.longitude || address.longitude || longitude
					}))

          return
        }
      },
      onError: () => {
				done()

        callbackError && callbackError()
      },
      onEmpty: () => {
				done()

        callbackError && callbackError()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

		done()

    callbackError && callbackError()
  }
}

export const setUserAddresses = (userAddresses) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_ADDRESSES,
    payload: userAddresses
 })
}

export const setUserAddress = (userAddress) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_ADDRESS,
    payload: userAddress
 })
}

export const removeUserAddressesItem = (userAddress) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_USER_ADDRESSES_ITEM,
    payload: userAddress
  })
}
