import React, {
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import {
  connect
} from 'react-redux'

import {
  pipe
} from '../../../domain/helpers'

import { Icon } from '../../components/common'

import {
  Wrapper,
  Button,
  Span,
  Div
} from './styles'

const calculateCartTotal = (items) => {
	const total = items && items.reduce((acc, item) => acc + calculateItemTotal(item), 0)

  return total
}

const calculateItemTotal = (item) => {
  const {
    selectedAdditionals
  } = item

  const valorFinalProduct = item.promotionalValue || item.value

  const selectedAdditionalsGroups = selectedAdditionals && selectedAdditionals.length && selectedAdditionals.map((mappedItem) => {
    const selectedAdditionalsMaximum = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 3)
    const selectedAdditionalsAverage = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 2)
    const selectedAdditionalsFixes = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 1)

    const additionalsMaximumTotal = (selectedAdditionalsMaximum && selectedAdditionalsMaximum.length && Math.max.apply(Math, selectedAdditionalsMaximum.map((selectedAdditionalsMaximumItem) => selectedAdditionalsMaximumItem.value ))) || 0
    const additionalsAverageTotal = selectedAdditionalsAverage && selectedAdditionalsAverage.length ? (selectedAdditionalsAverage.reduce((acc, current) => Number(acc + current.value), [])) / selectedAdditionalsAverage.length : 0
    const additionalsFixesTotal = selectedAdditionalsFixes && selectedAdditionalsFixes.length ? selectedAdditionalsFixes.reduce((acc, current) => {
			if (current.quantity > 1) {
				return Number(acc + (current.value * current.quantity))
			}

			return Number(acc + current.value)
		}, []) : 0

    return additionalsMaximumTotal + additionalsAverageTotal + additionalsFixesTotal
 })
  const selectedAdditionalsGroupsTotal = (selectedAdditionalsGroups && selectedAdditionalsGroups.length && selectedAdditionalsGroups.reduce((acc, current) => Number(acc + current), [])) || 0

  const total = item.quantity * (valorFinalProduct + selectedAdditionalsGroupsTotal)

  return total
}

const calculateQuantityTotal = (items) => {
	const total = items && items.reduce((acc, item) => acc + calculateItemQuantity(item), 0)

  return total
}

const calculateItemQuantity = (item) => {
  const total = item.quantity 

  return total
}

function CartBarComponent(props) {
  const {
    data,
    handleClick
  } = props

  useEffect(() => {
    return () => {}
  }, [])

  const valueTotal = calculateCartTotal(data)
  const quantityTotal = calculateQuantityTotal(data)

  const newProps = Object.assign( {}, props )

  delete newProps.data

  return valueTotal ? <Wrapper { ...newProps } id='store-cartbar' onClick={() => {
    handleClick()
  }}>
    <Button>
      <Div className='icon'>
        <Span className='quantity'>{ quantityTotal }</Span>
        <Icon name='cesta' />
      </Div>
      <Span className='label'>Meu Pedido</Span>
      <Span className='total'>
        { `R$ ${ Number(valueTotal).toFixed(2).replace('.', ',') }` }
      </Span>
    </Button>
  </Wrapper> : null
}

CartBarComponent.propTypes = {
  handleClick: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({}))
}

const mapStateToProps = (state) => {
  return {
    data: (state.main.cart && state.main.cart.products) || []
  }
}

const GetConnection = connect(mapStateToProps, {})

export const CartBar = React.memo(pipe(
  GetConnection
)(CartBarComponent))
