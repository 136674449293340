import React, {
  useEffect
} from 'react'

import {
  Details,
  Summary
} from './'

export function Delivery(props) {
  const {
    userName,
    cpf,
    cardBrand,
    cardNumber,
    deliveryTime,
    hasCpf,
    date,
    products,
    notification,
    deliveryAddress,
    handleStatus,
    storeName,
    logoStore,
    modalityId,
    scheduling,
    orderId,
    URLParameters,
		followLink,
		requestNumber,
    earnedPoints
  } = props

  const statusProgress = notification && notification.status

  const status0 = notification && notification.statuses && notification.statuses[0] && (notification.statuses[0].active || notification.statuses[0].time)
  const status1 = notification && notification.statuses && notification.statuses[1] && (notification.statuses[1].active || notification.statuses[1].time)
  const status2 = notification && notification.statuses && notification.statuses[2] && (notification.statuses[2].active || notification.statuses[2].time)
  const status3 = notification && notification.statuses && notification.statuses[3] && (notification.statuses[3].active || notification.statuses[3].time)

  const statusDate0 = notification && notification.statuses && notification.statuses[0] && notification.statuses[0].time
  const statusDate1 = notification && notification.statuses && notification.statuses[1] && notification.statuses[1].time
  const statusDate2 = notification && notification.statuses && notification.statuses[2] && notification.statuses[2].time
  const statusDate3 = notification && notification.statuses && notification.statuses[3] && notification.statuses[3].time

  const propStatus = {
    statusProgress,
    status0,
    status1,
    status2,
    status3,
    statusDate0,
    statusDate1,
    statusDate2,
    statusDate3
  }

  useEffect(() => {
    handleStatus(1)

    if (status3) {
      handleStatus(2)
    }

    return () => { }
  }, [
    handleStatus,
    status0,
    status1,
    status2,
    status3
  ])

  return (
    <>
      <Details
				userName={userName}
        status={statusProgress}
        deliveryTime={deliveryTime}
        deliveryAddress={deliveryAddress}
        date={date}
        cardBrand={cardBrand}
        cardNumber={cardNumber}
        cpf={cpf}
        hasCpf={hasCpf}
        scheduling={scheduling}
        orderId={orderId}
        followLink={followLink}
				requestNumber={requestNumber}
        earnedPoints={earnedPoints}
      />
      <Summary
        scheduling={scheduling}
        deliveryAddress={deliveryAddress}
        cardBrand={cardBrand}
        cardNumber={cardNumber}
        cpf={cpf}
        hasCpf={hasCpf}
        storeName={storeName}
        storeLogo={logoStore}
        products={products}
        modalityId={modalityId}
        URLParameters={URLParameters}
        earnedPoints={earnedPoints}
        {...propStatus}
      />
    </>
  )
}
