export default {
	SET_STORE: 'SET_STORE',
	SET_SELECTED_STORE_ID: 'SET_SELECTED_STORE_ID',
	SET_STORE_FEES: 'SET_STORE_FEES',
	SET_STORES: 'SET_STORES',
	SET_STORES_LIST_REQUEST: 'SET_STORES_LIST_REQUEST',
	SET_PAGINATION: 'SET_PAGINATION',
	SET_SELECTED_CATEGORY: 'SET_SELECTED_CATEGORY',
	SET_STORE_MODALITIES: 'SET_STORE_MODALITIES',
	SET_LOYALTY_POINTS: 'SET_LOYALTY_POINTS'
}
