import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-width: 100%;
	flex: 1;
	padding: 0 1.25rem;
	padding-bottom: 1.25rem;
	min-width: calc(100% - 1.5rem); 

	@media (min-width: 50rem) {
		padding: 0;
		padding-bottom: 1.25rem;
	}
`

export const Paragraph = styled.p`
  color: #fff;
  font-size: 1rem;
  flex: 1;
  min-width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: #fff .063rem solid;
  border-radius: .938rem;
`

export const Strong = styled.strong``
