import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles'

export function PageContainerMain({ children, itemId, opened }) {
  return (
    <Wrapper opened={opened} itemId={itemId}>{children}</Wrapper>
  )
}

PageContainerMain.propTypes = {
	children: PropTypes.node,
	itemId: PropTypes.number,
	opened: PropTypes.bool
}
