import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import {
  useStoreSettings,
  useDeviceLayout,
  useLayout,
  useSidebar
} from '../hooks'

import {
  logout,
  setModality
} from '../../redux/actions/main'

import {
  cart,
  coupon
} from '../../state'

import {
  pipe,
  GetContent,
  caculateProductsQuantity
} from '../../domain/helpers'

import history from '../../history'

import {
  DefaultImage as Image,
  CustomIcon
} from '../components'

import {
  Cart,
  CartContainer
} from '../cart'

import {
  MainHeader
} from '../main-header'

import {
  PageContainer,
  PageContainerHeader,
  PageContainerMain,
  PageContainerSide
} from '../page-container'

import {
  Skeleton,
  Navigation,
} from '..'

import { NewCoupon } from './new-coupon'

import { Content } from './content'
import { EmptyContent } from './empty-content'

import {
  H1,
  H2,
  Wrapper,
  LoadingWrapper,
  BackButton,
  Div,
	Message
} from './styles'
import { CouponOverlay } from './CouponOverlay'

import { ReactComponent as IconCheck } from '../assets/icons/icon_check.svg'
import { ReactComponent as IconClose } from '../assets/icons/icon_close.svg'

export const Context = React.createContext({})

export function CouponsPage(props) {
  const {
    accessToken,
    addressDelivery,
    cartProducts,
    fetchCoupons,
    loading,
    coupon,
    couponCpf,
    setCoupon,
    theme,
    address,
    userName,
    match,
    registeredCoupons,
    storeName,
    storeImg,
    modalityId,
    URLParameters
  } = props

  const storeImage = theme.headerImage
  const storeLogo = theme.logoImage

  const {
    layoutMode,
    layoutModeEnum,
    handleLayoutMode
  } = useLayout()

  const {
    sidebarContent,
    sidebarOpened,
    sidebarContentEnum,
    handleSidebarContent,
    handleSidebarOpened
  } = useSidebar()

  const context = useContext(Context)

  const {
    url,
    title,
    description
  } = useStoreSettings()

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const stateCouponId = coupon && coupon.id
  const couponId = (match.params && Number(match.params.couponId)) || null
  const { sections } = useContext(Context)
  const [visibleSignIn, setVisibleSignIn] = useState(false)
  const [viewCards, setViewCards] = useState(false)
  const [viewModalDatails, setViewModalDatails] = useState(false)
  const { coupons = [] } = registeredCoupons
  const [notificationSucess, setNotificationSucess] = useState(null)
  const [notificationError, setNotificationError] = useState(null)

  const registeredCouponsSection = sections && sections.filter(item => item.id === 'registered-coupons')[0]
  const newCouponSection = sections && sections.filter(item => item.id === 'new-coupon')[0]

  const registeredCouponsSectionTitle = registeredCouponsSection.title
  const registeredCouponsSelectedSectionName = registeredCoupons && registeredCoupons.coupons && registeredCoupons.coupons.length ? 'main-content' : 'empty-content'
  const registeredCouponsSelectedSection = registeredCouponsSection.content.filter(item => item.id === registeredCouponsSelectedSectionName)[0]

  const newCouponSectionTitle = newCouponSection.title

  useEffect(() => {
    setTimeout(() => fetchCoupons(), 500)
  }, [
    fetchCoupons
  ])

  useEffect(() => {
    if (couponId && couponId !== stateCouponId) {

      const selectedCoupon = registeredCoupons && registeredCoupons.coupons && registeredCoupons.coupons.filter(item => item.id === couponId)[0]

      setCoupon({
        id: couponId,
        ...selectedCoupon
      })

      if (couponId > 0) {
        setViewModalDatails(true)
      }
    }
  }, [
    isMobile,
    couponCpf,
    couponId,
    registeredCoupons,
    setCoupon,
    stateCouponId,
    handleLayoutMode,
    handleSidebarContent,
    handleSidebarOpened,
    layoutModeEnum,
    sidebarContentEnum
  ])

  async function handleModality(id) {
    setModality({ id })

    await fetchCoupons()
  }

  function handleContactUs() {
    history.push(`/contact-us${URLParameters}`)
  }

  function handleCoupon({
    id
  }) {
    setViewModalDatails(true)
    history.push(`/coupons/${id}${URLParameters}`)
  }

  return (
    <Wrapper id={'coupons'}>
      <Helmet>
        <title>{`${title} - Meus cupons`}</title>
        <meta name='description' content={description} />
        <link rel='canonical' href={`${url}/coupons`} />
      </Helmet>

      {loading.includes('coupons-history') && <LoadingWrapper id='coupons-loading'>
        <Skeleton module='store' />
      </LoadingWrapper>}

      {!loading.includes('coupons-history') && <PageContainer
        isSideOpen={sidebarOpened}
        mode={layoutMode}
      >
        <PageContainerHeader isMobile={isMobile}>
          <MainHeader
            mode='full'
            theme={theme}
            storeLogo={storeLogo}
            name={storeName}
            storeImg={storeImg}
            userName={userName}
            accessToken={accessToken}
            setVisibleSignIn={setVisibleSignIn}
            visibleSignIn={visibleSignIn}
            handleModality={handleModality}
            modality={modalityId}
            addressDelivery={address ? address : addressDelivery}
            cartItemsQuantity={caculateProductsQuantity(cartProducts)}
            homeOnClick={() => {
              handleLayoutMode(layoutModeEnum.default)
              handleSidebarContent(sidebarContentEnum.default)
              handleSidebarOpened(false)
              setViewCards(false)
            }}
            userClicked={() => {
              if (sidebarOpened && sidebarContent === sidebarContentEnum.nav) {
                handleLayoutMode(layoutModeEnum.default)
                handleSidebarContent(sidebarContentEnum.default)
                handleSidebarOpened(false)
                history.push('/coupons')
                setCoupon({ id: 0 })
              } else {
                handleLayoutMode(layoutModeEnum.headerFullContent)
                handleSidebarContent(sidebarContentEnum.nav)
                handleSidebarOpened(true)
                history.push('/coupons')
                setCoupon({ id: 0 })
              }
            }}
            cartClicked={() => {
              if (sidebarOpened && sidebarContent === sidebarContentEnum.cart) {
                handleLayoutMode(layoutModeEnum.default)
                handleSidebarContent(sidebarContentEnum.default)
                handleSidebarOpened(false)
                history.push('/coupons')
                setCoupon({ id: 0 })
              } else {
                handleLayoutMode(layoutModeEnum.sideFullContent)
                handleSidebarContent(sidebarContentEnum.cart)
                handleSidebarOpened(true)
                history.push('/coupons')
                setCoupon({ id: 0 })
              }
            }}
            isSidebarOpen={sidebarOpened}
          />
        </PageContainerHeader>
        <PageContainerMain opened={sidebarOpened}>
          <>
            <Image src={storeImage} alt="" />

            <H1>{context.title}</H1>

            <BackButton className='back-button' onClick={() => {
              window.history.back()
            }}>
              <CustomIcon name='AngleLeft' />
            </BackButton>

            <H2>{newCouponSectionTitle}</H2>


						<Div>
							{!notificationError && notificationSucess &&
								<Message className="success">
									<span>Cupom cadastrado com sucesso!</span>
									<IconCheck />
								</Message>
							}

							{notificationError &&
								<Message className="error">
									<span>{notificationError.text}</span>
									<IconClose />
								</Message>
							}
   				 	</Div>
            {/* <Div>
              {notificationError ? <OldNotification message={notificationError.text} classes={notificationError.type} /> : null}

              {notificationSucess ? <OldNotification message={notificationSucess.text} classes={notificationSucess.type} /> : null}
            </Div> */}
            
            <NewCoupon
              fetchCoupons={fetchCoupons}
              setNotificationSucess={setNotificationSucess}
              setNotificationError={setNotificationError}
            />

            {coupons && coupons.length ? <H2>{registeredCouponsSectionTitle}</H2> : null}

            {coupons && coupons.length ?
              <Content data={coupons} couponId={couponId} handleCoupon={handleCoupon} /> :
              <EmptyContent data={{ ...registeredCouponsSelectedSection }} />}
          </>
        </PageContainerMain>
        <PageContainerSide opened={sidebarOpened}>
          {!isMobile && layoutMode === layoutModeEnum.sideFullContent && <CartContainer logo={storeLogo}>
            <Cart
              mode='simple'
              screenMobile={isMobile}
              setViewCards={setViewCards}
              viewCards={viewCards}
              handleFinish={() => { }}
              handleClose={() => { }}
            />
          </CartContainer>}

          {layoutMode === layoutModeEnum.headerFullContent && <Navigation
            contactUs={() => handleContactUs()}
            userName={userName}
            currentRoute='coupons'
          />}

          {viewModalDatails && <CouponOverlay
            overlay={viewModalDatails}
            setOverlay={setViewModalDatails}
            id={coupon.id}
            code={coupon.code}
            quickDescription={coupon.quickDescription}
            description={coupon.description}
            expiration={coupon.expiration}
            value={coupon.value}
            maxValue={coupon.maxValue}
            minValue={coupon.minValue}
            type={coupon.type}
            addedAt={coupon.addedAt}
            expirationAt={coupon.expirationAt}
            pickupValue={coupon.pickupValue}
            pickupMaxValue={coupon.pickupMaxValue}
            pickupMinValue={coupon.pickupMinValue}
            pickupType={coupon.pickupType}
            isMobile={isMobile}
          />}
        </PageContainerSide>
      </PageContainer>}
    </Wrapper>
  )
}

CouponsPage.propTypes = {
  accessToken: PropTypes.string,
  addressDelivery: PropTypes.string,
  cartProducts: PropTypes.arrayOf(PropTypes.shape({})),
  fetchCoupons: PropTypes.func,
  loading: PropTypes.arrayOf(PropTypes.string),
  coupon: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    quickDescription: PropTypes.string,
    description: PropTypes.string,
    expiration: PropTypes.string,
    value: PropTypes.number,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    type: PropTypes.number,
    addedAt: PropTypes.number,
    expirationAt: PropTypes.string,
    pickupValue: PropTypes.number,
    pickupMaxValue: PropTypes.number,
    pickupMinValue: PropTypes.number,
    pickupType: PropTypes.number
  }),
  couponCpf: PropTypes.string,
  setCoupon: PropTypes.func,
  theme: PropTypes.shape({
    headerImage: PropTypes.string,
    logoImage: PropTypes.string,
  }),
  address: PropTypes.string,
  userName: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      couponId: PropTypes.number
    })
  }),
  registeredCoupons: PropTypes.shape({
    coupons: PropTypes.arrayOf(PropTypes.shape({

    }))
  }),
  storeName: PropTypes.string,
  storeImg: PropTypes.string,
  modalityId: PropTypes.number,
  URLParameters: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.user.accessToken,
    addressDelivery: state.address.addressByCEP || '',
    cartProducts: (state.main.cart && state.main.cart.products) || [],
    loading: state.loading.loading || [],
    coupon: state.main.coupon || {},
    registeredCoupons: state.main.coupons || { coupons: [] },
    address: state.address.address || '',
    userAddresses: state.userAddress.userAddresses || [],
    userHistory: state.main.userHistory || {},
    userName: state.user.name || '',
    storeImg: (state.store.store && state.store.store.logo) || '',
    modalityId: state.main.modality && state.main.modality.id,
    storeName: state.store.store && state.store.store.name,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchCart: cart.fetchCart,
  fetchCoupons: coupon.fetchCoupons,
  logout,
  setCoupon: coupon.setCoupon,
  setModality
})

export const Coupons = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'coupons' })
)(CouponsPage))
