import { Grid, makeStyles, TextField } from '@material-ui/core'
import React, {
	useEffect,
	useState
} from 'react'

import { CustomLoading } from '../../components'

import {
	Wrapper,
	ContentWrapper,
	Button,
	Input,
	Paragraph,
	Form,
	Div,
	Title,
	Block
} from './styles'
import { useSnackbar } from 'react-simple-snackbar'
import { errorOptions, successOptions } from '../../../infra/utils/styleNotification'

const useStyles = makeStyles(() => ({
	input: {
		width: '100%',
		height: '50px',
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingBottom: 0,
		marginTop: 0,
		fontSize: '1rem',
		fontWeight: 500,
		"& .MuiInputBase-root": {
			height: '50px',
		},
		"& .MuiFormLabel-root": {
			//transform: `translate(14px, 16px) scale(1)`,
			color: '#CECECE',
		},
		"& .MuiInputBase-input:hover + fieldset": {
			border: `1px solid #CECECE`
		},
		"& .MuiInputBase-input:focus + fieldset": {
			border: `1px solid #CECECE`
		}
	},
	textField: {
		borderRadius: '15px'
	}
}))
export function ChangePassword(props) {
	const classes = useStyles()
	const {
		postEmailCode,
		email,
		postPassword,
		close,
		setPassword,
		SubmitLogin,
		postResendCode
	} = props

	const [loading, setLoading] = useState(false)
	const [step, setStep] = useState(1)
	const [code1, setCode1] = useState(null)
	const [code2, setCode2] = useState(null)
	const [code3, setCode3] = useState(null)
	const [code4, setCode4] = useState(null)
	const [code5, setCode5] = useState(null)
	const [code6, setCode6] = useState(null)
	const [newPassword, setNewPassword] = useState(null)
	const [checknewPassword, setCheckNewPassword] = useState(null)

	const [time, setTime] = useState(30)
	const [openSuccessSnackbar] = useSnackbar(successOptions({ modal: true }))
	const [openErrorSnackbar] = useSnackbar(errorOptions({ modal: true }))

	let timeout = null

	useEffect(() => {
		if (time > 0) {
			setTimeout(() => {
				postTime(Number(time - 1))

				if (time - 1 > 0) {
					handleTime(30, time - 1)
				}
			}, 1000)
		}
		return () => { }
	}, [time])

	function postTime(args) {
		setTime(args)
	}

	function handleTime(startTime, time = 0, data = {}) {
		if (data.clear) {
			postTime(30, 30)
			clearTimeout(timeout)

			return
		}

		timeout = setTimeout(() => {
			postTime(Number(time - 1))

			if (time - 1 > 0) {
				handleTime(startTime, time - 1)
			}
		}, 1000)
	}
	async function handleResendCodeSubmit() {
		const requestData = {
			email: email
		}
		await postResendCode([{
			name: 'code',
			type: 'payload',
			data: requestData
		}, {
			name: 'code',
			type: 'error',
			callback: () => {
				const notificationMessage = 'Não foi possível reenviar o código.'
				openErrorSnackbar(notificationMessage)
			}
		}, {
			name: 'code',
			type: 'success',
			callback: () => {
				setTime(30)
			}
		}])
	}

	async function handleCodeSubmit() {
		setLoading(true)

		if (!code1 || !code2 || !code3 || !code4 || !code5 || !code6) {
			openErrorSnackbar('É necessário preencher o código corretamente!')
			setLoading(false)
			return
		}

		const requestData = {
			email: email,
			code: `${code1}${code2}${code3}${code4}${code5}${code6}`
		}

		await postEmailCode([{
			name: 'code',
			type: 'payload',
			data: requestData
		}, {
			name: 'code',
			type: 'error',
			callback: () => {
				const notificationMessage = 'Não foi possível validar o código para seu email.'
				openErrorSnackbar(notificationMessage)
				setLoading(false)
			}
		}, {
			name: 'code',
			type: 'success',
			callback: () => {
				setStep(2)
				setLoading(false)
			}
		}])
	}

	async function handlePasswordSubmit() {
		setLoading(true)

		if (!newPassword) {
			openErrorSnackbar('É necessário preencher o campo "senha"!')
			setLoading(false)
			return
		}

		if (!checknewPassword) {
			openErrorSnackbar('É necessário preencher o campo "Confirmar senha"!')
			setLoading(false)
			return
		}

		if (newPassword !== checknewPassword) {
			openErrorSnackbar('As senhas são diferentes! Tente novamente.')
			setLoading(false)
			return
		}

		const requestData = {
			email: email,
			password: newPassword
		}

		await postPassword([{
			name: 'code',
			type: 'payload',
			data: requestData
		}, {
			name: 'code',
			type: 'error',
			callback: () => {
				const notificationMessage = 'Não foi possível atualizar a sua senha. Tente mais tarde.'
				openErrorSnackbar(notificationMessage)
				setLoading(false)
			}
		}, {
			name: 'code',
			type: 'success',
			callback: () => {
				openSuccessSnackbar('Senha atualizada com sucesso!', 2000)

				setTime(() => {
					setPassword(newPassword)
					SubmitLogin()
					close()
				}, 5000)
			}
		}])
	}

	const handleNextInput = ({ value, id }) => {
		let next = null
		if (value && value.length === 1) {
			if (id === 'code1') next = 'code2'
			else if (id === 'code2') next = 'code3'
			else if (id === 'code3') next = 'code4'
			else if (id === 'code4') next = 'code5'
			else if (id === 'code5') next = 'code6'
			const el = document.getElementById(next);
			el.focus();
		}
		if (!value) {
			if (id === 'code6') next = 'code5'
			else if (id === 'code5') next = 'code4'
			else if (id === 'code4') next = 'code3'
			else if (id === 'code3') next = 'code2'
			else if (id === 'code2') next = 'code1'
			const el = document.getElementById(next);
			el.focus();
		}
	}
	return <Wrapper id='sign-up-phone'>
		<ContentWrapper id='sign-up-content'>
			<Form id='sign-up-phone-form' method='post' onSubmit={(event) => {
				event.preventDefault()
			}}>

				<Title>Validação de segurança</Title>
				{step === 1 ? <>
					<Paragraph>Insira o código de confirmação enviado no e-mail cadastrado, por favor. Esta etapa é necessária apenas uma vez.</Paragraph>
					<Div>
						<Grid container spacing={2}>
							<Grid item xs={2}>
								<Input id="code1" maxLength={1} name='code' placeholder='' value={code1}
									onKeyDown={(event) => {
										const { keyCode } = event

										if (!(
											(keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) ||
											keyCode === 8 ||
											keyCode === 9 ||
											keyCode === 46 ||
											keyCode === 37 ||
											keyCode === 39)) {
											event.preventDefault()
										}
									}} onChange={(e) => {
										if (e.target.value) {
											handleNextInput({ value: e.target.value, id: e.target.id })
										}
										setCode1(e.target.value)
									}

									} />
							</Grid>
							<Grid item xs={2}>
								<Input id="code2" maxLength={1} name='code' placeholder='' value={code2} onKeyDown={(event) => {
									const { keyCode } = event

									if (!(
										(keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) ||
										keyCode === 8 ||
										keyCode === 9 ||
										keyCode === 46 ||
										keyCode === 37 ||
										keyCode === 39)) {
										event.preventDefault()
									}
								}} onChange={(e) => {
									handleNextInput({ value: e.target.value, id: e.target.id })
									setCode2(e.target.value)
								}} />
							</Grid>
							<Grid item xs={2}>
								<Input id="code3" maxLength={1} name='code' placeholder='' value={code3} onKeyDown={(event) => {
									const { keyCode } = event

									if (!(
										(keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) ||
										keyCode === 8 ||
										keyCode === 9 ||
										keyCode === 46 ||
										keyCode === 37 ||
										keyCode === 39)) {
										event.preventDefault()
									}
								}} onChange={(e) => {
									handleNextInput({ value: e.target.value, id: e.target.id })
									setCode3(e.target.value)
								}} />
							</Grid>
							<Grid item xs={2}>
								<Input id="code4" maxLength={1} name='code' placeholder='' value={code4} onKeyDown={(event) => {
									const { keyCode } = event

									if (!(
										(keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) ||
										keyCode === 8 ||
										keyCode === 9 ||
										keyCode === 46 ||
										keyCode === 37 ||
										keyCode === 39)) {
										event.preventDefault()
									}
								}} onChange={(e) => {
									handleNextInput({ value: e.target.value, id: e.target.id })
									setCode4(e.target.value)
								}} />
							</Grid>
							<Grid item xs={2}>
								<Input id="code5" maxLength={1} name='code' placeholder='' value={code5} onKeyDown={(event) => {
									const { keyCode } = event

									if (!(
										(keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) ||
										keyCode === 8 ||
										keyCode === 9 ||
										keyCode === 46 ||
										keyCode === 37 ||
										keyCode === 39)) {
										event.preventDefault()
									}
								}} onChange={(e) => {
									handleNextInput({ value: e.target.value, id: e.target.id })
									setCode5(e.target.value)
								}} />
							</Grid>
							<Grid item xs={2}>
								<Input id="code6" maxLength={1} name='code' placeholder='' value={code6} onKeyDown={(event) => {
									const { keyCode } = event

									if (!(
										(keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) ||
										keyCode === 8 ||
										keyCode === 9 ||
										keyCode === 46 ||
										keyCode === 37 ||
										keyCode === 39)) {
										event.preventDefault()
									}
								}} onChange={(e) => {
									if (!e.target.value) {
										handleNextInput({ value: e.target.value, id: e.target.id })
									}
									setCode6(e.target.value)
								}} />
							</Grid>
						</Grid>
					</Div>

					<Button onClick={() => {
						handleCodeSubmit()
					}}>{loading ?
						<CustomLoading
							color={'#fff'}
							type={'spin'}
							id='default-loading'
							height={30}
							width={30} /> :
						'Ok'}</Button>

					<Button className='no-highlight' disabled={time > 0 ? true : false} onClick={async (event) => {
						event.preventDefault()

						if (time > 0) {
							return
						}

						handleResendCodeSubmit()
					}
					}>Reenviar código{time > 0 ? `: ${time}` : ''}</Button>
				</> :
					step === 2 ? <>
						<Div>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										id="password"
										label="Senha"
										variant="outlined"
										className={classes.input}
										type='password'
										value={newPassword}
										onChange={(e) => {
											setNewPassword(e.target.value)
											setPassword(e.target.value)
										}}
										InputProps={{
											className: classes.textField,
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="check-password"
										label="Confirmar senha"
										variant="outlined"
										className={classes.input}
										type='password'
										value={checknewPassword}
										onChange={(e) => setCheckNewPassword(e.target.value)}
										InputProps={{
											className: classes.textField,
										}}
									/>
								</Grid>
							</Grid>

							<Button
								disabled={!newPassword || !checknewPassword || (newPassword !== checknewPassword) ? true : false}
								onClick={() => { handlePasswordSubmit() }}
								className={!newPassword || !checknewPassword || (newPassword !== checknewPassword) ? 'disabled' : ''}
							>
								{loading ?
									<CustomLoading
										color={'#fff'}
										type={'spin'}
										id='default-loading'
										height={30}
										width={30} /> :
									'Confirmar'}
							</Button>
						</Div>
					</> : null}
			</Form>
			<Div className="footer">
				<Block className={step === 1 ? 'active' : ''} />
				<Block className={step === 2 ? 'active' : ''} />
			</Div>
		</ContentWrapper>
	</Wrapper>
}
