export default ({
  apiService
}) => {
  const strategy = {
    fetch: async ({
      data,
      auth,
      dispatch,
      actions,
      maps,
    }) => {
      const {
        setLoading
      } = actions

      const {
        address,
        loading
      } = data

      const {
        mapUserAddressesList
      } = maps

      if (loading && loading.length && loading.indexOf('user-addresses') > -1) {
        return false
      }

      dispatch(setLoading('user-addresses'))

      const url = `enderecosdelivery/listar`
      const result = await apiService.get(url, auth)
      const mappedResult = mapUserAddressesList(result.data)

      if (mappedResult && mappedResult.length) {
        return mappedResult
      }

      const filteredAddressById = mappedResult.filter((filteredItem) => filteredItem.id === address.id)
      const filteredAddresses = mappedResult.filter((filteredItem) => filteredItem.id !== address.id)

      if (filteredAddressById[0] && mappedResult && mappedResult.length && filteredAddresses && filteredAddresses.length) {
        const newFilteredAddresses = [filteredAddressById[0], ...filteredAddresses]

        return newFilteredAddresses
      }

      return []
    },

		add: async ({
      auth,
      data,
      maps
    }) => {
      const {
        address
      } = data

      const {
				mapUserAddressCreateRequest,
        mapUserAddressCreateData
      } = maps
  
      const url = `enderecosdelivery/cadastrar`
      const result = await apiService.post(url, mapUserAddressCreateRequest(address || {}), auth)
      const mappedResult = mapUserAddressCreateData(result.data)
  
      return mappedResult
    },

		delete: async ({
      actions,
			auth,
      data,
      dispatch,
      maps
    }) => {
      const {
        setLoading
      } = actions

      const {
        id,
				loading
      } = data

      const {
        mapUserAddressDeleteData
      } = maps

      if (loading && loading.includes('delete-user-address')) {
        return false
      }

      dispatch(setLoading('delete-user-address'))
  
      const url = `enderecosdelivery/deletar/${id}`
      const result = await apiService.post(url, null, auth)
      const mappedResult = mapUserAddressDeleteData(result.data)
  
      return mappedResult
    }
  }

  return {
    fetch: async (payload) => {
      return await strategy['fetch'](payload)
    },

		add: async (payload) => {
      return await strategy['add'](payload)
    },

		update: async (payload) => {
      return await strategy['update'](payload)
    },

		delete: async (payload) => {
      return await strategy['delete'](payload)
    }
  }
}
