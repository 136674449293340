import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-redux'

import { ReactComponent as IconNavigation } from '../../assets/icons/icon_navigation.svg'
import { ReactComponent as IconStoreList } from '../../assets/icons/icon_store_list.svg'
import { ReactComponent as IconStoreAlter } from '../../assets/icons/icon_store_alter.svg'

import history from '../../../history'

import {
	Button
} from '../../components'

import {
	LeftWrapper,
	RightWrapper,
	Wrapper,
	CenterWrapper,
} from './styles'
import { MULTI_STORE } from '../../../config'
import { ModalityMobile } from '../ModalityMobile'

export function Mobile(props) {
	const {
		setVisibleNavigation,
		setVisibleStoreList,
		modalityId,
		storeDelivery,
		storePickup,
		opened,
		context,
		handleModality,
		handleGTM,
		handlePixel,
		storeName
	} = props

	const store = useStore()

	const { content } = context

	const address = store.getState().address.address || {}
	const accessToken = store.getState().user.accessToken || null
	const URLParameters = store.getState().main.URLParameters || ''

	return (
		<Wrapper>
			<LeftWrapper>
				{address && address.main && MULTI_STORE === 'true' ? <Button onClick={() => {
					setVisibleStoreList(true)
				}}>
					<IconStoreList className="home" />
					<IconStoreAlter />
				</Button> : <></>}
			</LeftWrapper>
			<CenterWrapper>
				{modalityId && <ModalityMobile
					modalityId={modalityId}
					storeDelivery={storeDelivery}
					opened={opened}
					storePickup={storePickup}
					handleModality={handleModality}
					handleGTM={handleGTM}
					handlePixel={handlePixel}
					content={content}
					storeName={storeName}
				/>}
			</CenterWrapper>
			<RightWrapper>
				<IconNavigation onClick={() => {
					if (!accessToken) {
						history.push(`/login${URLParameters}`)

						return
					}

					setVisibleNavigation(true)
				}} />
			</RightWrapper>
		</Wrapper>
	)
}

Mobile.propTypes = {
	setVisibleNavigation: PropTypes.func,
	setVisibleStoreList: PropTypes.func
}
