export default ({
	TITLE,
	DESCRIPTION,
	URL
}) => {
  const strategy = {
    default: async (payload) => {
      return {
				title: TITLE,
				description: DESCRIPTION,
				url: URL
			}
    }
  }

  return {
    get: async (payload) => {
      return strategy['default'](payload)
    }
  }
}
