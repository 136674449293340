import React from 'react'

import {
  Details,
  Summary
} from './'

export function Pickup(props) {
  const {
    userName,
    cpf,
    cardBrand,
    cardNumber,
    deliveryTime,
    hasCpf,
    status,
    date,
    products,
    requestNumber,
    storeAddress,
    deliveryAddress,
    storeName,
    logoStore,
    phone,
    whattsapp,
    earnedPoints,
    scheduling
  } = props

  return (
    <>
      <Details
        userName={userName}
        deliveryTime={deliveryTime}
        storeAddress={storeAddress}
        date={date}
        cardBrand={cardBrand}
        cardNumber={cardNumber}
        cpf={cpf}
        hasCpf={hasCpf}
				requestNumber={requestNumber}
        earnedPoints={earnedPoints}
        scheduling={scheduling}
      />
      <Summary
        storeAddress={storeAddress}
        deliveryAddress={deliveryAddress}
        cardBrand={cardBrand}
        cardNumber={cardNumber}
        cpf={cpf}
        hasCpf={hasCpf}
        storeName={storeName}
        storeLogo={logoStore}
        products={products}
        status={status}
        requestNumber={requestNumber}
        phone={phone}
        whattsapp={whattsapp}
        scheduling={scheduling}
      />
    </>
  )
}
