import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;

  > p {
    margin-bottom: .938rem;
  }
`

export const ImageLogo = styled.img``

export const H1 = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  padding-left: 1.25rem;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
`

export const StoreStatusWrapper = styled.p`
  color: ${props => {
    if (props.status === 1) {
      return '#19BF2B'
    }

    if (props.status === 2) {
      return '#FF0000'
    }

    if (props.status === 3) {
      return '#9B9B9B'
    }

    if (props.status === 4) {
      return '#FF9900'
    }

    if (props.status === 5 || props.status === 6) {
      return '#FF9900'
    }

    return '#000'

  }};
  text-transform: uppercase;
  font-size: .688rem;
  margin: 0;
  padding: 0;
  padding-left: 1.25rem;
`

export const Background = styled.div`
  position: relative;
  margin-bottom: 1.563rem;
  min-width: 100%;
  max-width: 100%;

  > img {
    width: 100%;
    transform: scale(1);

    &.logo {
      width: 4.063rem;
      height: auto;
      position: absolute;
      right: 1.875rem;
      top: 100%;
      transform: translate(0, -50%);
      border-radius: 50%;
    }
  }
`

export const Div = styled.div``

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
`

export const DetailedStoreHeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  position: relative;
`
export const HeaderNavigation = styled.div`
  backgroung: #fff;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  z-index: 2;
  background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundHeader) || '#333'};;

  svg {
    width: 2.5rem;
    height: 2.5rem;

    &.none{
      display:none;
    }

    path {
      stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#333'};
    }
    
    &:first-of-type {
      width: .85rem;
      height: 1.25rem;
    }
  }

  @media (min-width: 60rem) {
    display: none;
  }
`

export const BackButton = styled.span`
  cursor: pointer;
`

export const HeaderBanner = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};

  img, svg {
    width: 100%;
    height: auto;
    display: block;
  }
`

export const HeaderBannerMobile = styled.div`
  width: 100%;
  height: 8.563rem
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};

  img, svg {
    width: 100%;
    height: auto;
		min-height: 100%;
		max-height: 100%;
    display: block;
  }
`

export const HeaderStore = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const StoreInfosContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: 60rem) {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  @media (min-width: 60rem) {
    border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  }
`

export const StoreIcon = styled.div`
  position: absolute;
  max-width: 4rem;
  height: 4rem;
  //background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -2.5rem;
  right: 1.5rem;

  img, svg {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }

  @media (min-width: 60rem) {
    position: unset;
    margin: 0 1rem 0 3rem;
    max-width: 6rem;
    height: 6rem;
  }

  @media (min-width: 60rem) {
    flex: 1;    
  }
`

export const StoreDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1rem 0rem 1rem;

  >div{
    &.app{
          display: flex;
    justify-content: space-between;
    }
  }

  @media (min-width: 60rem) {
    padding: 1.5rem;
    flex: 1;

    > p {
      margin-bottom: 0.5rem;
    }
  }
`

export const ButtonApp = styled.div`
  cursor: pointer;
  padding: .4rem .8rem;
  background: none;
  font-size: .8rem;
  border: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
  display: flex;
  align-items: center;
  border-radius: 8px;

  svg {
    margin-left: 8px;
    path {
      fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    }
  }
`

export const StoreName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  h1 {
    margin: 0 1rem 0 0;
    font-size: 1.5rem;
    font-weight: 700;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.titleColor) || '#000'};
  }

  img, svg {
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;

    path {
      fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.titleColor) || '#333'};
    }

    g {
      stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.titleColor) || '#333'};
    }
  }
`

export const StoreStatus = styled.p`
  font-size: .875rem;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 1rem;

  &.store-status--open {
    color: #19BF2B;   
  }

  &.store-status--close {
    color: #FF0000;   
  }

  &.store-status--unavailable {
    color: #9B9B9B;   
  }

  &.store-status--no-communication {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};   
  }

  &.store-status--temporarily-closed {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};   
  }
`

export const BoxFreeShipping = styled.div`

 & span {
	font-family: 'Roboto';
	display: inline-block;
	flex-direction: row;
	border: 1px solid #007141;
	color: #007141;
	border-radius: 4px;
	background: #fff;
	font-size: 12px;
	padding: 2px 2px;
	margin-bottom: 8px;
	
	& .icon {
		width: 22px;
		height: 17px;
		margin-right: 5px;
	}	
 }
`;


export const StoreCost = styled.div`
  display: flex;
  flex: 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 7rem;
  min-width: 6rem;
	justify-content: center;

  &.fee {
    svg {
      path {
        fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
      }
    }
  }

  &.minimum-value{
    svg {
      path {
        fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
      }
    }
  }

  &.time {
    svg {
      path {
        fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
      }
      g {
        stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
      }
      path {
        stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
      }
    }
  }

  h3 {
    margin: 0;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.titleColor) || '#000'};
    font-size: .7rem;
    font-weight: 500;
    text-align: start;
    flex: 1;
    font-weight: bold;

    @media (min-width: 60rem) {
      position: relative;
      margin-top: -1.3rem;
    }
  }

  p {
    width: 100%;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.titleColor) || '#CCC'};
    font-size: 1rem;
    margin: .3rem 0 0 0;

    @media (min-width: 60rem) {
      padding-left: 1.7rem;
      position: relative;
      margin-top: -1.6rem;
    }
    @media (max-width: 60rem) {
      height:100%;
    }
  }

  @media (min-width: 25rem) {
    min-width: 7rem;
  }

  @media (min-width: 50rem) {
    max-width: unset;
    min-width: 9.5rem;
  
    h3 {
      font-size: .9rem;
    }

    &.fee {
      min-width: 9.5rem;
    }

    &.time {
      min-width: 11rem;
    }
  }
`
export const AppDownload = styled.div`
  min-width: 190px;
  
  @media (max-width: 40rem) {
    display:none;
  }
`

export const StoreCosts = styled.div`
  display: flex;

  ${StoreCost}:not(:first-of-type) {
    padding-left: .5rem;
    border-left: .15rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#CCC'};
  }

  ${StoreCost}:not(:last-of-type) {
    padding-right: .5rem;
  }

  @media (min-width: 60rem) {
    margin: 0;

    ${StoreCost}:not(:first-of-type) {
      padding-left: 1rem;
    }
  }

	@media (max-width: 60rem) {
		justify-content: space-around;
  }
`

export const CostIconContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  margin: 0 .25rem 0 0;
  min-width: 1.2rem;
  max-width: 1.2rem;
  align-items: center;

  @media (min-width: 20rem) {
    margin: 0 .6rem 0 0;
  }

  @media (min-width: 60rem) {
    min-height: 3rem;
  }
`
export const ModalityContainer = styled.div`
  margin: 0;
  display: flex;
  border: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'};
  background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#000'};
  border-radius: .5rem;

  &.btn-store {
    border-radius: 1rem;

    > button {
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'};
      padding: 8px 3px 8px 8px;
      cursor: pointer;
      float: left;
      font-weight: bold;
      border-radius: 1rem;
      font-size: .8rem;
  
      svg {
        width: 1.5rem;
        height: 1.1rem;
        margin: 0 .2rem;
        path {
          fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
          stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        }
      }
    }
  }

  &.btn-group button {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'};
    padding: 5px 15px;
    cursor: pointer;
    font-weight: bold;
    border-radius: .5rem;
    font-size: .8rem;

    &.active{
      background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#000'};
      border-radius: .5rem;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
      box-shadow:2px 2px 9px 1px;
      z-index: 1;
    }
  }
`

export const Button = styled.button``

export const ButtonDisable = styled.button`
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'};
    padding: 9px 4px 9px 9px;
    float: left;
    font-weight: bold;
    font-size: .8rem;
    width: 30px;
    background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundHeader) || '#000'};
`
