import styled from 'styled-components'

import { DefaultImage } from '../Image'

const Image = styled(DefaultImage)`
  width: 6.25rem;
  height: auto;
`

const Wrapper = styled.div`
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export {
  Image,
  Wrapper
}
