export default {
	NEW_CART: 'NEW_CART',
	UPDATE_CART: 'UPDATE_CART',
	CLEAN_CART: 'CLEAN_CART',
	SET_OPENED: 'SET_OPENED',
	SET_COUPON: 'SET_COUPON',
	SET_SIDE_MODE: 'SET_SIDE_MODE',
	SET_CART_COUPON: 'SET_CART_COUPON',
	SET_COUPON_ABOVE: 'SET_COUPON_ABOVE'
}
