import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  position: relative;

  > button {
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#333' };
    position: absolute;
    right: .938rem;
    top: 0;
    padding: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
`

export const Input = styled.input`
  background: none;
  height: 2.5rem;
  font-weight: normal;
  font-size: .75rem;
  line-height: 1rem;
  border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#DDD' } .063rem solid;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#333' };
  padding: 0 .938rem;
  border-radius: 1rem;
  flex: 1;

  ::placeholder {
    font-weight: normal;
    font-size: .75rem;
    line-height: 1rem;
    color: #AEAEAE;
  }
`

export const Button = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
`
