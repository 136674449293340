export default ({
  signUpRepository,
  maps,
  auth
}) => {
  return async (payload) => {
    const {
      onSuccess,
      onError,
      data,
      actions,
      dispatch
    } = payload

    try {
      const signUp = await signUpRepository.signUpEmail({
        data,
        actions,
        maps,
        dispatch,
        auth: auth()
      })

      onSuccess({ signUp })
    } catch(e) {
      console.log(e)

      onError()
    }
  }
}
