import React, {
	useEffect,
	useState,
	useCallback
} from 'react'
import { connect } from 'react-redux'

import { ReactComponent as DeleteIcon } from '../assets/icons/icon_delete.svg'
import { ReactComponent as NoCards } from '../assets/seara/icone_cartao.svg'

import {
	pipe,
	GetContent
} from '../../domain/helpers'

import {
	getCardBrand,
	getCardBrandImageById,
	onlyNumbers
} from '../../infra/utils'

import { CARD_BRANDS_TAG } from '../../domain/enum/payment'

import {
	setCard,
	fetchCards,
	setUserCard,
	postCard,
	addTicketCard,
	setCVV,
	setCardName,
	setCardNumber,
	setCardDate,
	setCardBirth,
	setCardBrandId,
	setCardCPF,
	setCardCVV,
	deleteCard,
	setThing,
	setUserHistory,
	handleGTM
} from '../../redux/actions/main'

import history from '../../history'

import { loading } from '../../state'

import {
	Button,
	CustomCheckbox,
	CustomLoading,
	Notification as OldNotification,
	CustomModalNotification
} from '../components'

import { useDeviceLayout, usePhoneValidation } from '../hooks'

import {
	Notification
} from '../'

import { NewCard } from './'

import {
	Wrapper,
	ContentWrapper,
	H1,
	H4,
	PaymentMethodContainer,
	PaymentMethodHeader,
	PaymentMethodItems,
	PaymentMethodItem,
	PaymentMethodItemContent,
	PaymentMethodItemActions,
	BrandName,
	BrandImage,
	ActionContainer,
	Paragraph,
	NoCardIcon,
	LoadingContainer,
	Content,
	HeaderWrapper,
	BrandBlocked,
} from './styles'
import { Box } from '@material-ui/core'

export const Context = React.createContext({})

export function CardsPage(props) {
	const {
		accessToken,
		loading,
		setLoading,
		card,
		userCards,
		userCard,
		setCard,
		fetchCards,
		setUserCard,
		postCard,
		addTicketCard,
		setCardName,
		setCardNumber,
		setCardDate,
		setCardBirth,
		setCardBrandId,
		setCardCPF,
		setCardCVV,
		deleteCard,
		setThing,
		setViewCards,
		screenMobile,
		handleCloseDialogCards,
		payment,
		setUserHistory,
		handleNewCard,
		newCard,
		handleGTM,
		URLParameters,
		login
	} = props

	const {
		name: cardName,
		number: cardNumber,
		date: cardDate,
		CPF: cardCPF,
		CVV: cardCVV,
		birth: cardBirth
	} = card


	const isMobile = useDeviceLayout({
		isMobile: true
	})

	const {
		isValidPhone,
		showPhoneValidation,
	} = usePhoneValidation()

	const [onlineCards, setOnlineCards] = useState([])
	const [offlineCards, setOfflineCards] = useState([])
	const [deleteModalCard, setDeleteModalCard] = useState(false)
	const [visibleThing, setVisibleThing] = useState(false)
	const [thingValue, setThingValue] = useState('')
	const [notificationCreate, setNotificationCreate] = useState(null)
	const [notificationDelete, setNotificationDelete] = useState(null)
	const [selectRemoveCard, setSelectRemoveCard] = useState(null)
	const [cardBrandTicket, setCardBrandTicket] = useState(null)
	const [selectCardBrandId, setSelectCardBrandId] = useState(null)

	const postUserCard = useCallback(function postUserCard(args) {
		setUserCard(args)
	}, [setUserCard])

	useEffect(() => {
		setUserCard(null)
	}, [
		setUserCard,
		handleGTM
	])

	function handleAddNewCard(data) {
		if ((!isValidPhone) && login?.subscriptionStatus !== undefined) {
			showPhoneValidation(isMobile)
			return;
		}

		handleNewCard(data)
	}

	function handleOpenDialogThing() {
		setVisibleThing(true)
		setThingValue('')
	}

	function handleCloseDialogDeleteCard() {
		setDeleteModalCard(false)
	}

	function handleCloseDialogThing() {
		setVisibleThing(false)
	}

	function handleOpenModal() {
		setDeleteModalCard(true)
	}

	function handleCard({ selectCard, isOffline = false }) {
		if (selectCard.brand === 'Dinheiro') {
			handleOpenDialogThing()
		}

		postUserCard({
			...card,
			...selectCard,
			isOfflinePayment: isOffline
		})

		if (isMobile && selectCard.brand !== 'Dinheiro') {
			setTimeout(() => {
				history.push(`/cart${URLParameters}`)
			}, 1000)
		}
	}

	async function handleDeleteCard(card) {
		const result = await deleteCard(card)
		if (result && result.success) {
			setSelectRemoveCard(null)
			handleCloseDialogDeleteCard()
			fetchCards()
			setNotificationDelete({
				type: 'positive',
				text: 'O cartão foi removido com sucesso!'
			})
			setTimeout(() => {
				setNotificationDelete(null)
			}, 7500)
		} else {
			setSelectRemoveCard(null)
			handleCloseDialogDeleteCard()
			setNotificationDelete({
				type: 'negative',
				text: 'Não foi posivel remover o cartão! Falha no serviço.'
			})
			setTimeout(() => {
				setNotificationDelete(null)
			}, 7500)
		}
	}

	async function handleAddCard() {
		let result
		if (cardBrandTicket) {
			result = await addTicketCard()
		} else {
			result = await postCard()
		}

		if (result && result.success) {
			setCard(null)
			setCardBrandTicket(false)
			const result = await fetchCards();
			if (result.success) {
				const [firstCard] = result?.availableOnlineCards || []
				setUserCard(firstCard)
			}

			handleAddNewCard(false)

			if (window.location.pathname !== '/my-cards' && !isMobile) {
				history.push(`/order-confirmation${URLParameters}`)
			}

			if (window.location.pathname === '/cards' && isMobile) {
				history.push(`/cart${URLParameters}`)
			}

			setNotificationCreate({
				type: 'positive',
				text: 'O cartão foi cadastrado com sucesso e já pode ser utilizado!'
			})
			setTimeout(() => {
				setNotificationCreate(null)
			}, 7500)
		} else {
			setNotificationCreate({
				type: 'negative',
				text: 'Falha ao cadastrar o cartão! Verifique os dados.'
			})
			setTimeout(() => {
				setNotificationCreate(null)
			}, 7500)
		}
	}

	function handleCardNumber(args) {
		postCardNumber(args)
	}

	function postCardNumber(args) {
		setCardNumber(args)
	}

	function handleCardDate(args) {
		postCardDate(args)
	}

	function postCardDate(args) {
		setCardDate(args)
	}

	function handleCardBirth(args) {
		postCardBirth(args)
	}

	function postCardBirth(args) {
		setCardBirth(args)
	}

	function handleCardName(args) {
		postCardName(args)
	}

	function postCardName(args) {
		setCardName(args)
	}

	function handleCardCPF(args) {
		postCardCPF(args)
	}

	function postCardCPF(args) {
		setCardCPF(args)
	}

	function handleCardBrandId(args) {
		const brandIdSelected = getCardBrand(onlyNumbers(args)) || ''
		const nameBrand = CARD_BRANDS_TAG[brandIdSelected.id] || ''

		if (nameBrand && nameBrand === 'ticket') {
			setCardBrandTicket(true)
		} else {
			setCardBrandTicket(false)
		}

		setSelectCardBrandId(getCardBrandImageById(brandIdSelected.id))
		postCardBrandId(args)
	}

	function postCardBrandId(args) {
		setCardBrandId(args)
	}

	function handleCardCVV(args) {
		postCardCVV(args)
	}

	function postCardCVV(args) {
		setCardCVV(args)
	}

	const handleThingSelect = () => {
		setThing(thingValue)
		handleCloseDialogThing()

		if (isMobile) {
			setTimeout(() => {
				history.push(`/cart${URLParameters}`)
			}, 1000)
		}
	}

	useEffect(() => {
		setLoading('cards')

		if (!accessToken) {
			if (isMobile) {
				history.push(`/login${URLParameters}`)

				return
			}

			if (setViewCards) {
				setViewCards(false)
			}
		}

		if (!accessToken && screenMobile) {
			handleCloseDialogCards()
		}

		if (accessToken) {
			fetchCards()
		} else {
			fetchCards([])
		}

		setThingValue('')

		setTimeout(() => {
			setLoading('')
		}, 1000)

	}, [
		setViewCards,
		accessToken,
		setLoading,
		fetchCards,
		handleCloseDialogCards,
		isMobile,
		screenMobile,
		URLParameters
	])

	useEffect(() => {
		if (userCards.availableOnlineCards && userCards.availableOnlineCards.length > 0) {
			setOnlineCards(userCards.availableOnlineCards)
		}

		if (userCards.availableOfflineCards && userCards.availableOfflineCards.length > 0) {
			setOfflineCards(userCards.availableOfflineCards)
		}

	}, [
		userCards,
		setLoading
	])

	return <Wrapper id='cards'>
		<HeaderWrapper>
			<H1>Meus cartões</H1>
			{!newCard && <Button onClick={() => {
				if (!newCard) {
					handleAddNewCard(true)
				} else {
					handleAddNewCard(false)
				}
			}}>
				Adicionar Cartão
			</Button>}
		</HeaderWrapper>
		{loading.includes('cards') &&
			<LoadingContainer>
				<CustomLoading
					type={'spin'}
					id='default-loading'
					height={40}
					width={40}
				/>
			</LoadingContainer>}

		{!loading.includes('cards') && <>
			<ContentWrapper id='cards-content' className={true ? 'motion' : ''}>
				<Notification id='cards-notification' />
				{notificationDelete ? <OldNotification message={notificationDelete.text} classes={notificationDelete.type} /> : null}

				{notificationCreate ? <OldNotification message={notificationCreate.text} classes={notificationCreate.type} /> : null}

				{!newCard && onlineCards && onlineCards.length > 0 &&
					<>
						<PaymentMethodHeader>
							<h3>PAGAMENTO ONLINE</h3>
						</PaymentMethodHeader>
						<PaymentMethodContainer>
							<PaymentMethodItems>
								{onlineCards && onlineCards.map((card, index) => {
									const key = `cards-item-${index}-${card.id}`

									return <PaymentMethodItem key={key}>
										<PaymentMethodItemContent>
											<BrandImage
												src={getCardBrandImageById(card.brandId)}
												alt={`Bandeira do cartao: ${getCardBrandImageById(card.brand)}`}
											/>
											<Box style={{ display: 'flex', flexDirection: 'column', opacity: card.blocked ? .6 : 1 }}>
												<BrandName>{card.data}</BrandName>
												{card.blocked ? <BrandBlocked>Bandeira não aceita nessa loja</BrandBlocked> : ''}
											</Box>
										</PaymentMethodItemContent>
										<PaymentMethodItemActions>
											{(payment && !card.blocked) && <CustomCheckbox
												onClick={() => handleCard({ selectCard: card, isOffline: false })}
												type='radio'
												name='field'
												disabled={card.blocked}
												checked={userCard?.data && userCard?.id === card.id}

											></CustomCheckbox>}
											<DeleteIcon onClick={() => {
												setSelectRemoveCard(card)
												handleOpenModal()
											}} />
										</PaymentMethodItemActions>
									</PaymentMethodItem>
								})}

								<CustomModalNotification
									openModal={deleteModalCard}
									title={'Excluir Cartão'}
									content={'Você tem certeza que deseja excluir esse cartão?'}
									handleCloseModal={handleCloseDialogDeleteCard}
									size={'xs'}
									fullWidth={true}
									actions={[{
										label: 'Sim',
										classes: 'primary',
										handleClick: () => {
											handleDeleteCard(selectRemoveCard)
										}
									}, {
										label: 'Não',
										classes: 'secondary',
										handleClick: () => {
											handleCloseDialogDeleteCard(false)
										}
									}]}
								/>
							</PaymentMethodItems>
						</PaymentMethodContainer>

						<CustomModalNotification
							openModal={visibleThing}
							title={'Precisa de Troco?'}
							content={'Se precisar de troco informe abaixo o valor que vai pagar para levarmos o seu troco.'}
							handleCloseModal={handleCloseDialogThing}
							size={'xs'}
							fullWidth={true}
							input={true}
							inputValue={thingValue}
							setInputValue={setThingValue}
							inputType="number"
							inputPlaceholder="R$ 0,00"
							actions={[{
								label: 'Confirmar',
								classes: 'primary',
								handleClick: () => {
									handleThingSelect()
								}
							}, {
								label: 'Não Preciso de Troco!',
								classes: 'secondary',
								handleClick: () => {
									handleCloseDialogThing(false)
									setThingValue('')
								}
							}]}
						/>
					</>
				}

				{!newCard && <ActionContainer>
					<Button onClick={() => {
						if (!newCard) {
							handleAddNewCard(true)
						} else {
							handleAddNewCard(false)
						}
					}}>
						Adicionar Cartão
					</Button>
				</ActionContainer>}

				{!newCard && ((onlineCards && onlineCards.length <= 0) && (offlineCards && offlineCards.length <= 0)) && <>
					<Content>
						<NoCardIcon>
							<NoCards />
						</NoCardIcon>

						<H4>Você ainda não possui nenhum cartão cadastrado!</H4>

						<Paragraph>Adicione seu primeiro meio de pagamento! :)</Paragraph>
					</Content>
				</>}

				{!!newCard && <>
					<ActionContainer className={`top`}>
						<Button onClick={() => {
							if (!newCard) {
								setUserHistory({
									back: 'my-cards'
								})
							} else {
								handleAddNewCard(false)
							}
						}}>
							Cancelar
						</Button>
					</ActionContainer>

					<NewCard
						number={cardNumber}
						name={cardName}
						date={cardDate}
						CPF={cardCPF}
						CVV={cardCVV}
						birth={cardBirth}

						handleNumber={handleCardNumber}
						handleName={handleCardName}
						handleDate={handleCardDate}
						handleCPF={handleCardCPF}
						handleBrandId={handleCardBrandId}
						handleCVV={handleCardCVV}
						handleNext={handleAddCard}
						cardBrandTicket={cardBrandTicket}
						handleBirth={handleCardBirth}
						selectCardBrandId={selectCardBrandId}
					/>
				</>}

				{!newCard && payment && offlineCards && offlineCards.length > 0 && <PaymentMethodContainer>
					<PaymentMethodHeader>
						<h3>PAGAMENTO NA ENTREGA</h3>
					</PaymentMethodHeader>
					<PaymentMethodItems>
						{offlineCards && offlineCards.map((item, index) =>
							<PaymentMethodItem key={index}>
								<PaymentMethodItemContent>
									<BrandImage
										src={getCardBrandImageById(item.brandId)}
										alt={`Bandeira do cartao: ${getCardBrandImageById(item.brandId)}`}
									/>
									<BrandName>{item.data}</BrandName>
								</PaymentMethodItemContent>
								<PaymentMethodItemActions>
									<CustomCheckbox
										type='radio'
										name='field'
										onClick={() => handleCard({ selectCard: item, isOffline: true })}></CustomCheckbox>
								</PaymentMethodItemActions>
							</PaymentMethodItem>
						)}
					</PaymentMethodItems>
				</PaymentMethodContainer>}
			</ContentWrapper>
		</>}
	</Wrapper >
}

const mapStateToProps = (state) => {
	return {
		accessToken: state.user.accessToken,
		userCards: state.main.userCards || [],
		userCard: state.main.userCard || {},
		login: state.main.login || {},
		userHistory: state.main.userHistory || {},
		card: state.main.card || [],
		loading: state.loading.loading || [],
		modalityId: state.main.modality && state.main.modality.id,
		URLParameters: (state.main.URLParameters) || ''
	}
}

const GetConnection = connect(mapStateToProps, {
	setLoading: loading.setLoading,
	setCard,
	fetchCards,
	setUserCard,
	postCard,
	addTicketCard,
	setCVV,
	setCardName,
	setCardNumber,
	setCardDate,
	setCardBirth,
	setCardBrandId,
	setCardCPF,
	setCardCVV,
	deleteCard,
	setThing,
	setUserHistory,
	handleGTM
})

export const Cards = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'cards' })
)(CardsPage))
