import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  background: #fff;
  font-size: .875rem;
  min-height: 100%;
  padding: 1.25rem;

  > a{
    margin: 0 0 1rem 0;
    text-decoration: none;
    font-weight: bold;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
  }

  > .close-button {
    font-size: 1.25rem;
    align-self: flex-end;
    margin-bottom: .313rem;
    font-weight: 100;
  }

  > img.main {
    margin-bottom: 1.25rem;
    width: 100%;
    height: auto;
    max-height: 263px;
  }

  > h2 {
    margin-bottom: 1.25rem;
  }

  > p {
    margin-bottom: 1.25rem;
    font-size: .938rem;
    flex: 0;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000'};

    &.value {
      font-size: 1.125rem;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
      font-weight: bold;
    }

    &.promo {
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.positive) || '#333'};
      font-size: 1.125rem;
      font-weight: bold;
    }
  
    >span{
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#333'};
      font-size: 1rem;
      text-decoration: line-through;
      margin-left: .313rem;
    }
  }
`

export const Textarea = styled.textarea`
  min-width: 100%;
  max-width: 100%;
  height: 5rem;
  border-radius: .938rem;
  padding: .625rem;
  box-sizing: border-box;
  border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'} .063rem solid;
`

export const Button = styled.button`
  display: flex;
  flex-direction: row;

  &:disabled {
    opacity: .4;
    pointer-events: none;
  }
`

export const CloseButton = styled.button`
  padding: .313rem;
  background: none;
  border: 0 none;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
`

export const Image = styled.img`
  margin: 0;
`

export const H1 = styled.h1`
  margin: 0 0 1.563rem 0;
  padding: 0;
  font-size: 1.25rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
`

export const H4 = styled.h4`
  margin: 0 0 .625rem 0;
  padding: 0;
  font-size: 1.25rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
`

export const Div = styled.div``

export const Paragraph = styled.p`
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'} .063rem solid;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1;
	min-width: 100%;
`

export const QuantityControlWrapper = styled.div``

export const Span = styled.span`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  font-size: 1rem;
  margin: 0 .5rem;
  font-weight: bold;
`

export const ButtonActions = styled.button`
  font-weight: bold;
  font-size: 1rem;
  border-radius: 50%;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border: ${props => {
    if (props.qtd > 0) {
      return `1px solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' }`
    }
    return `1px solid #E2E2E2`
  }};
`

export const NotesWrapper = styled.div`
  min-width: 100%;
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;

  > h3 {
    margin-bottom: 1.25rem;
  }

  > textarea {
    margin-bottom: 1.25rem;
  }
  
  > ::-webkit-input-placeholder  { 
    font-size: .875rem;
    color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' }; 
  }

  > input:-moz-placeholder { 
    font-size: .875rem;
    color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' }; 
  }

  > textarea:-moz-placeholder { 
    font-size: .875rem;
    color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' }; 
  }

  > .row {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;

    > .quantity {
      border-radius: .625rem;
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'} .063rem solid;
      min-height: 3.438rem;
      box-sizing: border-box;
      padding: 0 .313rem;
      max-width: 35%;
      min-width: 35%;

      > button {
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        font-weight: bold;
        font-size: 1.875rem;
        padding: 0 .313rem;
        text-decoration: none;
        background-color: #fff;
      }

      > span {
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        font-weight: bold;
        font-size: 1.25rem;
      }      
    }

    > .add {
      flex: 1;
      max-width: 60%;
      min-width: 60%;

      > button {
        background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        border-radius: .625rem;
        min-width: 100%;
        display: flex;
        height: 3.438rem;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: .875rem;

        > span {
          flex: 1;
          color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff'};
        }
      }
    }
  }
`

export const ImageWrapper = styled.div`
  min-width: 100%;
  max-width: 100%;
  max-height: 16.75rem;
  min-height: 16.75rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .938rem;
  margin-bottom: 1.25rem;

  > img {
    flex: 1;
    max-width: 100%;
    min-width: 100%;
    height: auto;
    min-height: auto;
    max-height: auto;
  }
`
