import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
	fetchCards as fetchCardsAction,
	setUserCard as setUserCardAction,
	setThing as setChangeAction,
} from '../../../../redux/actions/main';
import { pipe, GetContent } from '../../../../domain/helpers';

import { getCardBrandImageById } from '../../../../infra/utils';
import { ReactComponent as IconArrowRight } from '../../../assets/icons/icon_arrow_right.svg';
import {
	CustomIcon,
	CustomLoading,
	CustomModalNotification,
} from '../../../components';

import {
	Dialog,
	PaymentMethods,
	PaymentMethod,
	LoadingContainer,
	PaymentMethodSection,
} from './styles';

const Context = React.createContext({});

function PaymentMethodSelectionComponent(props) {
	const {
		setUserCard,
		fetchCards,
		userCards,
		loading,
		close,
		card,
		setChange,
	} = props;
	const [changeOpened, setchangeOpened] = useState(false);
	const [changeValue, setChangeValue] = useState('');

	const isLoading = loading && loading.includes('cards');
	let { availableOnlineCards = [], availableOfflineCards = [] } = userCards;

	useEffect(() => {
		async function getCards() {
			await fetchCards();
		}

		getCards();
	}, [fetchCards]);

	function handleSelectCard(selectCard, isOfflinePayment = false) {
		setUserCard({
			...card,
			...selectCard,
			isOfflinePayment,
		});

		if (String(selectCard.brand).toUpperCase() === 'DINHEIRO') {
			setchangeOpened(true);
		} else {
			close();
		}
	}

	function handleCloseChangeDialog() {
		close();
	}

	const handleChangeSelected = () => {
		setChange(changeValue);
		close();
	};

	if (isLoading) {
		return (
			<LoadingContainer>
				<CustomLoading
					type={'spin'}
					id='default-loading'
					height={40}
					width={40}
				/>
			</LoadingContainer>
		);
	}

	if (changeOpened) {
		return (
			<CustomModalNotification
				openModal={true}
				title={'Precisa de Troco?'}
				content={
					'Se precisar de troco informe abaixo o valor que vai pagar para levarmos o seu troco.'
				}
				handleCloseModal={handleCloseChangeDialog}
				size={'xs'}
				fullWidth={true}
				input={true}
				inputValue={changeValue}
				setInputValue={setChangeValue}
				inputType='number'
				inputPlaceholder='R$ 0,00'
				actions={[
					{
						label: 'Confirmar',
						classes: 'primary',
						handleClick: () => handleChangeSelected(),
					},
					{
						label: 'Não Preciso de Troco!',
						classes: 'secondary',
						handleClick: () => {
							setChangeValue('')
							close()
						},
					},
				]}
			/>
		);
	}

	return (
		<Dialog>
			<Dialog.Header>
				<Dialog.Title>Escolha a forma de pagamento</Dialog.Title>
				<Dialog.CloseButton className='close-button' onClick={() => close()}>
					<CustomIcon name='Times' />
				</Dialog.CloseButton>
			</Dialog.Header>
			<Dialog.Content>
				{!!availableOnlineCards.length && (
					<PaymentMethodSection>
						<PaymentMethodSection.Title>
							Pagamento online
						</PaymentMethodSection.Title>
						<PaymentMethods>
							{availableOnlineCards.map((card) => (
								<PaymentMethod
									key={card.id}
									onClick={() => handleSelectCard(card)}
								>
									<PaymentMethod.BrandImage
										src={getCardBrandImageById(card.brandId)}
										alt={`Bandeira do cartão: ${card.brand}`}
									/>
									<PaymentMethod.Identification>
										{card.data}
									</PaymentMethod.Identification>
									<PaymentMethod.Icon>
										<IconArrowRight />
									</PaymentMethod.Icon>
								</PaymentMethod>
							))}
						</PaymentMethods>
					</PaymentMethodSection>
				)}

				{!!availableOfflineCards.length && (
					<PaymentMethodSection>
						<PaymentMethodSection.Title>
							Pagamento na entrega
						</PaymentMethodSection.Title>
						<PaymentMethods>
							{availableOfflineCards.map((card) => (
								<PaymentMethod
									key={card.id}
									onClick={() => handleSelectCard(card, true)}
								>
									<PaymentMethod.BrandImage
										src={getCardBrandImageById(card.brandId)}
										alt={`Bandeira do cartão: ${card.brand}`}
									/>
									<PaymentMethod.Identification>
										{card.data}
									</PaymentMethod.Identification>
									<PaymentMethod.Icon>
										<IconArrowRight />
									</PaymentMethod.Icon>
								</PaymentMethod>
							))}
						</PaymentMethods>
					</PaymentMethodSection>
				)}
			</Dialog.Content>
		</Dialog>
	);
}

const mapStateToProps = (state) => {
	return {
		userCards: state.main.userCards || [],
		loading: state.loading.loading || [],
		card: state.main.card || [],
	};
};

const GetConnection = connect(mapStateToProps, {
	fetchCards: fetchCardsAction,
	setUserCard: setUserCardAction,
	setChange: setChangeAction,
});

export const PaymentMethodSelection = React.memo(
	pipe(
		GetConnection,
		GetContent({ context: Context, id: 'cards' })
	)(PaymentMethodSelectionComponent)
);
