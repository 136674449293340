export function maskCard(v) {
	v = v.replace(/\D/g, "")
	v = v.replace(/^(\d{4})(\d)/g, "$1 $2")
	v = v.replace(/^(\d{4})\s(\d{4})(\d)/g, "$1 $2 $3")
	v = v.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, "$1 $2 $3 $4")
	return v
}

export function maskCpf(v) {
	v = v.replace(/\D/g, "")
	v = v.replace(/(\d{3})(\d)/, "$1.$2")
	v = v.replace(/(\d{3})(\d)/, "$1.$2")
	v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
	return v
}

export function maskDate(v) {
	v = v.replace(/\D/g, "")
	v = v.replace(/(\d{2})(\d)/, "$1/$2")
	return v
}

export function maskBirth(v) {
	v = v.replace(/\D/g, "")
	v = v.replace(/(\d{2})(\d)/, "$1/$2")
	v = v.replace(/(\d{2})(\d)/, "$1/$2")
	return v
}

export function maskCep(v) {
	v = v.replace(/\D/g, "")
	v = v.replace(/(\d{5})(\d)/, "$1-$2")
	
	return v
}

export function maskPhone(v) {
	if (!v) {
		return ''
	}

	let r = v.replace(/\D/g, "");
	r = r.replace(/^0/, "");
	if (r.length > 10) {
		r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
	} else if (r.length > 5) {
		r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
	} else if (r.length > 2) {
		r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
	} else {
		r = r.replace(/^(\d*)/, "($1");
	}

	return r;
}

export function maskCardExpirationDate(v) {
	v = v.replace(/\D/g, "")
	const monthReg = /^.{2}/g.exec(v);

	if (monthReg && monthReg.length && +monthReg[0] > 12) {
		v = v.replace(/^.{2}/g, '12');
	}

	v = v.replace(/(\d{2})(\d)/, "$1/$2")
	return v
}
