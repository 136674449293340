import { useStore, useDispatch } from 'react-redux'

import {
	sidebar
} from '../../state'

import {
	sidebarContentEnum
} from '../../domain/sidebar'

export function useSidebar() {
	const store = useStore()
	const dispatch = useDispatch()

	const sidebarContent = store.getState().sidebar && store.getState().sidebar.content
	const sidebarOpened = (store.getState().sidebar && store.getState().sidebar.opened) || null

	const handleOpened = (open = null) => {
    const newOpened = (open === true || open === false ? (open) : !sidebarOpened)

		dispatch(sidebar.setOpened(newOpened))
  }

	const handleContent = (content = sidebarContentEnum.default) => {
    dispatch(sidebar.setContent(content))
  }

	return {
		sidebarContent,
		sidebarOpened,
		sidebarContentEnum,
		handleSidebarContent: handleContent,
		handleSidebarOpened: handleOpened
	}
}
