export const countries = [
    { name: 'Afghanistan', url: 'afeganistao', ddi: 93, prefix: null, abbr: 'AF / AFG' },
    { name: 'Albania', url: 'albania', ddi: 355, prefix: null, abbr: 'AL / ALB' },
    { name: 'Algeria', url: 'algeria', ddi: 213, prefix: null, abbr: 'DZ / DZA' },
    { name: 'American Samoa', url: 'samoaamericana', ddi: 1, prefix: '684', abbr: 'AS / ASM' },
    { name: 'Andorra', url: 'andorra', ddi: 376, prefix: null, abbr: 'AD / AND' },
    { name: 'Angola', url: 'angola', ddi: 244, prefix: null, abbr: 'AO / AGO' },
    { name: 'Anguilla', url: 'anguila', ddi: 1, prefix: '264', abbr: 'AI / AIA' },
    { name: 'Antarctica', url: 'antarctica', ddi: 672, prefix: null, abbr: 'AQ / ATA' },
    { name: 'Antigua and Barbuda', url: 'antigua', ddi: 1, prefix: '268', abbr: 'AG / ATG' },
    { name: 'Argentina', url: 'argentina', ddi: 54, prefix: null, abbr: 'AR / ARG' },
    { name: 'Armenia', url: 'armenia', ddi: 374, prefix: null, abbr: 'AM / ARM' },
    { name: 'Aruba', url: 'aruba', ddi: 297, prefix: null, abbr: 'AW / ABW' },
    { name: 'Australia', url: 'australia', ddi: 61, prefix: null, abbr: 'AU / AUS' },
    { name: 'Austria', url: 'austria', ddi: 43, prefix: null, abbr: 'AT / AUT' },
    { name: 'Azerbaijan', url: 'azerbaijao', ddi: 994, prefix: null, abbr: 'AZ / AZE' },
    { name: 'Bahamas', url: 'bahamas', ddi: 1, prefix: '242', abbr: 'BS / BHS' },
    { name: 'Bahrain', url: 'bahrain', ddi: 973, prefix: null, abbr: 'BH / BHR' },
    { name: 'Bangladesh', url: 'bangladesh', ddi: 880, prefix: null, abbr: 'BD / BGD' },
    { name: 'Barbados', url: 'barbados', ddi: 1, prefix: '246', abbr: 'BB / BRB' },
    { name: 'Belarus', url: 'belarus', ddi: 375, prefix: null, abbr: 'BY / BLR' },
    { name: 'Belgium', url: 'belgica', ddi: 32, prefix: null, abbr: 'BE / BEL' },
    { name: 'Belize', url: 'belize', ddi: 501, prefix: null, abbr: 'BZ / BLZ' },
    { name: 'Benin', url: 'benin', ddi: 229, prefix: null, abbr: 'BJ / BEN' },
    { name: 'Bermuda', url: 'bermuda', ddi: 1, prefix: '441', abbr: 'BM / BMU' },
    { name: 'Bhutan', url: 'butao', ddi: 975, prefix: null, abbr: 'BT / BTN' },
    { name: 'Bolivia', url: 'bolivia', ddi: 591, prefix: null, abbr: 'BO / BOL' },
    { name: 'Bosnia and Herzegovina', url: 'bosnia', ddi: 387, prefix: null, abbr: 'BA / BIH' },
    { name: 'Botswana', url: 'botsuana', ddi: 267, prefix: null, abbr: 'BW / BWA' },
    { name: 'Brazil', url: 'brasil', ddi: 55, prefix: null, abbr: 'BR / BRA' },
    { name: 'British Indian Ocean Territory', url: 'territorioindianobritanico', ddi: 246, prefix: null, abbr: 'IO / IOT' },
    { name: 'British Virgin Islands', url: 'ilhasvirgensbritanica', ddi: 1, prefix: '284', abbr: 'VG / VGB' },
    { name: 'Brunei', url: 'brunei', ddi: 673, prefix: null, abbr: 'BN / BRN' },
    { name: 'Bulgaria', url: 'bulgaria', ddi: 359, prefix: null, abbr: 'BG / BGR' },
    { name: 'Burkina Faso', url: 'burkinafasso', ddi: 226, prefix: null, abbr: 'BF / BFA' },
    { name: 'Burundi', url: 'burundi', ddi: 257, prefix: null, abbr: 'BI / BDI' },
    { name: 'Cambodia', url: 'camboja', ddi: 855, prefix: null, abbr: 'KH / KHM' },
    { name: 'Cameroon', url: 'camaroes', ddi: 237, prefix: null, abbr: 'CM / CMR' },
    { name: 'Canada', url: 'canada', ddi: 1, prefix: null, abbr: 'CA / CAN' },
    { name: 'Cape Verde', url: 'caboverde', ddi: 238, prefix: null, abbr: 'CV / CPV' },
    { name: 'Cayman Islands', url: 'ilhascaiman', ddi: 1, prefix: '345', abbr: 'KY / CYM' },
    { name: 'Central African Republic', url: 'repcenafr', ddi: 236, prefix: null, abbr: 'CF / CAF' },
    { name: 'Chad', url: 'chade', ddi: 235, prefix: null, abbr: 'TD / TCD' },
    { name: 'Chile', url: 'chile', ddi: 56, prefix: null, abbr: 'CL / CHL' },
    { name: 'China', url: 'china', ddi: 86, prefix: null, abbr: 'CN / CHN' },
    { name: 'Christmas Island', url: 'ilhaschristmas', ddi: 61, prefix: null, abbr: 'CX / CXR' },
    { name: 'Cocos Islands', url: 'ilhascocos', ddi: 61, prefix: null, abbr: 'CC / CCK' },
    { name: 'Colombia', url: 'colombia', ddi: 57, prefix: null, abbr: 'CO / COL' },
    { name: 'Comoros', url: 'comoros', ddi: 269, prefix: null, abbr: 'KM / COM' },
    { name: 'Cook Islands', url: 'ilhascook', ddi: 682, prefix: null, abbr: 'CK / COK' },
    { name: 'Costa Rica', url: 'costarica', ddi: 506, prefix: null, abbr: 'CR / CRI' },
    { name: 'Croatia', url: 'croacia', ddi: 385, prefix: null, abbr: 'HR / HRV' },
    { name: 'Cuba', url: 'cuba', ddi: 53, prefix: null, abbr: 'CU / CUB' },
    { name: 'Curacao', url: 'curacao', ddi: 599, prefix: null, abbr: 'CW / CUW' },
    { name: 'Cyprus', url: 'cyprus', ddi: 357, prefix: null, abbr: 'CY / CYP' },
    { name: 'Czech Republic', url: 'republicatcheca', ddi: 420, prefix: null, abbr: 'CZ / CZE' },
    { name: 'Democratic Republic of the Congo', url: 'repdemcongo', ddi: 243, prefix: null, abbr: 'CD / COD' },
    { name: 'Denmark', url: 'dinamarca', ddi: 45, prefix: null, abbr: 'DK / DNK' },
    { name: 'Djibouti', url: 'djibouti', ddi: 253, prefix: null, abbr: 'DJ / DJI' },
    { name: 'Dominica', url: 'dominica', ddi: 1, prefix: '767', abbr: 'DM / DMA' },
    { name: 'Dominican Republic', url: 'republicadominicana', ddi: 1, prefix: '809,829,849', abbr: 'DO / DOM' },
    { name: 'East Timor', url: 'timorleste', ddi: 670, prefix: null, abbr: 'TL / TLS' },
    { name: 'Ecuador', url: 'equador', ddi: 593, prefix: null, abbr: 'EC / ECU' },
    { name: 'Egypt', url: 'egito', ddi: 20, prefix: null, abbr: 'EG / EGY' },
    { name: 'El Salvador', url: 'elsalvador', ddi: 503, prefix: null, abbr: 'SV / SLV' },
    { name: 'Equatorial Guinea', url: 'guineequatorial', ddi: 240, prefix: null, abbr: 'GQ / GNQ' },
    { name: 'Eritrea', url: 'eritreia', ddi: 291, prefix: null, abbr: 'ER / ERI' },
    { name: 'Estonia', url: 'estonia', ddi: 372, prefix: null, abbr: 'EE / EST' },
    { name: 'Ethiopia', url: 'etiopia', ddi: 251, prefix: null, abbr: 'ET / ETH' },
    { name: 'Falkland Islands', url: 'ilhasfalkland', ddi: 500, prefix: null, abbr: 'FK / FLK' },
    { name: 'Faroe Islands', url: 'ilhasfarao', ddi: 298, prefix: null, abbr: 'FO / FRO' },
    { name: 'Fiji', url: 'fiji', ddi: 679, prefix: null, abbr: 'FJ / FJI' },
    { name: 'Finland', url: 'finlandia', ddi: 358, prefix: null, abbr: 'FI / FIN' },
    { name: 'France', url: 'franca', ddi: 33, prefix: null, abbr: 'FR / FRA' },
    { name: 'French Polynesia', url: 'polinesiafrancesa', ddi: 689, prefix: null, abbr: 'PF / PYF' },
    { name: 'Gabon', url: 'gabao', ddi: 241, prefix: null, abbr: 'GA / GAB' },
    { name: 'Gambia', url: 'gambia', ddi: 220, prefix: null, abbr: 'GM / GMB' },
    { name: 'Georgia', url: 'georgia', ddi: 995, prefix: null, abbr: 'GE / GEO' },
    { name: 'Germany', url: 'alemanha', ddi: 49, prefix: null, abbr: 'DE / DEU' },
    { name: 'Ghana', url: 'gana', ddi: 233, prefix: null, abbr: 'GH / GHA' },
    { name: 'Gibraltar', url: 'gibraltar', ddi: 350, prefix: null, abbr: 'GI / GIB' },
    { name: 'Greece', url: 'grecia', ddi: 30, prefix: null, abbr: 'GR / GRC' },
    { name: 'Greenland', url: 'greenland', ddi: 299, prefix: null, abbr: 'GL / GRL' },
    { name: 'Grenada', url: 'granada', ddi: 1, prefix: '473', abbr: 'GD / GRD' },
    { name: 'Guam', url: 'guam', ddi: 1, prefix: '671', abbr: 'GU / GUM' },
    { name: 'Guatemala', url: 'guatemala', ddi: 502, prefix: null, abbr: 'GT / GTM' },
    { name: 'Guernsey', url: 'guernsey', ddi: 44, prefix: '1481', abbr: 'GG / GGY' },
    { name: 'Guinea', url: 'guine', ddi: 224, prefix: null, abbr: 'GN / GIN' },
    { name: 'Guinea-Bissau', url: 'guine-bissau', ddi: 245, prefix: null, abbr: 'GW / GNB' },
    { name: 'Guyana', url: 'guiana', ddi: 592, prefix: null, abbr: 'GY / GUY' },
    { name: 'Haiti', url: 'haiti', ddi: 509, prefix: null, abbr: 'HT / HTI' },
    { name: 'Honduras', url: 'honduras', ddi: 504, prefix: null, abbr: 'HN / HND' },
    { name: 'Hong Kong', url: 'hongkong', ddi: 852, prefix: null, abbr: 'HK / HKG' },
    { name: 'Hungary', url: 'hungria', ddi: 36, prefix: null, abbr: 'HU / HUN' },
    { name: 'Iceland', url: 'islandia', ddi: 354, prefix: null, abbr: 'IS / ISL' },
    { name: 'India', url: 'india', ddi: 91, prefix: null, abbr: 'IN / IND' },
    { name: 'Indonesia', url: 'indonesia', ddi: 62, prefix: null, abbr: 'ID / IDN' },
    { name: 'Iran', url: 'ira', ddi: 98, prefix: null, abbr: 'IR / IRN' },
    { name: 'Iraq', url: 'iraque', ddi: 964, prefix: null, abbr: 'IQ / IRQ' },
    { name: 'Ireland', url: 'irlanda', ddi: 353, prefix: null, abbr: 'IE / IRL' },
    { name: 'Isle of Man', url: 'ilhademan', ddi: 44, prefix: '1624', abbr: 'IM / IMN' },
    { name: 'Israel', url: 'israel', ddi: 972, prefix: null, abbr: 'IL / ISR' },
    { name: 'Italy', url: 'italia', ddi: 39, prefix: null, abbr: 'IT / ITA' },
    { name: 'Ivory Coast', url: 'costadomarfim', ddi: 225, prefix: null, abbr: 'CI / CIV' },
    { name: 'Jamaica', url: 'jamaica', ddi: 1, prefix: '876', abbr: 'JM / JAM' },
    { name: 'Japan', url: 'japao', ddi: 81, prefix: null, abbr: 'JP / JPN' },
    { name: 'Jersey', url: 'jersey', ddi: 44, prefix: '1534', abbr: 'JE / JEY' },
    { name: 'Jordan', url: 'jordao', ddi: 962, prefix: null, abbr: 'JO / JOR' },
    { name: 'Kazakhstan', url: 'cazaquistao', ddi: 7, prefix: null, abbr: 'KZ / KAZ' },
    { name: 'Kenya', url: 'quenia', ddi: 254, prefix: null, abbr: 'KE / KEN' },
    { name: 'Kiribati', url: 'kiribati', ddi: 686, prefix: null, abbr: 'KI / KIR' },
    { name: 'Kosovo', url: 'kosovo', ddi: 383, prefix: null, abbr: 'XK / XKX' },
    { name: 'Kuwait', url: 'kuwait', ddi: 965, prefix: null, abbr: 'KW / KWT' },
    { name: 'Kyrgyzstan', url: 'quirguistao', ddi: 996, prefix: null, abbr: 'KG / KGZ' },
    { name: 'Laos', url: 'laos', ddi: 856, prefix: null, abbr: 'LA / LAO' },
    { name: 'Latvia', url: 'latvia', ddi: 371, prefix: null, abbr: 'LV / LVA' },
    { name: 'Lebanon', url: 'libano', ddi: 961, prefix: null, abbr: 'LB / LBN' },
    { name: 'Lesotho', url: 'lessoto', ddi: 266, prefix: null, abbr: 'LS / LSO' },
    { name: 'Liberia', url: 'liberia', ddi: 231, prefix: null, abbr: 'LR / LBR' },
    { name: 'Libya', url: 'libia', ddi: 218, prefix: null, abbr: 'LY / LBY' },
    { name: 'Liechtenstein', url: 'liechtenstein', ddi: 423, prefix: null, abbr: 'LI / LIE' },
    { name: 'Lithuania', url: 'lituania', ddi: 370, prefix: null, abbr: 'LT / LTU' },
    { name: 'Luxembourg', url: 'luxemburgo', ddi: 352, prefix: null, abbr: 'LU / LUX' },
    { name: 'Macau', url: 'macau', ddi: 853, prefix: null, abbr: 'MO / MAC' },
    { name: 'Macedonia', url: 'macedonia', ddi: 389, prefix: null, abbr: 'MK / MKD' },
    { name: 'Madagascar', url: 'madagascar', ddi: 261, prefix: null, abbr: 'MG / MDG' },
    { name: 'Malawi', url: 'malaui', ddi: 265, prefix: null, abbr: 'MW / MWI' },
    { name: 'Malaysia', url: 'malasia', ddi: 60, prefix: null, abbr: 'MY / MYS' },
    { name: 'Maldives', url: 'maldivas', ddi: 960, prefix: null, abbr: 'MV / MDV' },
    { name: 'Mali', url: 'mali', ddi: 223, prefix: null, abbr: 'ML / MLI' },
    { name: 'Malta', url: 'malta', ddi: 356, prefix: null, abbr: 'MT / MLT' },
    { name: 'Marshall Islands', url: 'ilhasmarshall', ddi: 692, prefix: null, abbr: 'MH / MHL' },
    { name: 'Mauritania', url: 'mauritania', ddi: 222, prefix: null, abbr: 'MR / MRT' },
    { name: 'Mauritius', url: 'mauricio', ddi: 230, prefix: null, abbr: 'MU / MUS' },
    { name: 'Mayotte', url: 'mayotte', ddi: 262, prefix: null, abbr: 'YT / MYT' },
    { name: 'Mexico', url: 'mexico', ddi: 52, prefix: null, abbr: 'MX / MEX' },
    { name: 'Micronesia', url: 'micronesia', ddi: 691, prefix: null, abbr: 'FM / FSM' },
    { name: 'Moldova', url: 'moldova', ddi: 373, prefix: null, abbr: 'MD / MDA' },
    { name: 'Monaco', url: 'monaco', ddi: 377, prefix: null, abbr: 'MC / MCO' },
    { name: 'Mongolia', url: 'mongolia', ddi: 976, prefix: null, abbr: 'MN / MNG' },
    { name: 'Montenegro', url: 'montenegro', ddi: 382, prefix: null, abbr: 'ME / MNE' },
    { name: 'Montserrat', url: 'monteserrat', ddi: 1, prefix: '664', abbr: 'MS / MSR' },
    { name: 'Morocco', url: 'morocco', ddi: 212, prefix: null, abbr: 'MA / MAR' },
    { name: 'Mozambique', url: 'mocambique', ddi: 258, prefix: null, abbr: 'MZ / MOZ' },
    { name: 'Myanmar', url: 'mianmar', ddi: 95, prefix: null, abbr: 'MM / MMR' },
    { name: 'Namibia', url: 'namibia', ddi: 264, prefix: null, abbr: 'NA / NAM' },
    { name: 'Nauru', url: 'nauru', ddi: 674, prefix: null, abbr: 'NR / NRU' },
    { name: 'Nepal', url: 'nepal', ddi: 977, prefix: null, abbr: 'NP / NPL' },
    { name: 'Netherlands', url: 'holanda', ddi: 31, prefix: null, abbr: 'NL / NLD' },
    { name: 'Netherlands Antilles', url: 'antilhasholandesas', ddi: 599, prefix: null, abbr: 'AN / ANT' },
    { name: 'New Caledonia', url: 'novacaledonia', ddi: 687, prefix: null, abbr: 'NC / NCL' },
    { name: 'New Zealand', url: 'novazelandia', ddi: 64, prefix: null, abbr: 'NZ / NZL' },
    { name: 'Nicaragua', url: 'nicaragua', ddi: 505, prefix: null, abbr: 'NI / NIC' },
    { name: 'Niger', url: 'niger', ddi: 227, prefix: null, abbr: 'NE / NER' },
    { name: 'Nigeria', url: 'nigeria', ddi: 234, prefix: null, abbr: 'NG / NGA' },
    { name: 'Niue', url: 'niue', ddi: 683, prefix: null, abbr: 'NU / NIU' },
    { name: 'North Korea', url: 'coreiadonorte', ddi: 850, prefix: null, abbr: 'KP / PRK' },
    { name: 'Northern Mariana Islands', url: 'ilhasmarianasdonorte', ddi: 1, prefix: '670', abbr: 'MP / MNP' },
    { name: 'Norway', url: 'noruega', ddi: 47, prefix: null, abbr: 'NO / NOR' },
    { name: 'Oman', url: 'oman', ddi: 968, prefix: null, abbr: 'OM / OMN' },
    { name: 'Pakistan', url: 'paquistan', ddi: 92, prefix: null, abbr: 'PK / PAK' },
    { name: 'Palau', url: 'palau', ddi: 680, prefix: null, abbr: 'PW / PLW' },
    { name: 'Palestine', url: 'palestina', ddi: 970, prefix: null, abbr: 'PS / PSE' },
    { name: 'Panama', url: 'panama', ddi: 507, prefix: null, abbr: 'PA / PAN' },
    { name: 'Papua New Guinea', url: 'papuanovaguine', ddi: 675, prefix: null, abbr: 'PG / PNG' },
    { name: 'Paraguay', url: 'paraguai', ddi: 595, prefix: null, abbr: 'PY / PRY' },
    { name: 'Peru', url: 'peru', ddi: 51, prefix: null, abbr: 'PE / PER' },
    { name: 'Philippines', url: 'filipinas', ddi: 63, prefix: null, abbr: 'PH / PHL' },
    { name: 'Pitcairn', url: 'pitcairn', ddi: 64, prefix: null, abbr: 'PN / PCN' },
    { name: 'Poland', url: 'polonia', ddi: 48, prefix: null, abbr: 'PL / POL' },
    { name: 'Portugal', url: 'portugal', ddi: 351, prefix: null, abbr: 'PT / PRT' },
    { name: 'Puerto Rico', url: 'portorico', ddi: 1, prefix: '787,939', abbr: 'PR / PRI' },
    { name: 'Qatar', url: 'qatar', ddi: 974, prefix: null, abbr: 'QA / QAT' },
    { name: 'Republic of the Congo', url: 'repdemcongo', ddi: 242, prefix: null, abbr: 'CG / COG' },
    { name: 'Reunion', url: 'reunion', ddi: 262, prefix: null, abbr: 'RE / REU' },
    { name: 'Romania', url: 'romenia', ddi: 40, prefix: null, abbr: 'RO / ROU' },
    { name: 'Russia', url: 'russia', ddi: 7, prefix: null, abbr: 'RU / RUS' },
    { name: 'Rwanda', url: 'ruanda', ddi: 250, prefix: null, abbr: 'RW / RWA' },
    { name: 'Saint Barthelemy', url: 'saobartolomeu', ddi: 590, prefix: null, abbr: 'BL / BLM' },
    { name: 'Saint Helena', url: 'santahelene', ddi: 290, prefix: null, abbr: 'SH / SHN' },
    { name: 'Saint Kitts and Nevis', url: 'saocristovaoenevis', ddi: 1, prefix: '869', abbr: 'KN / KNA' },
    { name: 'Saint Lucia', url: 'santalucia', ddi: 1, prefix: '758', abbr: 'LC / LCA' },
    { name: 'Saint Martin', url: 'ilhadesaomartinho', ddi: 590, prefix: null, abbr: 'MF / MAF' },
    { name: 'Saint Pierre and Miquelon', url: 'saint-pierreemiquelon', ddi: 508, prefix: null, abbr: 'PM / SPM' },
    { name: 'Saint Vincent and the Grenadines', url: 'saovicenteegranadinas', ddi: 1, prefix: '784', abbr: 'VC / VCT' },
    { name: 'Samoa', url: 'samoa', ddi: 685, prefix: null, abbr: 'WS / WSM' },
    { name: 'San Marino', url: 'saomarino', ddi: 378, prefix: null, abbr: 'SM / SMR' },
    { name: 'Sao Tome and Principe', url: 'saotomeeprincipe', ddi: 239, prefix: null, abbr: 'ST / STP' },
    { name: 'Saudi Arabia', url: 'arabiasaudita', ddi: 966, prefix: null, abbr: 'SA / SAU' },
    { name: 'Senegal', url: 'senegal', ddi: 221, prefix: null, abbr: 'SN / SEN' },
    { name: 'Serbia', url: 'servia', ddi: 381, prefix: null, abbr: 'RS / SRB' },
    { name: 'Seychelles', url: 'seychelles', ddi: 248, prefix: null, abbr: 'SC / SYC' },
    { name: 'Sierra Leone', url: 'serraleoa', ddi: 232, prefix: null, abbr: 'SL / SLE' },
    { name: 'Singapore', url: 'cingapura', ddi: 65, prefix: null, abbr: 'SG / SGP' },
    { name: 'Sint Maarten', url: 'sint maarten', ddi: 1, prefix: '721', abbr: 'SX / SXM' },
    { name: 'Slovakia', url: 'eslovaquia', ddi: 421, prefix: null, abbr: 'SK / SVK' },
    { name: 'Slovenia', url: 'eslovenia', ddi: 386, prefix: null, abbr: 'SI / SVN' },
    { name: 'Solomon Islands', url: 'ilhassalomao', ddi: 677, prefix: null, abbr: 'SB / SLB' },
    { name: 'Somalia', url: 'somalia', ddi: 252, prefix: null, abbr: 'SO / SOM' },
    { name: 'South Africa', url: 'africadosul', ddi: 27, prefix: null, abbr: 'ZA / ZAF' },
    { name: 'South Korea', url: 'coreiadosul', ddi: 82, prefix: null, abbr: 'KR / KOR' },
    { name: 'South Sudan', url: 'sudaodosul', ddi: 211, prefix: null, abbr: 'SS / SSD' },
    { name: 'Spain', url: 'espanha', ddi: 34, prefix: null, abbr: 'ES / ESP' },
    { name: 'Sri Lanka', url: 'srilanka', ddi: 94, prefix: null, abbr: 'LK / LKA' },
    { name: 'Sudan', url: 'sudao', ddi: 249, prefix: null, abbr: 'SD / SDN' },
    { name: 'Suriname', url: 'suriname', ddi: 597, prefix: null, abbr: 'SR / SUR' },
    { name: 'Svalbard and Jan Mayen', url: 'svalbardejanmayen', ddi: 47, prefix: null, abbr: 'SJ / SJM' },
    { name: 'Swaziland', url: 'suazilandia', ddi: 268, prefix: null, abbr: 'SZ / SWZ' },
    { name: 'Sweden', url: 'suecia', ddi: 46, prefix: null, abbr: 'SE / SWE' },
    { name: 'Switzerland', url: 'suica', ddi: 41, prefix: null, abbr: 'CH / CHE' },
    { name: 'Syria', url: 'siria', ddi: 963, prefix: null, abbr: 'SY / SYR' },
    { name: 'Taiwan', url: 'taiwa', ddi: 886, prefix: null, abbr: 'TW / TWN' },
    { name: 'Tajikistan', url: 'tajiquistao', ddi: 992, prefix: null, abbr: 'TJ / TJK' },
    { name: 'Tanzania', url: 'tanzania', ddi: 255, prefix: null, abbr: 'TZ / TZA' },
    { name: 'Thailand', url: 'tailandia', ddi: 66, prefix: null, abbr: 'TH / THA' },
    { name: 'Togo', url: 'togo', ddi: 228, prefix: null, abbr: 'TG / TGO' },
    { name: 'Tokelau', url: 'tokelau', ddi: 690, prefix: null, abbr: 'TK / TKL' },
    { name: 'Tonga', url: 'tonga', ddi: 676, prefix: null, abbr: 'TO / TON' },
    { name: 'Trinidad and Tobago', url: 'trinidadetobago', ddi: 1, prefix: '868', abbr: 'TT / TTO' },
    { name: 'Tunisia', url: 'tunisia', ddi: 216, prefix: null, abbr: 'TN / TUN' },
    { name: 'Turkey', url: 'turquia', ddi: 90, prefix: null, abbr: 'TR / TUR' },
    { name: 'Turkmenistan', url: 'turcomenistao', ddi: 993, prefix: null, abbr: 'TM / TKM' },
    { name: 'Turks and Caicos Islands', url: 'ilhasturkscaicos', ddi: 1, prefix: '649', abbr: 'TC / TCA' },
    { name: 'Tuvalu', url: 'tuvalu', ddi: 688, prefix: null, abbr: 'TV / TUV' },
    { name: 'U.S. Virgin Islands', url: 'ilhasvirgens', ddi: 1, prefix: '340', abbr: 'VI / VIR' },
    { name: 'Uganda', url: 'uganda', ddi: 256, prefix: null, abbr: 'UG / UGA' },
    { name: 'Ukraine', url: 'ucrania', ddi: 380, prefix: null, abbr: 'UA / UKR' },
    { name: 'United Arab Emirates', url: 'emiradosarabes', ddi: 971, prefix: null, abbr: 'AE / ARE' },
    { name: 'United Kingdom', url: 'reinounido', ddi: 44, prefix: null, abbr: 'GB / GBR' },
    { name: 'United States', url: 'estadosunidos', ddi: 1, prefix: null, abbr: 'US / USA' },
    { name: 'Uruguay', url: 'uruguai', ddi: 598, prefix: null, abbr: 'UY / URY' },
    { name: 'Uzbekistan', url: 'uzbequistao', ddi: 998, prefix: null, abbr: 'UZ / UZB' },
    { name: 'Vanuatu', url: 'vanuatu', ddi: 678, prefix: null, abbr: 'VU / VUT' },
    { name: 'Vatican', url: 'vaticano', ddi: 379, prefix: null, abbr: 'VA / VAT' },
    { name: 'Venezuela', url: 'venezuela', ddi: 58, prefix: null, abbr: 'VE / VEN' },
    { name: 'Vietnam', url: 'vietnã', ddi: 84, prefix: null, abbr: 'VN / VNM' },
    { name: 'Wallis and Futuna', url: 'wallisandfutuna', ddi: 681, prefix: null, abbr: 'WF / WLF' },
    { name: 'Western Sahara', url: 'westernsahara', ddi: 212, prefix: null, abbr: 'EH / ESH' },
    { name: 'Yemen', url: 'iemen', ddi: 967, prefix: null, abbr: 'YE / YEM' },
    { name: 'Zambia', url: 'zambia', ddi: 260, prefix: null, abbr: 'ZM / ZMB' },
    { name: 'Zimbabwe', url: 'zimbabue', ddi: 263, prefix: null, abbr: 'ZW / ZWE' }
]
