export default ({
  apiService
}) => {
  const strategy = {
    signUp: async ({
      data,
      auth,
      dispatch,
      actions,
      maps
    }) => {
      const {
        setLoading
      } = actions

      const {
        loading
      } = data

      const {
        mapSignUpData,
        mapSignUpRequest
      } = maps

      if (loading && loading.includes('sign-up')) {
        return false
      }

      dispatch(setLoading('sign-up'))

			const url = `Usuarios/Cadastrar`
      const result = await apiService.post(url, mapSignUpRequest(data), auth)
      const mappedResult = mapSignUpData(result.data)
  
      return mappedResult
    },
		signUpPhone: async ({
      data,
      auth,
      maps
    }) => {
      const {
				resend,
				confirmationType
      } = data

      const {
        mapSignUpPhoneData,
        mapSignUpPhoneRequest
      } = maps

			const url = `Usuarios/${!resend && confirmationType === 'sms' ? 'ConfirmarTelefone' : (
				resend && confirmationType === 'sms' ? 'ReenviarCodigoVerificacao' : 'ConfirmarTelefonePorVoz'
			)}`
      const result = await apiService.post(url, mapSignUpPhoneRequest(data), auth)
      const mappedResult = mapSignUpPhoneData(result.data)
  
      return mappedResult
    },
		signUpPhoneCode: async ({
      data,
      auth,
      maps
    }) => {
      const {
        mapSignUpPhoneCodeData,
        mapSignUpPhoneCodeRequest
      } = maps

			const url = `Usuarios/ConfirmarCodigoVerificacao`
      const result = await apiService.post(url, mapSignUpPhoneCodeRequest(data), auth)
      const mappedResult = mapSignUpPhoneCodeData(result.data)
  
      return mappedResult
    },
    signUpEmail: async ({
      data,
      auth,
      maps
    }) => {
      const {
        mapSignUpEmailData,
        mapSignUpEmailCodeRequest
      } = maps

			const url = `Usuarios/ConfirmarCodigoEmail`
      const result = await apiService.post(url, mapSignUpEmailCodeRequest(data), auth)
      const mappedResult = mapSignUpEmailData(result.data)
  
      return mappedResult
    },
    signUpPassword: async ({
      data,
      auth,
      maps
    }) => {
      const {
        mapSignUpPasswordData,
        mapSignUpPasswordRequest
      } = maps

			const url = `Usuarios/AtualizarSenha`
      const result = await apiService.post(url, mapSignUpPasswordRequest(data), auth)
      const mappedResult = mapSignUpPasswordData(result.data)
  
      return mappedResult
    },
    signUpResendCode: async ({
      data,
      auth,
      maps
    }) => {
      const {
        mapSignUpResendCodeData,
        mapSignUpResendCodeRequest
      } = maps

			const url = `Usuarios/ReenviarCodigoEmail`
      const result = await apiService.post(url, mapSignUpResendCodeRequest(data), auth)
      const mappedResult = mapSignUpResendCodeData(result.data)
  
      return mappedResult
    },
    signUpGoogle: async ({
      data,
      auth,
      dispatch,
      actions,
      maps
    }) => {
      const {
        setLoading
      } = actions
  
      const {
        loading,
        signUp
      } = data
  
      const {
        mapSignUpGoogleData,
        mapSignUpGoogleRequest
      } = maps
  
      if (loading && loading.includes('sign-up')) {
        return false
      }
  
      dispatch(setLoading('sign-up'))
  
      const url = `Usuarios/CadastrarViaGoogle`
      const result = await apiService.post(url, mapSignUpGoogleRequest(signUp), auth)
      const mappedResult = mapSignUpGoogleData(result.data)
  
      return mappedResult
    },
    signUpFacebook: async ({
      data,
      auth,
      dispatch,
      actions,
      maps
    }) => {
      const {
        setLoading
      } = actions
  
      const {
        loading,
        signUp
      } = data
  
      const {
        mapSignUpFacebookData,
        mapSignUpFacebookRequest
      } = maps
  
      if (loading && loading.includes('sign-up')) {
        return false
      }
  
      dispatch(setLoading('sign-up'))
  
      const url = `Usuarios/CadastrarViaFacebook`
      const result = await apiService.post(url, mapSignUpFacebookRequest(signUp), auth)
      const mappedResult = mapSignUpFacebookData(result.data)
  
      return mappedResult
    }
  }

  return {
    signUp: async (payload) => {
      return await strategy['signUp'](payload)
    },

		signUpPhone: async (payload) => {
      return await strategy['signUpPhone'](payload)
    },

    signUpEmail: async (payload) => {
      return await strategy['signUpEmail'](payload)
    },

    signUpPassword: async (payload) => {
      return await strategy['signUpPassword'](payload)
    },

    signUpResendCode: async (payload) => {
      return await strategy['signUpResendCode'](payload)
    },

		signUpPhoneCode: async (payload) => {
      return await strategy['signUpPhoneCode'](payload)
    },

    signUpFacebook: async (payload) => {
      return await strategy['signUpFacebook'](payload)
    },

    signUpGoogle: async (payload) => {
      return await strategy['signUpGoogle'](payload)
    }
  }
}
