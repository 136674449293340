import React from 'react'
import { connect } from 'react-redux'
import {
  withTheme
} from 'styled-components'

import {
  pipe,
  GetContent
} from '../../domain/helpers'

import {
  CustomIcon
} from '../components'

import {
  setNotification
} from '../../redux/actions/main'

import {
  Wrapper,
  Paragraph,
  CloseButton
} from './styles'

export const Context = React.createContext({})

export function NotificationComponent(props) {
  const {
    notification,
    setNotification,
		id
  } = props

  const {
    message,
    callback,
		type = 'default'
  } = notification

  return message ? <Wrapper id={`${id ? id : 'notification'}`} className={`notification ${type}`}>
    { message ? <Paragraph>{ message }</Paragraph> : null }

    <CloseButton className='close-button' onClick={ () => {
      setNotification(null)
      callback && callback()
    } }>
      <CustomIcon name='Times' />
    </CloseButton>
  </Wrapper> : null
}

const mapStateToProps = (state) => {
  return {
    notification: state.main.notification || {}
  }
}

const GetConnection = connect(mapStateToProps, {
  setNotification
})

export const Notification = React.memo(pipe(
  GetConnection,
  GetContent({context: Context, id: 'notification'}),
  withTheme
)(NotificationComponent))
