import React from 'react'

import { ReactComponent as InfoIcon } from '../../assets/icons/icon_info.svg'
import { ReactComponent as CouponIcon } from '../../assets/icons/icon_coupon.svg'

import {
  Wrapper,
  CardSide,
  CardContent,
  CuponDetails,
  CuponCode,
  CuponDescription
} from './styles'

export function Coupon(props) {
  const {
		id,
    code,
		quickDescription,
    description,
    value,
		minValue,
		type,
		typeValueModalityDelivery,
		valueModalityDelivery,
		valueMaxModalityDelivery,
		valueMinModalityDelivery,
    handleClick
  } = props

	const formattedValue = type === 1 ? `R$ ${value}` : `${value}%`

  return (
    <Wrapper onClick={() => {
      handleClick({
				id,
        code,
				quickDescription,
        description,
        value,
        minValue,
				type,
        typeValueModalityDelivery,
        valueModalityDelivery,
        valueMaxModalityDelivery,
        valueMinModalityDelivery
      })
    }}>
      <CardSide>
        <CouponIcon />
      </CardSide>
      <CardContent>
        <CuponDetails>
          <CuponCode>{code}</CuponCode>
					<CuponDescription>{`Cupom de ${formattedValue} de desconto!`}</CuponDescription>
          <CuponDescription>{quickDescription}</CuponDescription>
        </CuponDetails>
        <InfoIcon />
      </CardContent>
    </Wrapper>
  )
}
