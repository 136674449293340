import styled from 'styled-components'

import { device } from '../../../domain/enum/responsive'
import CheckedIcon from '../../assets/icon_checked.png'
import UncheckedIcon from '../../assets/icon_unchecked.png'
import Loading from './../../assets/receipt_loading.gif'


export const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 960px;
  margin-top: 0;

  @media ${ device.desktop } {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  > div {
    background: #fff;
    box-sizing: border-box;

    @media ${ device.desktop } {
      padding: 1.25rem;
      border-radius: .625rem;
      box-shadow: #ddd .188rem .188rem .375rem;
      min-width: 620px;
      max-width: 620px;
      text-align: left;
    }

    &.sidebar {
      min-width: 280px;
      max-width: 280px;

      @media ${ device.desktop } {
        margin-left: 1.25rem;
        min-width: 320px;
        max-width: 320px;
      }
    }
  }
`;

export const H2 = styled.h2`
  font-size: 1.125rem;
  line-height: 1.5rem;
  color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  text-align: left;
  margin: 1.25rem 0 1.875rem 0;
  padding: 0;
  min-width: 280px;
  flex: 1;
  
  @media ${ device.desktop } {
    font-size: 1.5rem;
    text-align: left;
    line-height: 1.75rem;
    max-width: unset;
    min-width: unset;
    margin-bottom: 3.125rem;
  }

  > div {
    color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    font-size: 1.25rem;
  }

  > span {
    color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey) || '#000' };
    display: block;
    font-weight: normal;
    font-size: 1rem;
  }
`

export const H3 = styled.h3`
  font-size: 1.125rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 0;
  margin-bottom: 1.25rem;
  min-width: 280px;
  max-width: 280px;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    font-size: 1.5rem;
  }

  &.only-mobile {
    @media ${ device.desktop } {
      display: none;
    }
  }
`

export const List = styled.ul`
  list-style: inside none;
  min-width: 280px;
  max-width: 280px;
  padding: 0;
  margin: 0;
  flex: 1;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#B8B8B8'} .063rem solid;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0 -1.25rem;
    padding: 0 1.25rem;
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
  }
`

export const ListItem = styled.li`
  width: 100%;
  margin-bottom: .625rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-size: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  > span {
    flex: 1;
    font-weight: bold;

    &:nth-child(1) {
      max-width: 1.875rem;
    }

    &:nth-child(2) {
      > span {
        display: block;
        font-size: .75rem;
        font-weight: 400;
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#999' };
      }
    }

    &:nth-child(3) {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textBlueColor) || '#999' };
      text-align: right;
    }
  }
`

export const ImageContainer = styled.img`
  width: 6.25rem;
  height: auto;
`

export const Paragraph = styled.p`  
  min-width: 280px;
  padding: 0;
  margin: 0;
  color: #7D7D7D;
`

export const Stepper = styled.div`
  min-width: 280px;
  max-width: 280px;
  margin-bottom: 1.563rem;
  
  @media ${ device.desktop } {
    margin-bottom: 3.125rem;
  }
`

export const StepperItem = styled.div`
  background: ${ props => {
    if(props.status){
      return `url('${ Loading }') no-repeat 0 .313rem;`
    }

    return `url('${ UncheckedIcon }') no-repeat 0 .313rem;`
  }};
  min-width: 280px;
  max-width: 280px;
  line-height: 1.875rem;
  min-height: 1.875rem;
  padding: 0;
  padding-left: 1.875rem;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-size: 1.25rem;
  padding-bottom: 1.25rem;
  color: ${ props => {
    if(props.status){
      return `${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' }`
    }

    return ``
  }};
  font-size: ${ props => {
    if(props.status){
      return `1.25rem`
    }

    return `16px`
  }};
  font-weight: bold;
  
  @media ${ device.desktop } {
    font-size: ${ props => {
      if(props.status){
        return `32px`
      }
  
      return `1.25rem`
    }};
    padding-top: 0;
    padding-bottom: 44px;
  }

  &.active {
    background-image: url('${ CheckedIcon }');
    background-size: 1.25rem;
    padding-bottom: 9px;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#999' };
    font-weight: bold;
  }

  &:before {
    content: '';
    position: absolute;
    left: 9px;
    top: 1.75rem;
    border-left: #E2E2E2 .063rem solid;
    height: 34px;
    width: .063rem;

    @media ${ device.desktop } {
      height: 46px;
    }
  }

  &:last-child {
    padding-bottom: 0;

    &:before {
      display: none;
    }
  }

  > span {
    display: inline-block;
    font-size: .875rem;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
    font-weight: normal;
    padding-left: .313rem;
  }
`

export const ProgressBar = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  max-width: 280px;
  min-width: 280px;
  list-style: inside none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.25rem;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin-bottom: 3.125rem;
  }
`

export const ProgressBarItem = styled.li`
  background: #B1B1B1;
  flex: 1;
  max-width: 24%;
  min-width: 24%;
  min-height: .313rem;
  max-height: .313rem;
  border-radius: .125rem;
  padding: 0;
  margin: 0;

  &.active {
    background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#999' };
  }

  @media ${ device.desktop } {
    max-height: .375rem;
    min-height: .375rem;
    border-radius: 4px;
  }
`

export const DeliveryBox = styled.div`
  flex: 1;
  margin-bottom: .625rem;
  padding: .938rem 0;
  box-sizing: border-box;
	border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'} .063rem solid;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0 -1.25rem;
    padding: 0 1.25rem;
    margin-bottom: 1.875rem;
  }

  > p {
    min-width: 280px;
    padding: 0;
    margin: 0;
    color: #7D7D7D;
  }

  > h3 {
    margin-bottom: .625rem;
  }

  @media ${ device.mobile } {
    display: block;
  }

  &.only-mobile {
    @media ${ device.desktop } {
      display: none;
    }
  }

  &.only-desktop {
    @media ${ device.mobile } {
      display: none;
    }
  }
`

export const HelpBox = styled.div`
  flex: 1;
  margin-bottom: .625rem;
  padding: .625rem 0 .938rem 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0 -1.25rem;
    padding: 0 1.25rem;
    margin-bottom: 1.875rem;
  }

  > h3 {
    margin-bottom: .625rem;
  }

  > div {
    min-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: .625rem;
    
    > button {
      width: 100%;
      padding: .625rem;
      border-radius: .938rem;
      background: #fff;
      margin: 0;
      border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'} .125rem solid;
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333' };
      font-size: .75rem;

      &.off {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333' };
      }
    }
  }

  @media ${ device.mobile } {
    display: block;
  }

  &.only-mobile {
    @media ${ device.desktop } {
      display: none;
    }
  }

  &.only-desktop {
    @media ${ device.mobile } {
      display: none;
    }
  }
`

export const PreviewDeliveryBox = styled.div`
  background: #F7F7F7;
  min-width: 280px;
  max-width: 280px;
  flex: 1;
  margin-bottom: 1.875rem;
  border: #DDD .063rem solid;
  padding: .938rem 0;
  box-sizing: border-box;
  border-radius: .313rem;
  text-align: center;
  max-height: 3.125rem;

  @media ${ device.desktop } {
    margin-bottom: 0;
    min-width: unset;
    max-width: unset;
    display: none;
  }
 
  > p {
    font-size: .875rem;
    margin: 0;
    padding: 0;
    min-width: 280px;
    color: #7D7D7D;
  }
`

export const SummaryWrapper = styled.div`
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-width: 280px;
	
	flex: 1;
	padding: 0;
	padding-bottom: 2.5rem;
	border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'} .063rem solid;

	> p {
		line-height: 1rem;
    font-size: 1rem;
    max-width: 280px;
    margin: 0 auto;
    color: '#7D7D7D';
    padding: 0;
    margin: 0;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    min-width: 100%;

		&.subtotal {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#333' };
			margin-bottom: .625rem;
		}

		&.fee {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#333' };
			margin-bottom: 1.25rem;
		}

    &.discount {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#333' };
      margin-bottom: .625rem;
    }

		&.total {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333' };
			font-size: 1.25rem;
			line-height: 1.25rem;

			> span {
				font-weight: bold;
			}
		}

		> span {
			flex: 1;

			&.value {
				align-self: flex-end;
				flex: none;
			}
		}
	}
`

export const Footer = styled.footer`  
  flex: 1;
  min-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: .938rem;
  padding-top: 0;
  margin-top: 0;

  @media ${ device.desktop } {
    margin: 0 -1.25rem;
  }

  > p {
    min-width: 280px;
    padding: 0;
    margin: 0;
    margin-bottom: .938rem;
    color: #7D7D7D;
  }

  > button {
    font-size: 1rem;
    padding: .938rem .938rem;
    box-sizing: border-box;
    max-width: 100%;
    min-width: 100%;
    color: #fff;
    border-radius: .938rem;
    font-weight: bold;
  }
`

export const Block = styled.div`
  flex: 1;

  @media ${ device.desktop } {
    max-width: 12.5rem;
    min-width: 12.5rem;
  }
`

export const SelectedCardContainer = styled.p`
  margin: 0;
  width: 100%;
  max-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  > span {
    font-size: .75rem;
    font-weight: bold;
    color: #686868;

    &:nth-child(2) {
      font-size: .875rem;
      font-weight: normal;
    }

    > img {
      margin-right: .313rem;
    }

    > a {
      margin-left: .313rem;

      > img {
        width: .938rem;
        height: auto;
      }
    }
  }
`
