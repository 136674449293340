import React from 'react'
import PropTypes from 'prop-types'
import {
  connect
} from 'react-redux'

import {
  pipe,
  GetContent
} from '../../../../domain/helpers'

import {
  Wrapper
} from './styles'

import {
  Product
} from '../..'

const Context = React.createContext({})

export function ListComponent(props) {
  const {
    handleViewProduct,
    handleAddProduct,
    cart,
    products,
    categoryId,
    categoryName,
    direction,
    storeLogo,
    status,
    displayFull,
    storeImg,
    opened
  } = props

  const maxItemsPerRow = 3
  const newProducts = [...products]
  const linesTotal = Math.ceil(newProducts.length / maxItemsPerRow)
  const maxItemsTotal = linesTotal * maxItemsPerRow

  if ((newProducts.length % 3)) {
    while (newProducts.length < maxItemsTotal) {
      newProducts.push({
        id: 0
      })
    }
  }

  return <Wrapper className={displayFull ? `store-category-products-list store-horizontal-menu-list-full` : `store-category-products-list store-horizontal-menu-list`} id={`list-${categoryName}`}>
    {newProducts && newProducts.length && newProducts.map((mappedItem, index) => {
      const {
        id,
        name,
        description,
        image,
        value,
        additionals,
        promotionalValue,
        fromValue
      } = mappedItem

      const key = `stores-products-category-group-${id}-${index}`
      const formattedPrice = `R$ ${Number(value).toFixed(2).replace('.', ',')}`
      const cartProductPrevious = (cart.products && cart.products.length) ? (cart.products.filter(filteredItem => filteredItem.id === id))[0] : null
      const cartProductPreviousQty = cartProductPrevious && cartProductPrevious.quantity
      const selectedAdditionals = (cartProductPrevious && cartProductPrevious.selectedAdditionals) || []
      const position = index + 1
      const formattedPricePromotional = promotionalValue ? `${Number(promotionalValue).toFixed(2).replace('.', ',')}` : null
      const requiredAdditionals = additionals && additionals.length && additionals.filter(item => item.minimum >= 1)
      const hasRequiredAdditionals = requiredAdditionals && requiredAdditionals.length ? true : false
      const formattedFromValue = fromValue ? `${Number(fromValue).toFixed(2).replace('.', ',')}` : null

      return <Product
        className={`${displayFull ? (!(position % 3) ? 'right' : (!(!index || (!(index % 3) && (position % 3))) ? 'center' : '')) : 'normal'}`}
        direction={direction}
        quantity={cartProductPreviousQty}
        key={key}
        selectedCategory={{
          name: categoryName,
          id: categoryId
        }}
        handleAdd={handleAddProduct}
        handleView={handleViewProduct}
        id={id}
        name={name}
        description={description}
        image={image}
        storeLogo={storeLogo}
        value={formattedPrice}
        rawPrice={value}
        rawPromocional={promotionalValue}
        additionals={additionals}
        selectedAdditionals={selectedAdditionals}
        promotionalValue={promotionalValue}
        status={status}
        storeImg={storeImg}
        formattedPricePromotional={formattedPricePromotional}
        formattedFromValue={formattedFromValue}
        hasRequiredAdditionals={hasRequiredAdditionals}
        opened={opened}
      />
    })}
  </Wrapper>
}

ListComponent.propTypes = {
  handleViewProduct: PropTypes.func,
  handleAddProduct: PropTypes.func,
  cart: PropTypes.shape({}),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  categoryId: PropTypes.number,
  categoryName: PropTypes.string,
  direction: PropTypes.string,
  storeLogo: PropTypes.string,
  status: PropTypes.number,
  displayFull: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    cart: state.main.cart || {}
  }
}

const GetConnection = connect(mapStateToProps, {})

export const List = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'store' })
)(ListComponent))
