
import React, {
  useContext,
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  coupon
} from '../../../state'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
  Skeleton
} from '../../'

import {
  ContentWrapper,
  LoadingWrapper,
  Wrapper,
  H1,
  CloseButton,
	CartHeaderWrapper,
  Paragraph
} from './styles'

import {
  CustomIcon
} from '../../components'

import { Content } from '../content'
import { EmptyContent } from '../empty-content'

export const Context = React.createContext({})

function CouponsListPage(props) {
  const {
    loading,
    registeredCoupons,
		modal = false,
    fetchCoupons,
    handleCoupon,
		handleCloseCoupon,
  } = props

  const {
    sections,
    title,
    text
  } = useContext(Context)

  const registeredCouponsSection = sections && sections.filter(item => item.id === 'registered-coupons')[0]

  const registeredCouponsSelectedSectionName = registeredCoupons && registeredCoupons.length ? 'main-content' : 'empty-content'
  const registeredCouponsSelectedSection = (registeredCouponsSection && registeredCouponsSection.content && registeredCouponsSection.content.filter(item => item.id === registeredCouponsSelectedSectionName)[0]) || {}

  useEffect(() => {
    setTimeout(() => fetchCoupons(), 500)
  }, [
    fetchCoupons
  ])

  return <Wrapper>
    {!!loading.includes('coupons') && <LoadingWrapper id='coupons-list-loading'>
      <Skeleton module='coupons-list' />
    </LoadingWrapper>}

    {!loading.includes('coupons') && <>
			<H1>{title}</H1>
			<CartHeaderWrapper>
				<CloseButton className='close' onClick={() => {
					if(!handleCloseCoupon){
						return
					}
					handleCloseCoupon(false)
				}}>
					<CustomIcon name='AngleDown' />
				</CloseButton>
			</CartHeaderWrapper>
			<ContentWrapper id='coupons-list-content'>
				{registeredCoupons && registeredCoupons.length > 0 && <Paragraph>{text}</Paragraph>}
				{registeredCoupons && registeredCoupons.length ?
					<Content mode='simple' data={registeredCoupons} handleCoupon={handleCoupon} /> :
					<EmptyContent modal={modal} data={{ ...registeredCouponsSelectedSection }} />}
			</ContentWrapper></>}
  </Wrapper>
}

CouponsListPage.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.string),
  registeredCoupons: PropTypes.arrayOf(PropTypes.shape({})),

  fetchCoupons: PropTypes.func,

  handleCoupon: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading.loading || [],
    registeredCoupons: (state.main.coupons && state.main.coupons.coupons) || { coupons: [] }
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchCoupons: coupon.fetchCoupons
})

export const CouponsList = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'coupons' })
)(CouponsListPage))
