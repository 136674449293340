import React, {
  useEffect,
  useState,
  useContext
} from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  withTheme
} from 'styled-components'

import {
  pipe,
  GetContent
} from '../../domain/helpers'

import history from '../../history'

import LoadingGIF from '../assets/loading.gif'

import {
  modality,
	addressActions
} from '../../state'

import {
  setUserHistory,
  setUserCard
} from '../../redux/actions/main'

import {
  Button,
  H1,
  H2,
  ContentWrapper,
  LoadingWrapper,
  LoadingImage,
  Input,
  Paragraph,
  Wrapper
} from './styles'

export const Context = React.createContext({})

export function ModalityPage(props) {
  const {
    cep,
    setCEP,
    setUserCard,
    setModality,
    setAddress,
    handlePickup: propsHandlePickup,
    handleDelivery: propsHandleDelivery,
    theme,
    setUserHistory,
    handleGTM,
    URLParameters
  } = props

  const [loaded, setLoaded] = useState(false)
  const [ready, setReady] = useState(false)
  const [contentMotion, setContentMotion] = useState(false)
  const [CEPRequired, setCEPRequired] = useState(false)

  const context = useContext(Context)

  useEffect(() => {
    setLoaded(true)

    if (loaded) {
      setReady(true)
    }

    if (loaded && ready) {
      setTimeout(() => setContentMotion(true), 1000)
    }
  }, [
    loaded,
    ready,
    theme
  ])

  function handleSearchCEP() {
    if (cep && cep.length === 9) {
      handleGTM({
        'event': 'Address_Info',
        'CEP': cep
      })

      setCEPRequired(false)

      setUserCard(null)

      propsHandleDelivery()

      setModality({
        id: 4
      })
      setUserHistory({
        back: '/'
      })

      history.push(`/new-address${URLParameters}`)

      return
    }

    setCEPRequired(true)
  }

  function handlePickup() {
    setModality({
      id: 2
   })

    setAddress(null)
    setUserCard(null)

    propsHandlePickup()
  }

  function handleCEP(args) {
    postCEP(args)
  }

  function postCEP(args) {
    setCEP(args)
  }

  return <Wrapper id='modality'>
    {!loaded && <LoadingWrapper id='modality-loading'>
      <LoadingImage src={LoadingGIF} />
    </LoadingWrapper>}

    {loaded && <ContentWrapper id='modality-content' className={contentMotion ? 'motion' : ''}>
      <H1>{context && context.title}</H1>

      <H2>{context && context.subtitle}</H2>

      <Button onClick={() => {
        handlePickup()
      } }>{context && context.pickupButton.label}</Button>
      
       <Paragraph>ou</Paragraph>

      <Paragraph><strong>{context && context.text }</strong></Paragraph>

      <Input className={`${ CEPRequired ? 'required': '' }` } maxLength={ 9 } placeholder='00000-000' value={cep || ''} onKeyDown={(event) => {
        const {keyCode} = event

        if (!(
          (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) ||
          keyCode === 8 ||
          keyCode === 9 || 
          keyCode === 46 || 
          keyCode === 37 || 
          keyCode === 39 || 
          keyCode === 189 
        )) {
          event.preventDefault()
        }

        if (keyCode === 13) {
          handleSearchCEP()
        }

        if (keyCode === 189 && cep && (cep.indexOf('-') > -1 || cep.length !== 5)) {
          event.preventDefault()
        }

        if (cep && cep.length === 5 && keyCode !== 8 && keyCode !== 9 && keyCode !== 37 && keyCode !== 39 && keyCode !== 46) {
          handleCEP(`${cep}-`)
        }
      }} onChange={(event) => {
        const { value } = event.target

        handleCEP(value)
      }} />

      { CEPRequired ? <Paragraph className='warning'>CEP inválido!</Paragraph> : null }

      <Button onClick={ () => {
        handleSearchCEP()
      }}>{context && context.deliveryButton.label}</Button>
    </ContentWrapper> }
  </Wrapper>
}

ModalityPage.propTypes = {
  handleGTM: PropTypes.func.isRequired,
  handleDelivery: PropTypes.func.isRequired,
  handlePickup: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    cep: state.main.cep,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  setModality: modality.setModality,
  setAddress: addressActions.setAddress,
  setCEP: addressActions.setCEP,
  setUserHistory,
  setUserCard
})

export const Modality = React.memo(pipe(
  GetConnection,
  GetContent({context: Context, id: 'modality'}),
  withRouter,
  withTheme
)(ModalityPage))
