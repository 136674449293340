import styled from 'styled-components'

export const DefaultImage = styled.img`
	&.image-notification{
		width: 1rem;
		height: auto;
		min-width: 1rem;
		max-width: 1rem;
		min-height: auto;
		max-height: unset;
		margin-left: .2rem;
	}
`
