import React from 'react'
import defaultBrandImage from '../../../assets/default_brand.svg'
import {
    CardBrand
} from './styles'

export function Brand({ brandImage }) {
    return (
        <>
            {brandImage
                ? (<CardBrand src={brandImage} alt='card-brand' width={'40px'} />)
                : (<CardBrand src={defaultBrandImage} alt='card-brand' width={'40px'} />)
            }
        </>
    )
}
